import { ZapiEventKey } from '../event/event-key';
export const PlayerWithState = (Base) => {
    return class extends Base {
        zapiWatchResponse = null;
        zapiStreamRequestInfo = null;
        // warning: only available after watch response is received
        zapiMedia = null;
        playOptions = null;
        updateStreamInformation(streamRequestInfo, playOptions) {
            this.zapiMedia = null;
            this.zapiWatchResponse = null;
            this.playOptions = playOptions;
            this.zapiStreamRequestInfo = streamRequestInfo;
        }
        zapiHookMediaItem(media) {
            return media;
        }
        zapiDispatchMedia(media) {
            if (!this.zapiWatchResponse) {
                throw new Error('No watch response available');
            }
            this.zapiMedia = media;
            // allows mixins to extend the media item.
            // happens after streamInformation is set,
            // so that mixins have all information available
            const extendedMedia = this.zapiHookMediaItem(media);
            this.zapiMedia = extendedMedia;
            this.triggerEvent({
                type: ZapiEventKey.MEDIA_CHANGED,
                media: extendedMedia,
                psid: this.psid ?? undefined,
            });
            return extendedMedia;
        }
        triggerEvent(event) {
            // @ts-expect-error event mismatch
            super.triggerEvent(event);
        }
        requestStream(streamRequestInfo) {
            streamRequestInfo;
            // no op -- never called
            return Promise.reject();
        }
    };
};
