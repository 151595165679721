import { EventKey, PlaybackState, } from '../enums';
import { TelemetrySession } from '../../telemetry';
import { EventKey as TelemetryEventKey } from '../../telemetry/enums/event-key';
import { formatStreamRepresentations } from '../../telemetry/utils';
import { formatCapabilities } from './telemetry-utils';
export const WithTelemetry = (Base) => {
    return class PlayerWithEvent extends Base {
        #telemetrySessions = [];
        dispatchMedia(media, noEvent) {
            const options = this._playOptions;
            const psid = this.psid;
            if (!options || !psid) {
                throw new Error('Something went wrong');
            }
            const telemetrySession = this.getTelemetrySession(psid);
            if (options.telemetryEndpoint) {
                telemetrySession.setEndpoint(options.telemetryEndpoint);
            }
            else {
                telemetrySession.disableTelemetry();
            }
            return super.dispatchMedia(media, noEvent);
        }
        createStatusProperties() {
            const bufferInfo = this.adapter.getBufferInformation();
            const bufferDuration = bufferInfo
                ? ((bufferInfo.videoForward ?? 0) +
                    (bufferInfo.videoBackward ?? 0) +
                    (bufferInfo.audioForward ?? 0) +
                    (bufferInfo.audioBackward ?? 0))
                : -1;
            const statusProperties = {
                buf_dur: Math.round(bufferDuration),
                buf_dur_forward: Math.round(this.adapter.getBufferInformation()?.videoForward ?? -1),
                cur_media_time: Math.round(this.currentPosition ?? 0),
                cur_bitrate: this._bitrate ?? -1,
                playback_window_start: Math.round(this.seekableRange?.start ?? 0),
                playback_window_end: Math.round(this.seekableRange?.end ?? 0),
                net_dl_speed_est: Math.round(this.bandwidth ?? 0),
                cpu_usage: this.adapter.getSystemStats()?.cpuUsage,
                mem_free: this.adapter.getSystemStats()?.memoryFree,
            };
            return statusProperties;
        }
        getTelemetrySession = (psid) => {
            let telemetrySession = this.#telemetrySessions.find((session) => session.psid === psid);
            if (!telemetrySession) {
                telemetrySession = new TelemetrySession(() => {
                    return this.createStatusProperties();
                }, {
                    psid,
                    appId: this._playerOptions.appId,
                    appVersion: this._playerOptions.appVersion,
                    publicId: this._playerOptions.publicId,
                });
                this.#telemetrySessions.push(telemetrySession);
            }
            return telemetrySession;
        };
        appendStreamLoadedEvent = (psid) => {
            const telemetrySession = this.getTelemetrySession(psid);
            telemetrySession.appendEventPromise(async () => {
                const representations = await this.adapter.getAvailableRepresentations();
                return {
                    type: TelemetryEventKey.STREAM_LOADED,
                    psid: telemetrySession.psid,
                    representations: formatStreamRepresentations(representations),
                };
            });
        };
        triggerTelemetryNewSessionEvent(psid, assetType, assetId) {
            const telemetrySession = this.getTelemetrySession(psid);
            telemetrySession.appendEventPromise(async () => {
                const capabilities = await this.adapter.getCapabilities();
                // buffer config is only available after the player is ready
                await new Promise((resolve) => {
                    let timeout;
                    const handler = () => {
                        this.off(EventKey.PLAYER_READY, handler);
                        if (timeout) {
                            clearTimeout(timeout);
                        }
                        resolve();
                    };
                    timeout = setTimeout(handler, 1000);
                    this.on(EventKey.PLAYER_READY, handler);
                });
                const userAgent = (typeof window !== 'undefined' &&
                    window?.navigator?.userAgent) || 'unknown';
                return {
                    type: TelemetryEventKey.NEW_SESSION,
                    psid,
                    browser_name: userAgent,
                    player_name: this.adapter.playerName,
                    player_ver: __INJ.SDK_VERSION_JS,
                    buf_player_conf_min_buf: this.adapter.getBufferInformation()?.miniumVideoBufferForward ?? -1,
                    asset_type: assetType,
                    asset_id: assetId,
                    player_size_w: this._geometry?.playerWidth ?? 0,
                    player_size_h: this._geometry?.playerHeight ?? 0,
                    screen_size_w: this._geometry?.screenWidth ?? 0,
                    screen_size_h: this._geometry?.screenHeight ?? 0,
                    capabilities: formatCapabilities(capabilities),
                    device_name: capabilities?.deviceName,
                };
            });
        }
        triggerTelemetryEvent(payload) {
            const telemetrySession = this.getTelemetrySession(payload.psid);
            telemetrySession.appendEvent(payload);
        }
        dispatchCustomTelemetryEvent(event) {
            const { type, psid, csid, ...payload } = event;
            if (csid) {
                const telemetrySession = this.getTelemetrySession(psid);
                telemetrySession.setCsid(csid);
            }
            this.triggerTelemetryEvent({
                type: TelemetryEventKey.CUSTOM,
                customType: type,
                psid,
                csid,
                payload,
            });
        }
        disableTelemetry(psid) {
            const telemetrySession = this.getTelemetrySession(psid);
            telemetrySession.disableTelemetry();
        }
        evaluateEvent = (event) => {
            switch (event.type) {
                case EventKey.PLAYER_READY: {
                    this.appendStreamLoadedEvent(event.psid);
                    this.triggerTelemetryEvent({
                        type: TelemetryEventKey.READY,
                        psid: event.psid,
                    });
                    break;
                }
                case EventKey.PLAYBACK_STATE_CHANGED: {
                    switch (event.state) {
                        case PlaybackState.PLAYING: {
                            this.triggerTelemetryEvent({
                                type: TelemetryEventKey.PLAYING,
                                psid: event.psid,
                            });
                            // todo: Cleanup of old sessions should happen on
                            // PlaybackState.STOPPED.
                            // For that we need to pass the psid to the adapter.
                            const openSessions = this.#telemetrySessions.filter((session) => session.psid !== event.psid);
                            openSessions.forEach((session) => {
                                session.close();
                            });
                            this.#telemetrySessions = this.#telemetrySessions.filter((session) => session.psid === event.psid);
                            break;
                        }
                        case PlaybackState.BUFFERING: {
                            this.triggerTelemetryEvent({
                                type: TelemetryEventKey.BUFFERING,
                                psid: event.psid,
                            });
                            break;
                        }
                        case PlaybackState.PAUSED: {
                            this.triggerTelemetryEvent({
                                type: TelemetryEventKey.PAUSED,
                                psid: event.psid,
                            });
                            break;
                        }
                        case PlaybackState.STOPPED: {
                            const telemetrySession = this.getTelemetrySession(event.psid);
                            telemetrySession.close();
                            break;
                        }
                        default: {
                            break;
                        }
                    }
                    break;
                }
                case EventKey.QUALITY_CHANGED: {
                    this.triggerTelemetryEvent({
                        type: TelemetryEventKey.QUALITY_CHANGED,
                        psid: event.psid,
                    });
                    break;
                }
                case EventKey.SEEKED: {
                    this.triggerTelemetryEvent({
                        type: TelemetryEventKey.SEEKED,
                        psid: event.psid,
                    });
                    break;
                }
                case EventKey.SELECTED_AUDIO_TRACK_CHANGED: {
                    this.triggerTelemetryEvent({
                        type: TelemetryEventKey.TRACK_AUDIO_CHANGED,
                        psid: event.psid,
                        language: event.targetTrack?.locale,
                        codec: event.targetTrack?.codec,
                    });
                    break;
                }
                case EventKey.SELECTED_SUBTITLES_TRACK_CHANGED: {
                    this.triggerTelemetryEvent({
                        type: TelemetryEventKey.TRACK_SUBTITLES_CHANGED,
                        psid: event.psid,
                        language: event.targetTrack?.locale,
                    });
                    break;
                }
                case EventKey.PLAYER_ERROR: {
                    this.triggerTelemetryEvent({
                        type: TelemetryEventKey.ERROR,
                        psid: event.psid,
                        err_type: String(event.error.code),
                        err_payload: event.error.stack
                            ? `${event.error.message}\n${event.error.stack}`
                            : event.error.message,
                    });
                    break;
                }
                default: {
                    break;
                }
            }
        };
        triggerFullEvent = (event) => {
            // consider to defer this off the main event loop
            this.evaluateEvent(event);
            return super.triggerFullEvent(event);
        };
        async destroy() {
            const closePromises = this.#telemetrySessions.map((session) => {
                return session.close();
            });
            await Promise.all(closePromises);
            this.#telemetrySessions = [];
            return super.destroy();
        }
    };
};
