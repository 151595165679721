import { PlaybackState, MediaType, } from '@zattoo/playback-sdk';
export const PlayerWithRecording = (Base) => {
    return class extends Base {
        get isRecording() {
            return this.mediaType === MediaType.RECORDING;
        }
        playRecording(id, playOptions) {
            return this.requestStream({
                playOptions,
                mediaType: MediaType.RECORDING,
                watchRequestParams: {
                    recording_id: id.toString(10),
                },
            });
        }
        // @ts-expect-error https://github.com/microsoft/TypeScript/issues/48125
        setPlayerState(newState) {
            if (!this.isRecording || this.isAdPlaying()) {
                return super.setPlayerState(newState);
            }
            return super.setPlayerState({
                canPause: this.currentState === PlaybackState.PLAYING || this.currentState === PlaybackState.BUFFERING,
                canSeekBackward: true,
                canSeekForward: this.zapiWatchResponse?.stream?.forward_seeking,
                seekableRange: this.seekableRange,
                ...newState,
            });
        }
    };
};
