import { isPinError, mapError, } from './utils';
export const PlayerWithError = (Base) => {
    return class extends Base {
        handlePinErrror(error) {
            if (isPinError(error)) {
                this.stop();
            }
        }
        // @ts-expect-error https://github.com/microsoft/TypeScript/issues/48125
        handleError(error) {
            const mappedError = mapError(error);
            this.handlePinErrror(mappedError);
            return super.handleError(mappedError);
        }
    };
};
