import { EventKey, ImaEventKey, PlaybackState, AdType, } from '../enums';
import { WithImaEvent } from './ima-event';
export const WithImaPlayer = (Base) => {
    return class extends Base {
        isAdapterWithIma = this.adapter.hasIma;
        onContentSeekableRangeChanged = null;
        onContentPositionChanged = null;
        onContentPlaybackStateChanged = null;
        isAdPlaying() {
            return this.isAdapterWithIma && this.adapter.isAdPlaying();
        }
        dispatchMedia(adapterMedia, noEvent) {
            if (!this.isAdapterWithIma) {
                return super.dispatchMedia(adapterMedia, noEvent);
            }
            if (!this._playOptions) {
                throw new Error('Something went wrong');
            }
            const ads = this._playOptions.adsInfo?.ads;
            if (ads) {
                ads.forEach((ad) => {
                    if (ad.type === AdType.PREROLL || ad.type === AdType.MIDROLL) {
                        const url = ad.url;
                        const imaAdapter = this.adapter;
                        imaAdapter.imaLoadAds(url);
                        imaAdapter.onContentPauseRequested = () => {
                            this.setAdEventListeners();
                            this.updateAdPlayerState();
                        };
                        imaAdapter.onContentResumeRequested = () => {
                            this.resetAdEventListeners();
                            this.updateAdPlayerState();
                        };
                    }
                });
            }
            return super.dispatchMedia(adapterMedia, noEvent);
        }
        onAdPaused = () => {
            this._playbackState = PlaybackState.PAUSED;
            this.triggerEvent({
                type: EventKey.PLAYBACK_STATE_CHANGED,
                state: this._playbackState,
            });
            this.setPlayerState({
                canPause: false,
            });
        };
        onAdResumed = () => {
            this._playbackState = PlaybackState.PLAYING;
            this.triggerEvent({
                type: EventKey.PLAYBACK_STATE_CHANGED,
                state: this._playbackState,
            });
            this.setPlayerState({
                canPause: true,
            });
        };
        onAdStarted = () => {
            this._playbackState = PlaybackState.PLAYING;
            this.triggerEvent({
                type: EventKey.PLAYBACK_STATE_CHANGED,
                state: this._playbackState,
            });
        };
        setAdEventListeners() {
            this.on(ImaEventKey.AD_PAUSED, this.onAdPaused);
            this.on(ImaEventKey.AD_STARTED, this.onAdStarted);
            this.on(ImaEventKey.AD_RESUMED, this.onAdResumed);
            this.onContentSeekableRangeChanged = this.adapter.onSeekableRangeChanged;
            this.onContentPositionChanged = this.adapter.onPositionChanged;
            this.onContentPlaybackStateChanged = this.adapter.onPlaybackStateChanged;
            // Disable content event listeners
            this.adapter.onSeekableRangeChanged = null;
            this.adapter.onPositionChanged = null;
            this.adapter.onPlaybackStateChanged = null;
        }
        resetAdEventListeners() {
            this.off(ImaEventKey.AD_PAUSED, this.onAdPaused);
            this.off(ImaEventKey.AD_STARTED, this.onAdStarted);
            this.off(ImaEventKey.AD_RESUMED, this.onAdResumed);
            // Enable content event listeners
            if (this.onContentSeekableRangeChanged) {
                this.adapter.onSeekableRangeChanged = this.onContentSeekableRangeChanged;
                this.onContentSeekableRangeChanged = null;
            }
            if (this.onContentPositionChanged) {
                this.adapter.onPositionChanged = this.onContentPositionChanged;
                this.onContentPositionChanged = null;
            }
            if (this.onContentPlaybackStateChanged) {
                this.adapter.onPlaybackStateChanged = this.onContentPlaybackStateChanged;
                this.onContentPlaybackStateChanged = null;
            }
        }
        updateAdPlayerState() {
            this.setPlayerState({
                canPause: true,
            });
        }
    };
};
export const WithIma = (Base) => {
    return WithImaEvent(WithImaPlayer(Base));
};
