// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.2
//   protoc               v5.29.3
// source: action.proto
/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Lab_LabInfo } from "./lab";
import { Player_Create, Player_CreateZapi, Player_Destroy, Player_GetAvailableAudioTracks, Player_GetAvailableSubtitlesTracks, Player_GetProperties, Player_GetSelectedAudioTrack, Player_GetSelectedSubtitlesTrack, Player_Pause, Player_Play, Player_PlayLive, Player_PlayProgram, Player_PlayRecording, Player_PlayUrl, Player_PlayVod, Player_Seek, Player_SeekBackward, Player_SeekForward, Player_SetAudioTrack, Player_SetRate, Player_SetSubtitlesTrack, Player_SetVolume, Player_Stop, } from "./player";
import { PlayerOutput_AvailableAudioTracks, PlayerOutput_AvailableAudioTracksChanged, PlayerOutput_AvailableSubtitlesTrackChanged, PlayerOutput_AvailableSubtitlesTracks, PlayerOutput_CurrentPositionChanged, PlayerOutput_MediaChanged, PlayerOutput_PlaybackStateChanged, PlayerOutput_PlayerError, PlayerOutput_PlayerReady, PlayerOutput_PlayerStateChanged, PlayerOutput_Properties, PlayerOutput_QualityChanged, PlayerOutput_Seeked, PlayerOutput_SelectedAudioTrack, PlayerOutput_SelectedAudioTrackChanged, PlayerOutput_SelectedSubtitlesTrack, PlayerOutput_SelectedSubtitlesTrackChanged, PlayerOutput_WatchReceived, PlayerOutput_WatchRequested, } from "./player_output";
import { TafAction_CleanupSession, TafAction_ConfigureStreamingHubProxy, TafAction_ConfigureZapiMockedProxy, TafAction_SetupZapiMockedProxy, TafAction_SetupZapiProxy, TafAction_Sleep, TafAction_StartSession, } from "./taf_action";
import { Zapi_Create, Zapi_SessionHello, Zapi_SignIn } from "./zapi";
export const protobufPackage = "taf";
export var Output_Log_Source;
(function (Output_Log_Source) {
    Output_Log_Source[Output_Log_Source["TAF"] = 0] = "TAF";
    Output_Log_Source[Output_Log_Source["PLAYER"] = 1] = "PLAYER";
    Output_Log_Source[Output_Log_Source["PLAYER_ADAPTER"] = 2] = "PLAYER_ADAPTER";
    Output_Log_Source[Output_Log_Source["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(Output_Log_Source || (Output_Log_Source = {}));
export function output_Log_SourceFromJSON(object) {
    switch (object) {
        case 0:
        case "TAF":
            return Output_Log_Source.TAF;
        case 1:
        case "PLAYER":
            return Output_Log_Source.PLAYER;
        case 2:
        case "PLAYER_ADAPTER":
            return Output_Log_Source.PLAYER_ADAPTER;
        case -1:
        case "UNRECOGNIZED":
        default:
            return Output_Log_Source.UNRECOGNIZED;
    }
}
export function output_Log_SourceToJSON(object) {
    switch (object) {
        case Output_Log_Source.TAF:
            return "TAF";
        case Output_Log_Source.PLAYER:
            return "PLAYER";
        case Output_Log_Source.PLAYER_ADAPTER:
            return "PLAYER_ADAPTER";
        case Output_Log_Source.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var Output_Log_Level;
(function (Output_Log_Level) {
    Output_Log_Level[Output_Log_Level["DEBUG"] = 0] = "DEBUG";
    Output_Log_Level[Output_Log_Level["INFO"] = 1] = "INFO";
    Output_Log_Level[Output_Log_Level["WARN"] = 2] = "WARN";
    Output_Log_Level[Output_Log_Level["ERROR"] = 3] = "ERROR";
    Output_Log_Level[Output_Log_Level["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(Output_Log_Level || (Output_Log_Level = {}));
export function output_Log_LevelFromJSON(object) {
    switch (object) {
        case 0:
        case "DEBUG":
            return Output_Log_Level.DEBUG;
        case 1:
        case "INFO":
            return Output_Log_Level.INFO;
        case 2:
        case "WARN":
            return Output_Log_Level.WARN;
        case 3:
        case "ERROR":
            return Output_Log_Level.ERROR;
        case -1:
        case "UNRECOGNIZED":
        default:
            return Output_Log_Level.UNRECOGNIZED;
    }
}
export function output_Log_LevelToJSON(object) {
    switch (object) {
        case Output_Log_Level.DEBUG:
            return "DEBUG";
        case Output_Log_Level.INFO:
            return "INFO";
        case Output_Log_Level.WARN:
            return "WARN";
        case Output_Log_Level.ERROR:
            return "ERROR";
        case Output_Log_Level.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBaseAction() {
    return {
        id: "",
        session: undefined,
        tafSetupZapiProxy: undefined,
        tafSleep: undefined,
        tafStartSession: undefined,
        tafCleanupSession: undefined,
        tafSetupZapiMockedProxy: undefined,
        tafConfigureZapiMockedProxy: undefined,
        tafConfigureStreamingHubProxy: undefined,
        zapiCreate: undefined,
        zapiSignIn: undefined,
        zapiSessionHello: undefined,
        playerCreate: undefined,
        playerCreateZapi: undefined,
        playerPlayLive: undefined,
        playerPlayVod: undefined,
        playerPlayProgram: undefined,
        playerPlayRecording: undefined,
        playerPlayUrl: undefined,
        playerPlay: undefined,
        playerPause: undefined,
        playerSeek: undefined,
        playerSeekForward: undefined,
        playerSeekBackward: undefined,
        playerSetVolume: undefined,
        playerSetRate: undefined,
        playerGetAvailableAudioTracks: undefined,
        playerSetAudioTrack: undefined,
        playerGetSelectedAudioTrack: undefined,
        playerGetAvailableSubtitlesTracks: undefined,
        playerSetSubtitlesTrack: undefined,
        playerGetSelectedSubtitlesTrack: undefined,
        playerGetProperties: undefined,
        playerStop: undefined,
        playerDestroy: undefined,
    };
}
export const Action = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.session !== undefined) {
            Action_Session.encode(message.session, writer.uint32(18).fork()).ldelim();
        }
        if (message.tafSetupZapiProxy !== undefined) {
            TafAction_SetupZapiProxy.encode(message.tafSetupZapiProxy, writer.uint32(402).fork()).ldelim();
        }
        if (message.tafSleep !== undefined) {
            TafAction_Sleep.encode(message.tafSleep, writer.uint32(410).fork()).ldelim();
        }
        if (message.tafStartSession !== undefined) {
            TafAction_StartSession.encode(message.tafStartSession, writer.uint32(418).fork()).ldelim();
        }
        if (message.tafCleanupSession !== undefined) {
            TafAction_CleanupSession.encode(message.tafCleanupSession, writer.uint32(426).fork()).ldelim();
        }
        if (message.tafSetupZapiMockedProxy !== undefined) {
            TafAction_SetupZapiMockedProxy.encode(message.tafSetupZapiMockedProxy, writer.uint32(434).fork()).ldelim();
        }
        if (message.tafConfigureZapiMockedProxy !== undefined) {
            TafAction_ConfigureZapiMockedProxy.encode(message.tafConfigureZapiMockedProxy, writer.uint32(442).fork())
                .ldelim();
        }
        if (message.tafConfigureStreamingHubProxy !== undefined) {
            TafAction_ConfigureStreamingHubProxy.encode(message.tafConfigureStreamingHubProxy, writer.uint32(450).fork())
                .ldelim();
        }
        if (message.zapiCreate !== undefined) {
            Zapi_Create.encode(message.zapiCreate, writer.uint32(802).fork()).ldelim();
        }
        if (message.zapiSignIn !== undefined) {
            Zapi_SignIn.encode(message.zapiSignIn, writer.uint32(810).fork()).ldelim();
        }
        if (message.zapiSessionHello !== undefined) {
            Zapi_SessionHello.encode(message.zapiSessionHello, writer.uint32(818).fork()).ldelim();
        }
        if (message.playerCreate !== undefined) {
            Player_Create.encode(message.playerCreate, writer.uint32(1602).fork()).ldelim();
        }
        if (message.playerCreateZapi !== undefined) {
            Player_CreateZapi.encode(message.playerCreateZapi, writer.uint32(1610).fork()).ldelim();
        }
        if (message.playerPlayLive !== undefined) {
            Player_PlayLive.encode(message.playerPlayLive, writer.uint32(1618).fork()).ldelim();
        }
        if (message.playerPlayVod !== undefined) {
            Player_PlayVod.encode(message.playerPlayVod, writer.uint32(1626).fork()).ldelim();
        }
        if (message.playerPlayProgram !== undefined) {
            Player_PlayProgram.encode(message.playerPlayProgram, writer.uint32(1634).fork()).ldelim();
        }
        if (message.playerPlayRecording !== undefined) {
            Player_PlayRecording.encode(message.playerPlayRecording, writer.uint32(1642).fork()).ldelim();
        }
        if (message.playerPlayUrl !== undefined) {
            Player_PlayUrl.encode(message.playerPlayUrl, writer.uint32(2002).fork()).ldelim();
        }
        if (message.playerPlay !== undefined) {
            Player_Play.encode(message.playerPlay, writer.uint32(2162).fork()).ldelim();
        }
        if (message.playerPause !== undefined) {
            Player_Pause.encode(message.playerPause, writer.uint32(2170).fork()).ldelim();
        }
        if (message.playerSeek !== undefined) {
            Player_Seek.encode(message.playerSeek, writer.uint32(2178).fork()).ldelim();
        }
        if (message.playerSeekForward !== undefined) {
            Player_SeekForward.encode(message.playerSeekForward, writer.uint32(2186).fork()).ldelim();
        }
        if (message.playerSeekBackward !== undefined) {
            Player_SeekBackward.encode(message.playerSeekBackward, writer.uint32(2194).fork()).ldelim();
        }
        if (message.playerSetVolume !== undefined) {
            Player_SetVolume.encode(message.playerSetVolume, writer.uint32(2202).fork()).ldelim();
        }
        if (message.playerSetRate !== undefined) {
            Player_SetRate.encode(message.playerSetRate, writer.uint32(2210).fork()).ldelim();
        }
        if (message.playerGetAvailableAudioTracks !== undefined) {
            Player_GetAvailableAudioTracks.encode(message.playerGetAvailableAudioTracks, writer.uint32(2402).fork()).ldelim();
        }
        if (message.playerSetAudioTrack !== undefined) {
            Player_SetAudioTrack.encode(message.playerSetAudioTrack, writer.uint32(2410).fork()).ldelim();
        }
        if (message.playerGetSelectedAudioTrack !== undefined) {
            Player_GetSelectedAudioTrack.encode(message.playerGetSelectedAudioTrack, writer.uint32(2418).fork()).ldelim();
        }
        if (message.playerGetAvailableSubtitlesTracks !== undefined) {
            Player_GetAvailableSubtitlesTracks.encode(message.playerGetAvailableSubtitlesTracks, writer.uint32(3202).fork())
                .ldelim();
        }
        if (message.playerSetSubtitlesTrack !== undefined) {
            Player_SetSubtitlesTrack.encode(message.playerSetSubtitlesTrack, writer.uint32(3210).fork()).ldelim();
        }
        if (message.playerGetSelectedSubtitlesTrack !== undefined) {
            Player_GetSelectedSubtitlesTrack.encode(message.playerGetSelectedSubtitlesTrack, writer.uint32(3218).fork())
                .ldelim();
        }
        if (message.playerGetProperties !== undefined) {
            Player_GetProperties.encode(message.playerGetProperties, writer.uint32(3226).fork()).ldelim();
        }
        if (message.playerStop !== undefined) {
            Player_Stop.encode(message.playerStop, writer.uint32(3602).fork()).ldelim();
        }
        if (message.playerDestroy !== undefined) {
            Player_Destroy.encode(message.playerDestroy, writer.uint32(4002).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAction();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.id = reader.string();
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.session = Action_Session.decode(reader, reader.uint32());
                    continue;
                case 50:
                    if (tag !== 402) {
                        break;
                    }
                    message.tafSetupZapiProxy = TafAction_SetupZapiProxy.decode(reader, reader.uint32());
                    continue;
                case 51:
                    if (tag !== 410) {
                        break;
                    }
                    message.tafSleep = TafAction_Sleep.decode(reader, reader.uint32());
                    continue;
                case 52:
                    if (tag !== 418) {
                        break;
                    }
                    message.tafStartSession = TafAction_StartSession.decode(reader, reader.uint32());
                    continue;
                case 53:
                    if (tag !== 426) {
                        break;
                    }
                    message.tafCleanupSession = TafAction_CleanupSession.decode(reader, reader.uint32());
                    continue;
                case 54:
                    if (tag !== 434) {
                        break;
                    }
                    message.tafSetupZapiMockedProxy = TafAction_SetupZapiMockedProxy.decode(reader, reader.uint32());
                    continue;
                case 55:
                    if (tag !== 442) {
                        break;
                    }
                    message.tafConfigureZapiMockedProxy = TafAction_ConfigureZapiMockedProxy.decode(reader, reader.uint32());
                    continue;
                case 56:
                    if (tag !== 450) {
                        break;
                    }
                    message.tafConfigureStreamingHubProxy = TafAction_ConfigureStreamingHubProxy.decode(reader, reader.uint32());
                    continue;
                case 100:
                    if (tag !== 802) {
                        break;
                    }
                    message.zapiCreate = Zapi_Create.decode(reader, reader.uint32());
                    continue;
                case 101:
                    if (tag !== 810) {
                        break;
                    }
                    message.zapiSignIn = Zapi_SignIn.decode(reader, reader.uint32());
                    continue;
                case 102:
                    if (tag !== 818) {
                        break;
                    }
                    message.zapiSessionHello = Zapi_SessionHello.decode(reader, reader.uint32());
                    continue;
                case 200:
                    if (tag !== 1602) {
                        break;
                    }
                    message.playerCreate = Player_Create.decode(reader, reader.uint32());
                    continue;
                case 201:
                    if (tag !== 1610) {
                        break;
                    }
                    message.playerCreateZapi = Player_CreateZapi.decode(reader, reader.uint32());
                    continue;
                case 202:
                    if (tag !== 1618) {
                        break;
                    }
                    message.playerPlayLive = Player_PlayLive.decode(reader, reader.uint32());
                    continue;
                case 203:
                    if (tag !== 1626) {
                        break;
                    }
                    message.playerPlayVod = Player_PlayVod.decode(reader, reader.uint32());
                    continue;
                case 204:
                    if (tag !== 1634) {
                        break;
                    }
                    message.playerPlayProgram = Player_PlayProgram.decode(reader, reader.uint32());
                    continue;
                case 205:
                    if (tag !== 1642) {
                        break;
                    }
                    message.playerPlayRecording = Player_PlayRecording.decode(reader, reader.uint32());
                    continue;
                case 250:
                    if (tag !== 2002) {
                        break;
                    }
                    message.playerPlayUrl = Player_PlayUrl.decode(reader, reader.uint32());
                    continue;
                case 270:
                    if (tag !== 2162) {
                        break;
                    }
                    message.playerPlay = Player_Play.decode(reader, reader.uint32());
                    continue;
                case 271:
                    if (tag !== 2170) {
                        break;
                    }
                    message.playerPause = Player_Pause.decode(reader, reader.uint32());
                    continue;
                case 272:
                    if (tag !== 2178) {
                        break;
                    }
                    message.playerSeek = Player_Seek.decode(reader, reader.uint32());
                    continue;
                case 273:
                    if (tag !== 2186) {
                        break;
                    }
                    message.playerSeekForward = Player_SeekForward.decode(reader, reader.uint32());
                    continue;
                case 274:
                    if (tag !== 2194) {
                        break;
                    }
                    message.playerSeekBackward = Player_SeekBackward.decode(reader, reader.uint32());
                    continue;
                case 275:
                    if (tag !== 2202) {
                        break;
                    }
                    message.playerSetVolume = Player_SetVolume.decode(reader, reader.uint32());
                    continue;
                case 276:
                    if (tag !== 2210) {
                        break;
                    }
                    message.playerSetRate = Player_SetRate.decode(reader, reader.uint32());
                    continue;
                case 300:
                    if (tag !== 2402) {
                        break;
                    }
                    message.playerGetAvailableAudioTracks = Player_GetAvailableAudioTracks.decode(reader, reader.uint32());
                    continue;
                case 301:
                    if (tag !== 2410) {
                        break;
                    }
                    message.playerSetAudioTrack = Player_SetAudioTrack.decode(reader, reader.uint32());
                    continue;
                case 302:
                    if (tag !== 2418) {
                        break;
                    }
                    message.playerGetSelectedAudioTrack = Player_GetSelectedAudioTrack.decode(reader, reader.uint32());
                    continue;
                case 400:
                    if (tag !== 3202) {
                        break;
                    }
                    message.playerGetAvailableSubtitlesTracks = Player_GetAvailableSubtitlesTracks.decode(reader, reader.uint32());
                    continue;
                case 401:
                    if (tag !== 3210) {
                        break;
                    }
                    message.playerSetSubtitlesTrack = Player_SetSubtitlesTrack.decode(reader, reader.uint32());
                    continue;
                case 402:
                    if (tag !== 3218) {
                        break;
                    }
                    message.playerGetSelectedSubtitlesTrack = Player_GetSelectedSubtitlesTrack.decode(reader, reader.uint32());
                    continue;
                case 403:
                    if (tag !== 3226) {
                        break;
                    }
                    message.playerGetProperties = Player_GetProperties.decode(reader, reader.uint32());
                    continue;
                case 450:
                    if (tag !== 3602) {
                        break;
                    }
                    message.playerStop = Player_Stop.decode(reader, reader.uint32());
                    continue;
                case 500:
                    if (tag !== 4002) {
                        break;
                    }
                    message.playerDestroy = Player_Destroy.decode(reader, reader.uint32());
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? globalThis.String(object.id) : "",
            session: isSet(object.session) ? Action_Session.fromJSON(object.session) : undefined,
            tafSetupZapiProxy: isSet(object.tafSetupZapiProxy)
                ? TafAction_SetupZapiProxy.fromJSON(object.tafSetupZapiProxy)
                : undefined,
            tafSleep: isSet(object.tafSleep) ? TafAction_Sleep.fromJSON(object.tafSleep) : undefined,
            tafStartSession: isSet(object.tafStartSession)
                ? TafAction_StartSession.fromJSON(object.tafStartSession)
                : undefined,
            tafCleanupSession: isSet(object.tafCleanupSession)
                ? TafAction_CleanupSession.fromJSON(object.tafCleanupSession)
                : undefined,
            tafSetupZapiMockedProxy: isSet(object.tafSetupZapiMockedProxy)
                ? TafAction_SetupZapiMockedProxy.fromJSON(object.tafSetupZapiMockedProxy)
                : undefined,
            tafConfigureZapiMockedProxy: isSet(object.tafConfigureZapiMockedProxy)
                ? TafAction_ConfigureZapiMockedProxy.fromJSON(object.tafConfigureZapiMockedProxy)
                : undefined,
            tafConfigureStreamingHubProxy: isSet(object.tafConfigureStreamingHubProxy)
                ? TafAction_ConfigureStreamingHubProxy.fromJSON(object.tafConfigureStreamingHubProxy)
                : undefined,
            zapiCreate: isSet(object.zapiCreate) ? Zapi_Create.fromJSON(object.zapiCreate) : undefined,
            zapiSignIn: isSet(object.zapiSignIn) ? Zapi_SignIn.fromJSON(object.zapiSignIn) : undefined,
            zapiSessionHello: isSet(object.zapiSessionHello)
                ? Zapi_SessionHello.fromJSON(object.zapiSessionHello)
                : undefined,
            playerCreate: isSet(object.playerCreate) ? Player_Create.fromJSON(object.playerCreate) : undefined,
            playerCreateZapi: isSet(object.playerCreateZapi)
                ? Player_CreateZapi.fromJSON(object.playerCreateZapi)
                : undefined,
            playerPlayLive: isSet(object.playerPlayLive) ? Player_PlayLive.fromJSON(object.playerPlayLive) : undefined,
            playerPlayVod: isSet(object.playerPlayVod) ? Player_PlayVod.fromJSON(object.playerPlayVod) : undefined,
            playerPlayProgram: isSet(object.playerPlayProgram)
                ? Player_PlayProgram.fromJSON(object.playerPlayProgram)
                : undefined,
            playerPlayRecording: isSet(object.playerPlayRecording)
                ? Player_PlayRecording.fromJSON(object.playerPlayRecording)
                : undefined,
            playerPlayUrl: isSet(object.playerPlayUrl) ? Player_PlayUrl.fromJSON(object.playerPlayUrl) : undefined,
            playerPlay: isSet(object.playerPlay) ? Player_Play.fromJSON(object.playerPlay) : undefined,
            playerPause: isSet(object.playerPause) ? Player_Pause.fromJSON(object.playerPause) : undefined,
            playerSeek: isSet(object.playerSeek) ? Player_Seek.fromJSON(object.playerSeek) : undefined,
            playerSeekForward: isSet(object.playerSeekForward)
                ? Player_SeekForward.fromJSON(object.playerSeekForward)
                : undefined,
            playerSeekBackward: isSet(object.playerSeekBackward)
                ? Player_SeekBackward.fromJSON(object.playerSeekBackward)
                : undefined,
            playerSetVolume: isSet(object.playerSetVolume) ? Player_SetVolume.fromJSON(object.playerSetVolume) : undefined,
            playerSetRate: isSet(object.playerSetRate) ? Player_SetRate.fromJSON(object.playerSetRate) : undefined,
            playerGetAvailableAudioTracks: isSet(object.playerGetAvailableAudioTracks)
                ? Player_GetAvailableAudioTracks.fromJSON(object.playerGetAvailableAudioTracks)
                : undefined,
            playerSetAudioTrack: isSet(object.playerSetAudioTrack)
                ? Player_SetAudioTrack.fromJSON(object.playerSetAudioTrack)
                : undefined,
            playerGetSelectedAudioTrack: isSet(object.playerGetSelectedAudioTrack)
                ? Player_GetSelectedAudioTrack.fromJSON(object.playerGetSelectedAudioTrack)
                : undefined,
            playerGetAvailableSubtitlesTracks: isSet(object.playerGetAvailableSubtitlesTracks)
                ? Player_GetAvailableSubtitlesTracks.fromJSON(object.playerGetAvailableSubtitlesTracks)
                : undefined,
            playerSetSubtitlesTrack: isSet(object.playerSetSubtitlesTrack)
                ? Player_SetSubtitlesTrack.fromJSON(object.playerSetSubtitlesTrack)
                : undefined,
            playerGetSelectedSubtitlesTrack: isSet(object.playerGetSelectedSubtitlesTrack)
                ? Player_GetSelectedSubtitlesTrack.fromJSON(object.playerGetSelectedSubtitlesTrack)
                : undefined,
            playerGetProperties: isSet(object.playerGetProperties)
                ? Player_GetProperties.fromJSON(object.playerGetProperties)
                : undefined,
            playerStop: isSet(object.playerStop) ? Player_Stop.fromJSON(object.playerStop) : undefined,
            playerDestroy: isSet(object.playerDestroy) ? Player_Destroy.fromJSON(object.playerDestroy) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.id !== "") {
            obj.id = message.id;
        }
        if (message.session !== undefined) {
            obj.session = Action_Session.toJSON(message.session);
        }
        if (message.tafSetupZapiProxy !== undefined) {
            obj.tafSetupZapiProxy = TafAction_SetupZapiProxy.toJSON(message.tafSetupZapiProxy);
        }
        if (message.tafSleep !== undefined) {
            obj.tafSleep = TafAction_Sleep.toJSON(message.tafSleep);
        }
        if (message.tafStartSession !== undefined) {
            obj.tafStartSession = TafAction_StartSession.toJSON(message.tafStartSession);
        }
        if (message.tafCleanupSession !== undefined) {
            obj.tafCleanupSession = TafAction_CleanupSession.toJSON(message.tafCleanupSession);
        }
        if (message.tafSetupZapiMockedProxy !== undefined) {
            obj.tafSetupZapiMockedProxy = TafAction_SetupZapiMockedProxy.toJSON(message.tafSetupZapiMockedProxy);
        }
        if (message.tafConfigureZapiMockedProxy !== undefined) {
            obj.tafConfigureZapiMockedProxy = TafAction_ConfigureZapiMockedProxy.toJSON(message.tafConfigureZapiMockedProxy);
        }
        if (message.tafConfigureStreamingHubProxy !== undefined) {
            obj.tafConfigureStreamingHubProxy = TafAction_ConfigureStreamingHubProxy.toJSON(message.tafConfigureStreamingHubProxy);
        }
        if (message.zapiCreate !== undefined) {
            obj.zapiCreate = Zapi_Create.toJSON(message.zapiCreate);
        }
        if (message.zapiSignIn !== undefined) {
            obj.zapiSignIn = Zapi_SignIn.toJSON(message.zapiSignIn);
        }
        if (message.zapiSessionHello !== undefined) {
            obj.zapiSessionHello = Zapi_SessionHello.toJSON(message.zapiSessionHello);
        }
        if (message.playerCreate !== undefined) {
            obj.playerCreate = Player_Create.toJSON(message.playerCreate);
        }
        if (message.playerCreateZapi !== undefined) {
            obj.playerCreateZapi = Player_CreateZapi.toJSON(message.playerCreateZapi);
        }
        if (message.playerPlayLive !== undefined) {
            obj.playerPlayLive = Player_PlayLive.toJSON(message.playerPlayLive);
        }
        if (message.playerPlayVod !== undefined) {
            obj.playerPlayVod = Player_PlayVod.toJSON(message.playerPlayVod);
        }
        if (message.playerPlayProgram !== undefined) {
            obj.playerPlayProgram = Player_PlayProgram.toJSON(message.playerPlayProgram);
        }
        if (message.playerPlayRecording !== undefined) {
            obj.playerPlayRecording = Player_PlayRecording.toJSON(message.playerPlayRecording);
        }
        if (message.playerPlayUrl !== undefined) {
            obj.playerPlayUrl = Player_PlayUrl.toJSON(message.playerPlayUrl);
        }
        if (message.playerPlay !== undefined) {
            obj.playerPlay = Player_Play.toJSON(message.playerPlay);
        }
        if (message.playerPause !== undefined) {
            obj.playerPause = Player_Pause.toJSON(message.playerPause);
        }
        if (message.playerSeek !== undefined) {
            obj.playerSeek = Player_Seek.toJSON(message.playerSeek);
        }
        if (message.playerSeekForward !== undefined) {
            obj.playerSeekForward = Player_SeekForward.toJSON(message.playerSeekForward);
        }
        if (message.playerSeekBackward !== undefined) {
            obj.playerSeekBackward = Player_SeekBackward.toJSON(message.playerSeekBackward);
        }
        if (message.playerSetVolume !== undefined) {
            obj.playerSetVolume = Player_SetVolume.toJSON(message.playerSetVolume);
        }
        if (message.playerSetRate !== undefined) {
            obj.playerSetRate = Player_SetRate.toJSON(message.playerSetRate);
        }
        if (message.playerGetAvailableAudioTracks !== undefined) {
            obj.playerGetAvailableAudioTracks = Player_GetAvailableAudioTracks.toJSON(message.playerGetAvailableAudioTracks);
        }
        if (message.playerSetAudioTrack !== undefined) {
            obj.playerSetAudioTrack = Player_SetAudioTrack.toJSON(message.playerSetAudioTrack);
        }
        if (message.playerGetSelectedAudioTrack !== undefined) {
            obj.playerGetSelectedAudioTrack = Player_GetSelectedAudioTrack.toJSON(message.playerGetSelectedAudioTrack);
        }
        if (message.playerGetAvailableSubtitlesTracks !== undefined) {
            obj.playerGetAvailableSubtitlesTracks = Player_GetAvailableSubtitlesTracks.toJSON(message.playerGetAvailableSubtitlesTracks);
        }
        if (message.playerSetSubtitlesTrack !== undefined) {
            obj.playerSetSubtitlesTrack = Player_SetSubtitlesTrack.toJSON(message.playerSetSubtitlesTrack);
        }
        if (message.playerGetSelectedSubtitlesTrack !== undefined) {
            obj.playerGetSelectedSubtitlesTrack = Player_GetSelectedSubtitlesTrack.toJSON(message.playerGetSelectedSubtitlesTrack);
        }
        if (message.playerGetProperties !== undefined) {
            obj.playerGetProperties = Player_GetProperties.toJSON(message.playerGetProperties);
        }
        if (message.playerStop !== undefined) {
            obj.playerStop = Player_Stop.toJSON(message.playerStop);
        }
        if (message.playerDestroy !== undefined) {
            obj.playerDestroy = Player_Destroy.toJSON(message.playerDestroy);
        }
        return obj;
    },
    create(base) {
        return Action.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseAction();
        message.id = object.id ?? "";
        message.session = (object.session !== undefined && object.session !== null)
            ? Action_Session.fromPartial(object.session)
            : undefined;
        message.tafSetupZapiProxy = (object.tafSetupZapiProxy !== undefined && object.tafSetupZapiProxy !== null)
            ? TafAction_SetupZapiProxy.fromPartial(object.tafSetupZapiProxy)
            : undefined;
        message.tafSleep = (object.tafSleep !== undefined && object.tafSleep !== null)
            ? TafAction_Sleep.fromPartial(object.tafSleep)
            : undefined;
        message.tafStartSession = (object.tafStartSession !== undefined && object.tafStartSession !== null)
            ? TafAction_StartSession.fromPartial(object.tafStartSession)
            : undefined;
        message.tafCleanupSession = (object.tafCleanupSession !== undefined && object.tafCleanupSession !== null)
            ? TafAction_CleanupSession.fromPartial(object.tafCleanupSession)
            : undefined;
        message.tafSetupZapiMockedProxy =
            (object.tafSetupZapiMockedProxy !== undefined && object.tafSetupZapiMockedProxy !== null)
                ? TafAction_SetupZapiMockedProxy.fromPartial(object.tafSetupZapiMockedProxy)
                : undefined;
        message.tafConfigureZapiMockedProxy =
            (object.tafConfigureZapiMockedProxy !== undefined && object.tafConfigureZapiMockedProxy !== null)
                ? TafAction_ConfigureZapiMockedProxy.fromPartial(object.tafConfigureZapiMockedProxy)
                : undefined;
        message.tafConfigureStreamingHubProxy =
            (object.tafConfigureStreamingHubProxy !== undefined && object.tafConfigureStreamingHubProxy !== null)
                ? TafAction_ConfigureStreamingHubProxy.fromPartial(object.tafConfigureStreamingHubProxy)
                : undefined;
        message.zapiCreate = (object.zapiCreate !== undefined && object.zapiCreate !== null)
            ? Zapi_Create.fromPartial(object.zapiCreate)
            : undefined;
        message.zapiSignIn = (object.zapiSignIn !== undefined && object.zapiSignIn !== null)
            ? Zapi_SignIn.fromPartial(object.zapiSignIn)
            : undefined;
        message.zapiSessionHello = (object.zapiSessionHello !== undefined && object.zapiSessionHello !== null)
            ? Zapi_SessionHello.fromPartial(object.zapiSessionHello)
            : undefined;
        message.playerCreate = (object.playerCreate !== undefined && object.playerCreate !== null)
            ? Player_Create.fromPartial(object.playerCreate)
            : undefined;
        message.playerCreateZapi = (object.playerCreateZapi !== undefined && object.playerCreateZapi !== null)
            ? Player_CreateZapi.fromPartial(object.playerCreateZapi)
            : undefined;
        message.playerPlayLive = (object.playerPlayLive !== undefined && object.playerPlayLive !== null)
            ? Player_PlayLive.fromPartial(object.playerPlayLive)
            : undefined;
        message.playerPlayVod = (object.playerPlayVod !== undefined && object.playerPlayVod !== null)
            ? Player_PlayVod.fromPartial(object.playerPlayVod)
            : undefined;
        message.playerPlayProgram = (object.playerPlayProgram !== undefined && object.playerPlayProgram !== null)
            ? Player_PlayProgram.fromPartial(object.playerPlayProgram)
            : undefined;
        message.playerPlayRecording = (object.playerPlayRecording !== undefined && object.playerPlayRecording !== null)
            ? Player_PlayRecording.fromPartial(object.playerPlayRecording)
            : undefined;
        message.playerPlayUrl = (object.playerPlayUrl !== undefined && object.playerPlayUrl !== null)
            ? Player_PlayUrl.fromPartial(object.playerPlayUrl)
            : undefined;
        message.playerPlay = (object.playerPlay !== undefined && object.playerPlay !== null)
            ? Player_Play.fromPartial(object.playerPlay)
            : undefined;
        message.playerPause = (object.playerPause !== undefined && object.playerPause !== null)
            ? Player_Pause.fromPartial(object.playerPause)
            : undefined;
        message.playerSeek = (object.playerSeek !== undefined && object.playerSeek !== null)
            ? Player_Seek.fromPartial(object.playerSeek)
            : undefined;
        message.playerSeekForward = (object.playerSeekForward !== undefined && object.playerSeekForward !== null)
            ? Player_SeekForward.fromPartial(object.playerSeekForward)
            : undefined;
        message.playerSeekBackward = (object.playerSeekBackward !== undefined && object.playerSeekBackward !== null)
            ? Player_SeekBackward.fromPartial(object.playerSeekBackward)
            : undefined;
        message.playerSetVolume = (object.playerSetVolume !== undefined && object.playerSetVolume !== null)
            ? Player_SetVolume.fromPartial(object.playerSetVolume)
            : undefined;
        message.playerSetRate = (object.playerSetRate !== undefined && object.playerSetRate !== null)
            ? Player_SetRate.fromPartial(object.playerSetRate)
            : undefined;
        message.playerGetAvailableAudioTracks =
            (object.playerGetAvailableAudioTracks !== undefined && object.playerGetAvailableAudioTracks !== null)
                ? Player_GetAvailableAudioTracks.fromPartial(object.playerGetAvailableAudioTracks)
                : undefined;
        message.playerSetAudioTrack = (object.playerSetAudioTrack !== undefined && object.playerSetAudioTrack !== null)
            ? Player_SetAudioTrack.fromPartial(object.playerSetAudioTrack)
            : undefined;
        message.playerGetSelectedAudioTrack =
            (object.playerGetSelectedAudioTrack !== undefined && object.playerGetSelectedAudioTrack !== null)
                ? Player_GetSelectedAudioTrack.fromPartial(object.playerGetSelectedAudioTrack)
                : undefined;
        message.playerGetAvailableSubtitlesTracks =
            (object.playerGetAvailableSubtitlesTracks !== undefined && object.playerGetAvailableSubtitlesTracks !== null)
                ? Player_GetAvailableSubtitlesTracks.fromPartial(object.playerGetAvailableSubtitlesTracks)
                : undefined;
        message.playerSetSubtitlesTrack =
            (object.playerSetSubtitlesTrack !== undefined && object.playerSetSubtitlesTrack !== null)
                ? Player_SetSubtitlesTrack.fromPartial(object.playerSetSubtitlesTrack)
                : undefined;
        message.playerGetSelectedSubtitlesTrack =
            (object.playerGetSelectedSubtitlesTrack !== undefined && object.playerGetSelectedSubtitlesTrack !== null)
                ? Player_GetSelectedSubtitlesTrack.fromPartial(object.playerGetSelectedSubtitlesTrack)
                : undefined;
        message.playerGetProperties = (object.playerGetProperties !== undefined && object.playerGetProperties !== null)
            ? Player_GetProperties.fromPartial(object.playerGetProperties)
            : undefined;
        message.playerStop = (object.playerStop !== undefined && object.playerStop !== null)
            ? Player_Stop.fromPartial(object.playerStop)
            : undefined;
        message.playerDestroy = (object.playerDestroy !== undefined && object.playerDestroy !== null)
            ? Player_Destroy.fromPartial(object.playerDestroy)
            : undefined;
        return message;
    },
};
function createBaseAction_Session() {
    return { sessionId: "" };
}
export const Action_Session = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.sessionId !== "") {
            writer.uint32(10).string(message.sessionId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAction_Session();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.sessionId = reader.string();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return { sessionId: isSet(object.sessionId) ? globalThis.String(object.sessionId) : "" };
    },
    toJSON(message) {
        const obj = {};
        if (message.sessionId !== "") {
            obj.sessionId = message.sessionId;
        }
        return obj;
    },
    create(base) {
        return Action_Session.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseAction_Session();
        message.sessionId = object.sessionId ?? "";
        return message;
    },
};
function createBaseOutput() {
    return {
        deviceInfo: undefined,
        tafSessions: undefined,
        playerEvent: undefined,
        actionResult: undefined,
        tafLabs: undefined,
        log: undefined,
    };
}
export const Output = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.deviceInfo !== undefined) {
            Output_DeviceInfo.encode(message.deviceInfo, writer.uint32(10).fork()).ldelim();
        }
        if (message.tafSessions !== undefined) {
            Output_TafSessions.encode(message.tafSessions, writer.uint32(18).fork()).ldelim();
        }
        if (message.playerEvent !== undefined) {
            Output_PlayerEvent.encode(message.playerEvent, writer.uint32(26).fork()).ldelim();
        }
        if (message.actionResult !== undefined) {
            Output_ActionResult.encode(message.actionResult, writer.uint32(34).fork()).ldelim();
        }
        if (message.tafLabs !== undefined) {
            Output_TafLabs.encode(message.tafLabs, writer.uint32(42).fork()).ldelim();
        }
        if (message.log !== undefined) {
            Output_Log.encode(message.log, writer.uint32(50).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOutput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.deviceInfo = Output_DeviceInfo.decode(reader, reader.uint32());
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.tafSessions = Output_TafSessions.decode(reader, reader.uint32());
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }
                    message.playerEvent = Output_PlayerEvent.decode(reader, reader.uint32());
                    continue;
                case 4:
                    if (tag !== 34) {
                        break;
                    }
                    message.actionResult = Output_ActionResult.decode(reader, reader.uint32());
                    continue;
                case 5:
                    if (tag !== 42) {
                        break;
                    }
                    message.tafLabs = Output_TafLabs.decode(reader, reader.uint32());
                    continue;
                case 6:
                    if (tag !== 50) {
                        break;
                    }
                    message.log = Output_Log.decode(reader, reader.uint32());
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            deviceInfo: isSet(object.deviceInfo) ? Output_DeviceInfo.fromJSON(object.deviceInfo) : undefined,
            tafSessions: isSet(object.tafSessions) ? Output_TafSessions.fromJSON(object.tafSessions) : undefined,
            playerEvent: isSet(object.playerEvent) ? Output_PlayerEvent.fromJSON(object.playerEvent) : undefined,
            actionResult: isSet(object.actionResult) ? Output_ActionResult.fromJSON(object.actionResult) : undefined,
            tafLabs: isSet(object.tafLabs) ? Output_TafLabs.fromJSON(object.tafLabs) : undefined,
            log: isSet(object.log) ? Output_Log.fromJSON(object.log) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.deviceInfo !== undefined) {
            obj.deviceInfo = Output_DeviceInfo.toJSON(message.deviceInfo);
        }
        if (message.tafSessions !== undefined) {
            obj.tafSessions = Output_TafSessions.toJSON(message.tafSessions);
        }
        if (message.playerEvent !== undefined) {
            obj.playerEvent = Output_PlayerEvent.toJSON(message.playerEvent);
        }
        if (message.actionResult !== undefined) {
            obj.actionResult = Output_ActionResult.toJSON(message.actionResult);
        }
        if (message.tafLabs !== undefined) {
            obj.tafLabs = Output_TafLabs.toJSON(message.tafLabs);
        }
        if (message.log !== undefined) {
            obj.log = Output_Log.toJSON(message.log);
        }
        return obj;
    },
    create(base) {
        return Output.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseOutput();
        message.deviceInfo = (object.deviceInfo !== undefined && object.deviceInfo !== null)
            ? Output_DeviceInfo.fromPartial(object.deviceInfo)
            : undefined;
        message.tafSessions = (object.tafSessions !== undefined && object.tafSessions !== null)
            ? Output_TafSessions.fromPartial(object.tafSessions)
            : undefined;
        message.playerEvent = (object.playerEvent !== undefined && object.playerEvent !== null)
            ? Output_PlayerEvent.fromPartial(object.playerEvent)
            : undefined;
        message.actionResult = (object.actionResult !== undefined && object.actionResult !== null)
            ? Output_ActionResult.fromPartial(object.actionResult)
            : undefined;
        message.tafLabs = (object.tafLabs !== undefined && object.tafLabs !== null)
            ? Output_TafLabs.fromPartial(object.tafLabs)
            : undefined;
        message.log = (object.log !== undefined && object.log !== null) ? Output_Log.fromPartial(object.log) : undefined;
        return message;
    },
};
function createBaseOutput_DeviceInfo() {
    return { name: "", id: "", platform: "" };
}
export const Output_DeviceInfo = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.name !== "") {
            writer.uint32(10).string(message.name);
        }
        if (message.id !== "") {
            writer.uint32(18).string(message.id);
        }
        if (message.platform !== "") {
            writer.uint32(26).string(message.platform);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOutput_DeviceInfo();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.name = reader.string();
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.id = reader.string();
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }
                    message.platform = reader.string();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            name: isSet(object.name) ? globalThis.String(object.name) : "",
            id: isSet(object.id) ? globalThis.String(object.id) : "",
            platform: isSet(object.platform) ? globalThis.String(object.platform) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.name !== "") {
            obj.name = message.name;
        }
        if (message.id !== "") {
            obj.id = message.id;
        }
        if (message.platform !== "") {
            obj.platform = message.platform;
        }
        return obj;
    },
    create(base) {
        return Output_DeviceInfo.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseOutput_DeviceInfo();
        message.name = object.name ?? "";
        message.id = object.id ?? "";
        message.platform = object.platform ?? "";
        return message;
    },
};
function createBaseOutput_SessionInfo() {
    return { name: "", id: "", platform: "", ip: "", ready: false };
}
export const Output_SessionInfo = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.name !== "") {
            writer.uint32(10).string(message.name);
        }
        if (message.id !== "") {
            writer.uint32(18).string(message.id);
        }
        if (message.platform !== "") {
            writer.uint32(26).string(message.platform);
        }
        if (message.ip !== "") {
            writer.uint32(34).string(message.ip);
        }
        if (message.ready !== false) {
            writer.uint32(40).bool(message.ready);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOutput_SessionInfo();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.name = reader.string();
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.id = reader.string();
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }
                    message.platform = reader.string();
                    continue;
                case 4:
                    if (tag !== 34) {
                        break;
                    }
                    message.ip = reader.string();
                    continue;
                case 5:
                    if (tag !== 40) {
                        break;
                    }
                    message.ready = reader.bool();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            name: isSet(object.name) ? globalThis.String(object.name) : "",
            id: isSet(object.id) ? globalThis.String(object.id) : "",
            platform: isSet(object.platform) ? globalThis.String(object.platform) : "",
            ip: isSet(object.ip) ? globalThis.String(object.ip) : "",
            ready: isSet(object.ready) ? globalThis.Boolean(object.ready) : false,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.name !== "") {
            obj.name = message.name;
        }
        if (message.id !== "") {
            obj.id = message.id;
        }
        if (message.platform !== "") {
            obj.platform = message.platform;
        }
        if (message.ip !== "") {
            obj.ip = message.ip;
        }
        if (message.ready !== false) {
            obj.ready = message.ready;
        }
        return obj;
    },
    create(base) {
        return Output_SessionInfo.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseOutput_SessionInfo();
        message.name = object.name ?? "";
        message.id = object.id ?? "";
        message.platform = object.platform ?? "";
        message.ip = object.ip ?? "";
        message.ready = object.ready ?? false;
        return message;
    },
};
function createBaseOutput_TafSessions() {
    return { sessions: [] };
}
export const Output_TafSessions = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.sessions) {
            Output_SessionInfo.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOutput_TafSessions();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.sessions.push(Output_SessionInfo.decode(reader, reader.uint32()));
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            sessions: globalThis.Array.isArray(object?.sessions)
                ? object.sessions.map((e) => Output_SessionInfo.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.sessions?.length) {
            obj.sessions = message.sessions.map((e) => Output_SessionInfo.toJSON(e));
        }
        return obj;
    },
    create(base) {
        return Output_TafSessions.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseOutput_TafSessions();
        message.sessions = object.sessions?.map((e) => Output_SessionInfo.fromPartial(e)) || [];
        return message;
    },
};
function createBaseOutput_TafLabs() {
    return { labInfo: [] };
}
export const Output_TafLabs = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.labInfo) {
            Lab_LabInfo.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOutput_TafLabs();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.labInfo.push(Lab_LabInfo.decode(reader, reader.uint32()));
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            labInfo: globalThis.Array.isArray(object?.labInfo) ? object.labInfo.map((e) => Lab_LabInfo.fromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.labInfo?.length) {
            obj.labInfo = message.labInfo.map((e) => Lab_LabInfo.toJSON(e));
        }
        return obj;
    },
    create(base) {
        return Output_TafLabs.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseOutput_TafLabs();
        message.labInfo = object.labInfo?.map((e) => Lab_LabInfo.fromPartial(e)) || [];
        return message;
    },
};
function createBaseOutput_Log() {
    return { source: 0, level: 0, message: [] };
}
export const Output_Log = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.source !== 0) {
            writer.uint32(8).int32(message.source);
        }
        if (message.level !== 0) {
            writer.uint32(16).int32(message.level);
        }
        for (const v of message.message) {
            writer.uint32(26).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOutput_Log();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 8) {
                        break;
                    }
                    message.source = reader.int32();
                    continue;
                case 2:
                    if (tag !== 16) {
                        break;
                    }
                    message.level = reader.int32();
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }
                    message.message.push(reader.string());
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            source: isSet(object.source) ? output_Log_SourceFromJSON(object.source) : 0,
            level: isSet(object.level) ? output_Log_LevelFromJSON(object.level) : 0,
            message: globalThis.Array.isArray(object?.message) ? object.message.map((e) => globalThis.String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.source !== 0) {
            obj.source = output_Log_SourceToJSON(message.source);
        }
        if (message.level !== 0) {
            obj.level = output_Log_LevelToJSON(message.level);
        }
        if (message.message?.length) {
            obj.message = message.message;
        }
        return obj;
    },
    create(base) {
        return Output_Log.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseOutput_Log();
        message.source = object.source ?? 0;
        message.level = object.level ?? 0;
        message.message = object.message?.map((e) => e) || [];
        return message;
    },
};
function createBaseOutput_PlayerEvent() {
    return {
        watchRequested: undefined,
        watchReceived: undefined,
        playerReady: undefined,
        mediaChanged: undefined,
        currentPositionChanged: undefined,
        seeked: undefined,
        playbackStateChanged: undefined,
        playerStateChanged: undefined,
        availableAudioTracksChanged: undefined,
        selectedAudioTrackChanged: undefined,
        availableSubtitlesTrackChanged: undefined,
        selectedSubtitlesTrackChanged: undefined,
        qualityChanged: undefined,
        playerError: undefined,
    };
}
export const Output_PlayerEvent = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.watchRequested !== undefined) {
            PlayerOutput_WatchRequested.encode(message.watchRequested, writer.uint32(74).fork()).ldelim();
        }
        if (message.watchReceived !== undefined) {
            PlayerOutput_WatchReceived.encode(message.watchReceived, writer.uint32(82).fork()).ldelim();
        }
        if (message.playerReady !== undefined) {
            PlayerOutput_PlayerReady.encode(message.playerReady, writer.uint32(90).fork()).ldelim();
        }
        if (message.mediaChanged !== undefined) {
            PlayerOutput_MediaChanged.encode(message.mediaChanged, writer.uint32(98).fork()).ldelim();
        }
        if (message.currentPositionChanged !== undefined) {
            PlayerOutput_CurrentPositionChanged.encode(message.currentPositionChanged, writer.uint32(106).fork()).ldelim();
        }
        if (message.seeked !== undefined) {
            PlayerOutput_Seeked.encode(message.seeked, writer.uint32(114).fork()).ldelim();
        }
        if (message.playbackStateChanged !== undefined) {
            PlayerOutput_PlaybackStateChanged.encode(message.playbackStateChanged, writer.uint32(10).fork()).ldelim();
        }
        if (message.playerStateChanged !== undefined) {
            PlayerOutput_PlayerStateChanged.encode(message.playerStateChanged, writer.uint32(122).fork()).ldelim();
        }
        if (message.availableAudioTracksChanged !== undefined) {
            PlayerOutput_AvailableAudioTracksChanged.encode(message.availableAudioTracksChanged, writer.uint32(42).fork())
                .ldelim();
        }
        if (message.selectedAudioTrackChanged !== undefined) {
            PlayerOutput_SelectedAudioTrackChanged.encode(message.selectedAudioTrackChanged, writer.uint32(50).fork())
                .ldelim();
        }
        if (message.availableSubtitlesTrackChanged !== undefined) {
            PlayerOutput_AvailableSubtitlesTrackChanged.encode(message.availableSubtitlesTrackChanged, writer.uint32(58).fork()).ldelim();
        }
        if (message.selectedSubtitlesTrackChanged !== undefined) {
            PlayerOutput_SelectedSubtitlesTrackChanged.encode(message.selectedSubtitlesTrackChanged, writer.uint32(66).fork())
                .ldelim();
        }
        if (message.qualityChanged !== undefined) {
            PlayerOutput_QualityChanged.encode(message.qualityChanged, writer.uint32(138).fork()).ldelim();
        }
        if (message.playerError !== undefined) {
            PlayerOutput_PlayerError.encode(message.playerError, writer.uint32(130).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOutput_PlayerEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 9:
                    if (tag !== 74) {
                        break;
                    }
                    message.watchRequested = PlayerOutput_WatchRequested.decode(reader, reader.uint32());
                    continue;
                case 10:
                    if (tag !== 82) {
                        break;
                    }
                    message.watchReceived = PlayerOutput_WatchReceived.decode(reader, reader.uint32());
                    continue;
                case 11:
                    if (tag !== 90) {
                        break;
                    }
                    message.playerReady = PlayerOutput_PlayerReady.decode(reader, reader.uint32());
                    continue;
                case 12:
                    if (tag !== 98) {
                        break;
                    }
                    message.mediaChanged = PlayerOutput_MediaChanged.decode(reader, reader.uint32());
                    continue;
                case 13:
                    if (tag !== 106) {
                        break;
                    }
                    message.currentPositionChanged = PlayerOutput_CurrentPositionChanged.decode(reader, reader.uint32());
                    continue;
                case 14:
                    if (tag !== 114) {
                        break;
                    }
                    message.seeked = PlayerOutput_Seeked.decode(reader, reader.uint32());
                    continue;
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.playbackStateChanged = PlayerOutput_PlaybackStateChanged.decode(reader, reader.uint32());
                    continue;
                case 15:
                    if (tag !== 122) {
                        break;
                    }
                    message.playerStateChanged = PlayerOutput_PlayerStateChanged.decode(reader, reader.uint32());
                    continue;
                case 5:
                    if (tag !== 42) {
                        break;
                    }
                    message.availableAudioTracksChanged = PlayerOutput_AvailableAudioTracksChanged.decode(reader, reader.uint32());
                    continue;
                case 6:
                    if (tag !== 50) {
                        break;
                    }
                    message.selectedAudioTrackChanged = PlayerOutput_SelectedAudioTrackChanged.decode(reader, reader.uint32());
                    continue;
                case 7:
                    if (tag !== 58) {
                        break;
                    }
                    message.availableSubtitlesTrackChanged = PlayerOutput_AvailableSubtitlesTrackChanged.decode(reader, reader.uint32());
                    continue;
                case 8:
                    if (tag !== 66) {
                        break;
                    }
                    message.selectedSubtitlesTrackChanged = PlayerOutput_SelectedSubtitlesTrackChanged.decode(reader, reader.uint32());
                    continue;
                case 17:
                    if (tag !== 138) {
                        break;
                    }
                    message.qualityChanged = PlayerOutput_QualityChanged.decode(reader, reader.uint32());
                    continue;
                case 16:
                    if (tag !== 130) {
                        break;
                    }
                    message.playerError = PlayerOutput_PlayerError.decode(reader, reader.uint32());
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            watchRequested: isSet(object.watchRequested)
                ? PlayerOutput_WatchRequested.fromJSON(object.watchRequested)
                : undefined,
            watchReceived: isSet(object.watchReceived)
                ? PlayerOutput_WatchReceived.fromJSON(object.watchReceived)
                : undefined,
            playerReady: isSet(object.playerReady) ? PlayerOutput_PlayerReady.fromJSON(object.playerReady) : undefined,
            mediaChanged: isSet(object.mediaChanged) ? PlayerOutput_MediaChanged.fromJSON(object.mediaChanged) : undefined,
            currentPositionChanged: isSet(object.currentPositionChanged)
                ? PlayerOutput_CurrentPositionChanged.fromJSON(object.currentPositionChanged)
                : undefined,
            seeked: isSet(object.seeked) ? PlayerOutput_Seeked.fromJSON(object.seeked) : undefined,
            playbackStateChanged: isSet(object.playbackStateChanged)
                ? PlayerOutput_PlaybackStateChanged.fromJSON(object.playbackStateChanged)
                : undefined,
            playerStateChanged: isSet(object.playerStateChanged)
                ? PlayerOutput_PlayerStateChanged.fromJSON(object.playerStateChanged)
                : undefined,
            availableAudioTracksChanged: isSet(object.availableAudioTracksChanged)
                ? PlayerOutput_AvailableAudioTracksChanged.fromJSON(object.availableAudioTracksChanged)
                : undefined,
            selectedAudioTrackChanged: isSet(object.selectedAudioTrackChanged)
                ? PlayerOutput_SelectedAudioTrackChanged.fromJSON(object.selectedAudioTrackChanged)
                : undefined,
            availableSubtitlesTrackChanged: isSet(object.availableSubtitlesTrackChanged)
                ? PlayerOutput_AvailableSubtitlesTrackChanged.fromJSON(object.availableSubtitlesTrackChanged)
                : undefined,
            selectedSubtitlesTrackChanged: isSet(object.selectedSubtitlesTrackChanged)
                ? PlayerOutput_SelectedSubtitlesTrackChanged.fromJSON(object.selectedSubtitlesTrackChanged)
                : undefined,
            qualityChanged: isSet(object.qualityChanged)
                ? PlayerOutput_QualityChanged.fromJSON(object.qualityChanged)
                : undefined,
            playerError: isSet(object.playerError) ? PlayerOutput_PlayerError.fromJSON(object.playerError) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.watchRequested !== undefined) {
            obj.watchRequested = PlayerOutput_WatchRequested.toJSON(message.watchRequested);
        }
        if (message.watchReceived !== undefined) {
            obj.watchReceived = PlayerOutput_WatchReceived.toJSON(message.watchReceived);
        }
        if (message.playerReady !== undefined) {
            obj.playerReady = PlayerOutput_PlayerReady.toJSON(message.playerReady);
        }
        if (message.mediaChanged !== undefined) {
            obj.mediaChanged = PlayerOutput_MediaChanged.toJSON(message.mediaChanged);
        }
        if (message.currentPositionChanged !== undefined) {
            obj.currentPositionChanged = PlayerOutput_CurrentPositionChanged.toJSON(message.currentPositionChanged);
        }
        if (message.seeked !== undefined) {
            obj.seeked = PlayerOutput_Seeked.toJSON(message.seeked);
        }
        if (message.playbackStateChanged !== undefined) {
            obj.playbackStateChanged = PlayerOutput_PlaybackStateChanged.toJSON(message.playbackStateChanged);
        }
        if (message.playerStateChanged !== undefined) {
            obj.playerStateChanged = PlayerOutput_PlayerStateChanged.toJSON(message.playerStateChanged);
        }
        if (message.availableAudioTracksChanged !== undefined) {
            obj.availableAudioTracksChanged = PlayerOutput_AvailableAudioTracksChanged.toJSON(message.availableAudioTracksChanged);
        }
        if (message.selectedAudioTrackChanged !== undefined) {
            obj.selectedAudioTrackChanged = PlayerOutput_SelectedAudioTrackChanged.toJSON(message.selectedAudioTrackChanged);
        }
        if (message.availableSubtitlesTrackChanged !== undefined) {
            obj.availableSubtitlesTrackChanged = PlayerOutput_AvailableSubtitlesTrackChanged.toJSON(message.availableSubtitlesTrackChanged);
        }
        if (message.selectedSubtitlesTrackChanged !== undefined) {
            obj.selectedSubtitlesTrackChanged = PlayerOutput_SelectedSubtitlesTrackChanged.toJSON(message.selectedSubtitlesTrackChanged);
        }
        if (message.qualityChanged !== undefined) {
            obj.qualityChanged = PlayerOutput_QualityChanged.toJSON(message.qualityChanged);
        }
        if (message.playerError !== undefined) {
            obj.playerError = PlayerOutput_PlayerError.toJSON(message.playerError);
        }
        return obj;
    },
    create(base) {
        return Output_PlayerEvent.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseOutput_PlayerEvent();
        message.watchRequested = (object.watchRequested !== undefined && object.watchRequested !== null)
            ? PlayerOutput_WatchRequested.fromPartial(object.watchRequested)
            : undefined;
        message.watchReceived = (object.watchReceived !== undefined && object.watchReceived !== null)
            ? PlayerOutput_WatchReceived.fromPartial(object.watchReceived)
            : undefined;
        message.playerReady = (object.playerReady !== undefined && object.playerReady !== null)
            ? PlayerOutput_PlayerReady.fromPartial(object.playerReady)
            : undefined;
        message.mediaChanged = (object.mediaChanged !== undefined && object.mediaChanged !== null)
            ? PlayerOutput_MediaChanged.fromPartial(object.mediaChanged)
            : undefined;
        message.currentPositionChanged =
            (object.currentPositionChanged !== undefined && object.currentPositionChanged !== null)
                ? PlayerOutput_CurrentPositionChanged.fromPartial(object.currentPositionChanged)
                : undefined;
        message.seeked = (object.seeked !== undefined && object.seeked !== null)
            ? PlayerOutput_Seeked.fromPartial(object.seeked)
            : undefined;
        message.playbackStateChanged = (object.playbackStateChanged !== undefined && object.playbackStateChanged !== null)
            ? PlayerOutput_PlaybackStateChanged.fromPartial(object.playbackStateChanged)
            : undefined;
        message.playerStateChanged = (object.playerStateChanged !== undefined && object.playerStateChanged !== null)
            ? PlayerOutput_PlayerStateChanged.fromPartial(object.playerStateChanged)
            : undefined;
        message.availableAudioTracksChanged =
            (object.availableAudioTracksChanged !== undefined && object.availableAudioTracksChanged !== null)
                ? PlayerOutput_AvailableAudioTracksChanged.fromPartial(object.availableAudioTracksChanged)
                : undefined;
        message.selectedAudioTrackChanged =
            (object.selectedAudioTrackChanged !== undefined && object.selectedAudioTrackChanged !== null)
                ? PlayerOutput_SelectedAudioTrackChanged.fromPartial(object.selectedAudioTrackChanged)
                : undefined;
        message.availableSubtitlesTrackChanged =
            (object.availableSubtitlesTrackChanged !== undefined && object.availableSubtitlesTrackChanged !== null)
                ? PlayerOutput_AvailableSubtitlesTrackChanged.fromPartial(object.availableSubtitlesTrackChanged)
                : undefined;
        message.selectedSubtitlesTrackChanged =
            (object.selectedSubtitlesTrackChanged !== undefined && object.selectedSubtitlesTrackChanged !== null)
                ? PlayerOutput_SelectedSubtitlesTrackChanged.fromPartial(object.selectedSubtitlesTrackChanged)
                : undefined;
        message.qualityChanged = (object.qualityChanged !== undefined && object.qualityChanged !== null)
            ? PlayerOutput_QualityChanged.fromPartial(object.qualityChanged)
            : undefined;
        message.playerError = (object.playerError !== undefined && object.playerError !== null)
            ? PlayerOutput_PlayerError.fromPartial(object.playerError)
            : undefined;
        return message;
    },
};
function createBaseOutput_ActionResult() {
    return {
        id: "",
        success: false,
        error: undefined,
        mediaChanged: undefined,
        availableAudioTracks: undefined,
        selectedAudioTrack: undefined,
        availableSubtitlesTracks: undefined,
        selectedSubtitlesTrack: undefined,
        properties: undefined,
    };
}
export const Output_ActionResult = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.success !== false) {
            writer.uint32(16).bool(message.success);
        }
        if (message.error !== undefined) {
            writer.uint32(26).string(message.error);
        }
        if (message.mediaChanged !== undefined) {
            PlayerOutput_MediaChanged.encode(message.mediaChanged, writer.uint32(34).fork()).ldelim();
        }
        if (message.availableAudioTracks !== undefined) {
            PlayerOutput_AvailableAudioTracks.encode(message.availableAudioTracks, writer.uint32(74).fork()).ldelim();
        }
        if (message.selectedAudioTrack !== undefined) {
            PlayerOutput_SelectedAudioTrack.encode(message.selectedAudioTrack, writer.uint32(90).fork()).ldelim();
        }
        if (message.availableSubtitlesTracks !== undefined) {
            PlayerOutput_AvailableSubtitlesTracks.encode(message.availableSubtitlesTracks, writer.uint32(82).fork()).ldelim();
        }
        if (message.selectedSubtitlesTrack !== undefined) {
            PlayerOutput_SelectedSubtitlesTrack.encode(message.selectedSubtitlesTrack, writer.uint32(98).fork()).ldelim();
        }
        if (message.properties !== undefined) {
            PlayerOutput_Properties.encode(message.properties, writer.uint32(106).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOutput_ActionResult();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.id = reader.string();
                    continue;
                case 2:
                    if (tag !== 16) {
                        break;
                    }
                    message.success = reader.bool();
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }
                    message.error = reader.string();
                    continue;
                case 4:
                    if (tag !== 34) {
                        break;
                    }
                    message.mediaChanged = PlayerOutput_MediaChanged.decode(reader, reader.uint32());
                    continue;
                case 9:
                    if (tag !== 74) {
                        break;
                    }
                    message.availableAudioTracks = PlayerOutput_AvailableAudioTracks.decode(reader, reader.uint32());
                    continue;
                case 11:
                    if (tag !== 90) {
                        break;
                    }
                    message.selectedAudioTrack = PlayerOutput_SelectedAudioTrack.decode(reader, reader.uint32());
                    continue;
                case 10:
                    if (tag !== 82) {
                        break;
                    }
                    message.availableSubtitlesTracks = PlayerOutput_AvailableSubtitlesTracks.decode(reader, reader.uint32());
                    continue;
                case 12:
                    if (tag !== 98) {
                        break;
                    }
                    message.selectedSubtitlesTrack = PlayerOutput_SelectedSubtitlesTrack.decode(reader, reader.uint32());
                    continue;
                case 13:
                    if (tag !== 106) {
                        break;
                    }
                    message.properties = PlayerOutput_Properties.decode(reader, reader.uint32());
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? globalThis.String(object.id) : "",
            success: isSet(object.success) ? globalThis.Boolean(object.success) : false,
            error: isSet(object.error) ? globalThis.String(object.error) : undefined,
            mediaChanged: isSet(object.mediaChanged) ? PlayerOutput_MediaChanged.fromJSON(object.mediaChanged) : undefined,
            availableAudioTracks: isSet(object.availableAudioTracks)
                ? PlayerOutput_AvailableAudioTracks.fromJSON(object.availableAudioTracks)
                : undefined,
            selectedAudioTrack: isSet(object.selectedAudioTrack)
                ? PlayerOutput_SelectedAudioTrack.fromJSON(object.selectedAudioTrack)
                : undefined,
            availableSubtitlesTracks: isSet(object.availableSubtitlesTracks)
                ? PlayerOutput_AvailableSubtitlesTracks.fromJSON(object.availableSubtitlesTracks)
                : undefined,
            selectedSubtitlesTrack: isSet(object.selectedSubtitlesTrack)
                ? PlayerOutput_SelectedSubtitlesTrack.fromJSON(object.selectedSubtitlesTrack)
                : undefined,
            properties: isSet(object.properties) ? PlayerOutput_Properties.fromJSON(object.properties) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.id !== "") {
            obj.id = message.id;
        }
        if (message.success !== false) {
            obj.success = message.success;
        }
        if (message.error !== undefined) {
            obj.error = message.error;
        }
        if (message.mediaChanged !== undefined) {
            obj.mediaChanged = PlayerOutput_MediaChanged.toJSON(message.mediaChanged);
        }
        if (message.availableAudioTracks !== undefined) {
            obj.availableAudioTracks = PlayerOutput_AvailableAudioTracks.toJSON(message.availableAudioTracks);
        }
        if (message.selectedAudioTrack !== undefined) {
            obj.selectedAudioTrack = PlayerOutput_SelectedAudioTrack.toJSON(message.selectedAudioTrack);
        }
        if (message.availableSubtitlesTracks !== undefined) {
            obj.availableSubtitlesTracks = PlayerOutput_AvailableSubtitlesTracks.toJSON(message.availableSubtitlesTracks);
        }
        if (message.selectedSubtitlesTrack !== undefined) {
            obj.selectedSubtitlesTrack = PlayerOutput_SelectedSubtitlesTrack.toJSON(message.selectedSubtitlesTrack);
        }
        if (message.properties !== undefined) {
            obj.properties = PlayerOutput_Properties.toJSON(message.properties);
        }
        return obj;
    },
    create(base) {
        return Output_ActionResult.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseOutput_ActionResult();
        message.id = object.id ?? "";
        message.success = object.success ?? false;
        message.error = object.error ?? undefined;
        message.mediaChanged = (object.mediaChanged !== undefined && object.mediaChanged !== null)
            ? PlayerOutput_MediaChanged.fromPartial(object.mediaChanged)
            : undefined;
        message.availableAudioTracks = (object.availableAudioTracks !== undefined && object.availableAudioTracks !== null)
            ? PlayerOutput_AvailableAudioTracks.fromPartial(object.availableAudioTracks)
            : undefined;
        message.selectedAudioTrack = (object.selectedAudioTrack !== undefined && object.selectedAudioTrack !== null)
            ? PlayerOutput_SelectedAudioTrack.fromPartial(object.selectedAudioTrack)
            : undefined;
        message.availableSubtitlesTracks =
            (object.availableSubtitlesTracks !== undefined && object.availableSubtitlesTracks !== null)
                ? PlayerOutput_AvailableSubtitlesTracks.fromPartial(object.availableSubtitlesTracks)
                : undefined;
        message.selectedSubtitlesTrack =
            (object.selectedSubtitlesTrack !== undefined && object.selectedSubtitlesTrack !== null)
                ? PlayerOutput_SelectedSubtitlesTrack.fromPartial(object.selectedSubtitlesTrack)
                : undefined;
        message.properties = (object.properties !== undefined && object.properties !== null)
            ? PlayerOutput_Properties.fromPartial(object.properties)
            : undefined;
        return message;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
