import { PlayerWithPlay } from '../stream/play';
import { EventKey, PlaybackState, } from './enums';
import { isPlaybackError, PlaybackError, } from '../errors';
import { ErrorCode } from '../errors/enums/error-code';
import { WithProperty } from './modules/property';
import { WithControl } from './modules/control';
import { WithTrack } from './modules/track';
import { WithEvent } from './modules/event';
import { WithTelemetry } from './modules/telemetry';
import { WithDebug } from './modules/debug';
import { WithUtil } from './modules/util';
import { WithIma } from './modules/ima';
export class BasePlayer {
    _canPause = false;
    _canSeekBackward = false;
    _canSeekForward = false;
    _capabilities;
    _currentPosition = null;
    _duration = null;
    _logger;
    _playbackState = PlaybackState.STOPPED;
    _seekableRange = null;
    _mediaItem = null;
    _volume = 1;
    _playerOptions;
    _psid = null;
    _geometry = null;
    _bandwidth = null;
    _bitrate = null;
    _mediaType = null;
    _sessions = [];
    _playOptions = null;
    adapter;
    triggerTelemetryEvent(payload) {
        payload;
        /* noop */
    }
    dispatchCustomTelemetryEvent(event) {
        event;
        /* noop */
    }
    triggerTelemetryNewSessionEvent(psid, assetType, assetId) {
        psid;
        assetType;
        assetId;
        /* noop */
    }
    triggerFullEvent(event) {
        event;
        /* noop */
    }
    triggerEvent(event) {
        event;
        /* noop */
    }
    setPlayerState(newState) {
        newState;
        /* noop */
    }
    seekPosition = null;
    constructor(playerOptions, adapter, capabilities) {
        this.adapter = adapter;
        this._playerOptions = playerOptions;
        this._playerOptions.stepBackwardDuration ??= 30;
        this._playerOptions.stepForwardDuration ??= 60;
        this._capabilities = capabilities;
        this._logger = playerOptions.logging?.playerLogger;
    }
    hookMediaItem(media) {
        return media;
    }
    disableTelemetry(psid) {
        psid;
        /* noop */
    }
    dispatchMedia(media, noEvent) {
        this._mediaItem = media;
        const extendedMedia = this.hookMediaItem(media);
        this._mediaItem = extendedMedia;
        if (!noEvent) {
            this.triggerEvent({
                type: EventKey.MEDIA_CHANGED,
                media: extendedMedia,
            });
        }
        return Promise.resolve(extendedMedia);
    }
    destroy() {
        return this.adapter.destroy();
    }
    get psid() {
        return this._psid;
    }
    set psid(newPsid) {
        this._psid = newPsid;
    }
    get bandwidth() {
        return this._bandwidth;
    }
    startNewSession() {
        const sessionAbortController = new AbortController();
        this._sessions.forEach((session) => {
            if (!session.signal.aborted) {
                session.abort();
            }
        });
        this._sessions = [sessionAbortController];
        return sessionAbortController;
    }
    handleError(error, psid) {
        const mappedError = this.mapError(error);
        this.triggerEvent({
            type: EventKey.PLAYER_ERROR,
            error: mappedError,
            psid,
        });
    }
    mapError(error) {
        if (isPlaybackError(error)) {
            return error;
        }
        return new PlaybackError(ErrorCode.Unknown, String(error));
    }
}
const PlayerWithModules = WithIma(WithDebug(WithUtil(WithTelemetry(WithEvent(WithProperty(WithTrack(WithControl(BasePlayer))))))));
export const Player = PlayerWithPlay(PlayerWithModules);
