// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.2
//   protoc               v5.29.3
// source: taf_action.proto
/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
export const protobufPackage = "taf";
export var TafAction_ConfigureZapiMockedProxy_TimeshiftMode;
(function (TafAction_ConfigureZapiMockedProxy_TimeshiftMode) {
    TafAction_ConfigureZapiMockedProxy_TimeshiftMode[TafAction_ConfigureZapiMockedProxy_TimeshiftMode["AVAILABLE"] = 0] = "AVAILABLE";
    TafAction_ConfigureZapiMockedProxy_TimeshiftMode[TafAction_ConfigureZapiMockedProxy_TimeshiftMode["UNAVAILABLE"] = 1] = "UNAVAILABLE";
    TafAction_ConfigureZapiMockedProxy_TimeshiftMode[TafAction_ConfigureZapiMockedProxy_TimeshiftMode["ELIGIBLE"] = 2] = "ELIGIBLE";
    TafAction_ConfigureZapiMockedProxy_TimeshiftMode[TafAction_ConfigureZapiMockedProxy_TimeshiftMode["SUBSCRIBABLE"] = 3] = "SUBSCRIBABLE";
    TafAction_ConfigureZapiMockedProxy_TimeshiftMode[TafAction_ConfigureZapiMockedProxy_TimeshiftMode["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(TafAction_ConfigureZapiMockedProxy_TimeshiftMode || (TafAction_ConfigureZapiMockedProxy_TimeshiftMode = {}));
export function tafAction_ConfigureZapiMockedProxy_TimeshiftModeFromJSON(object) {
    switch (object) {
        case 0:
        case "AVAILABLE":
            return TafAction_ConfigureZapiMockedProxy_TimeshiftMode.AVAILABLE;
        case 1:
        case "UNAVAILABLE":
            return TafAction_ConfigureZapiMockedProxy_TimeshiftMode.UNAVAILABLE;
        case 2:
        case "ELIGIBLE":
            return TafAction_ConfigureZapiMockedProxy_TimeshiftMode.ELIGIBLE;
        case 3:
        case "SUBSCRIBABLE":
            return TafAction_ConfigureZapiMockedProxy_TimeshiftMode.SUBSCRIBABLE;
        case -1:
        case "UNRECOGNIZED":
        default:
            return TafAction_ConfigureZapiMockedProxy_TimeshiftMode.UNRECOGNIZED;
    }
}
export function tafAction_ConfigureZapiMockedProxy_TimeshiftModeToJSON(object) {
    switch (object) {
        case TafAction_ConfigureZapiMockedProxy_TimeshiftMode.AVAILABLE:
            return "AVAILABLE";
        case TafAction_ConfigureZapiMockedProxy_TimeshiftMode.UNAVAILABLE:
            return "UNAVAILABLE";
        case TafAction_ConfigureZapiMockedProxy_TimeshiftMode.ELIGIBLE:
            return "ELIGIBLE";
        case TafAction_ConfigureZapiMockedProxy_TimeshiftMode.SUBSCRIBABLE:
            return "SUBSCRIBABLE";
        case TafAction_ConfigureZapiMockedProxy_TimeshiftMode.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var TafAction_ConfigureStreamingHubProxy_License_Valid_Scope;
(function (TafAction_ConfigureStreamingHubProxy_License_Valid_Scope) {
    TafAction_ConfigureStreamingHubProxy_License_Valid_Scope[TafAction_ConfigureStreamingHubProxy_License_Valid_Scope["PLAYBACK_SDK"] = 0] = "PLAYBACK_SDK";
    TafAction_ConfigureStreamingHubProxy_License_Valid_Scope[TafAction_ConfigureStreamingHubProxy_License_Valid_Scope["TELEMETRY"] = 1] = "TELEMETRY";
    TafAction_ConfigureStreamingHubProxy_License_Valid_Scope[TafAction_ConfigureStreamingHubProxy_License_Valid_Scope["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(TafAction_ConfigureStreamingHubProxy_License_Valid_Scope || (TafAction_ConfigureStreamingHubProxy_License_Valid_Scope = {}));
export function tafAction_ConfigureStreamingHubProxy_License_Valid_ScopeFromJSON(object) {
    switch (object) {
        case 0:
        case "PLAYBACK_SDK":
            return TafAction_ConfigureStreamingHubProxy_License_Valid_Scope.PLAYBACK_SDK;
        case 1:
        case "TELEMETRY":
            return TafAction_ConfigureStreamingHubProxy_License_Valid_Scope.TELEMETRY;
        case -1:
        case "UNRECOGNIZED":
        default:
            return TafAction_ConfigureStreamingHubProxy_License_Valid_Scope.UNRECOGNIZED;
    }
}
export function tafAction_ConfigureStreamingHubProxy_License_Valid_ScopeToJSON(object) {
    switch (object) {
        case TafAction_ConfigureStreamingHubProxy_License_Valid_Scope.PLAYBACK_SDK:
            return "PLAYBACK_SDK";
        case TafAction_ConfigureStreamingHubProxy_License_Valid_Scope.TELEMETRY:
            return "TELEMETRY";
        case TafAction_ConfigureStreamingHubProxy_License_Valid_Scope.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBaseTafAction() {
    return {};
}
export const TafAction = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTafAction();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return TafAction.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseTafAction();
        return message;
    },
};
function createBaseTafAction_Sleep() {
    return { ms: 0 };
}
export const TafAction_Sleep = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.ms !== 0) {
            writer.uint32(8).uint32(message.ms);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTafAction_Sleep();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 8) {
                        break;
                    }
                    message.ms = reader.uint32();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return { ms: isSet(object.ms) ? globalThis.Number(object.ms) : 0 };
    },
    toJSON(message) {
        const obj = {};
        if (message.ms !== 0) {
            obj.ms = Math.round(message.ms);
        }
        return obj;
    },
    create(base) {
        return TafAction_Sleep.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTafAction_Sleep();
        message.ms = object.ms ?? 0;
        return message;
    },
};
function createBaseTafAction_SetupZapiProxy() {
    return { host: "" };
}
export const TafAction_SetupZapiProxy = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.host !== "") {
            writer.uint32(10).string(message.host);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTafAction_SetupZapiProxy();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.host = reader.string();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return { host: isSet(object.host) ? globalThis.String(object.host) : "" };
    },
    toJSON(message) {
        const obj = {};
        if (message.host !== "") {
            obj.host = message.host;
        }
        return obj;
    },
    create(base) {
        return TafAction_SetupZapiProxy.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTafAction_SetupZapiProxy();
        message.host = object.host ?? "";
        return message;
    },
};
function createBaseTafAction_SetupZapiMockedProxy() {
    return { streamapiConfig: undefined };
}
export const TafAction_SetupZapiMockedProxy = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.streamapiConfig !== undefined) {
            TafAction_SetupZapiMockedProxy_Config.encode(message.streamapiConfig, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTafAction_SetupZapiMockedProxy();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.streamapiConfig = TafAction_SetupZapiMockedProxy_Config.decode(reader, reader.uint32());
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            streamapiConfig: isSet(object.streamapiConfig)
                ? TafAction_SetupZapiMockedProxy_Config.fromJSON(object.streamapiConfig)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.streamapiConfig !== undefined) {
            obj.streamapiConfig = TafAction_SetupZapiMockedProxy_Config.toJSON(message.streamapiConfig);
        }
        return obj;
    },
    create(base) {
        return TafAction_SetupZapiMockedProxy.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTafAction_SetupZapiMockedProxy();
        message.streamapiConfig = (object.streamapiConfig !== undefined && object.streamapiConfig !== null)
            ? TafAction_SetupZapiMockedProxy_Config.fromPartial(object.streamapiConfig)
            : undefined;
        return message;
    },
};
function createBaseTafAction_SetupZapiMockedProxy_Config() {
    return { url: "", partnerId: "", secret: "" };
}
export const TafAction_SetupZapiMockedProxy_Config = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.url !== "") {
            writer.uint32(10).string(message.url);
        }
        if (message.partnerId !== "") {
            writer.uint32(18).string(message.partnerId);
        }
        if (message.secret !== "") {
            writer.uint32(26).string(message.secret);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTafAction_SetupZapiMockedProxy_Config();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.url = reader.string();
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.partnerId = reader.string();
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }
                    message.secret = reader.string();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            url: isSet(object.url) ? globalThis.String(object.url) : "",
            partnerId: isSet(object.partnerId) ? globalThis.String(object.partnerId) : "",
            secret: isSet(object.secret) ? globalThis.String(object.secret) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.url !== "") {
            obj.url = message.url;
        }
        if (message.partnerId !== "") {
            obj.partnerId = message.partnerId;
        }
        if (message.secret !== "") {
            obj.secret = message.secret;
        }
        return obj;
    },
    create(base) {
        return TafAction_SetupZapiMockedProxy_Config.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTafAction_SetupZapiMockedProxy_Config();
        message.url = object.url ?? "";
        message.partnerId = object.partnerId ?? "";
        message.secret = object.secret ?? "";
        return message;
    },
};
function createBaseTafAction_ConfigureZapiMockedProxy() {
    return { liveConfigs: [], recordingConfigs: [], replayConfigs: [] };
}
export const TafAction_ConfigureZapiMockedProxy = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.liveConfigs) {
            TafAction_ConfigureZapiMockedProxy_LiveConfig.encode(v, writer.uint32(10).fork()).ldelim();
        }
        for (const v of message.recordingConfigs) {
            TafAction_ConfigureZapiMockedProxy_RecordingConfig.encode(v, writer.uint32(18).fork()).ldelim();
        }
        for (const v of message.replayConfigs) {
            TafAction_ConfigureZapiMockedProxy_ReplayConfig.encode(v, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTafAction_ConfigureZapiMockedProxy();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.liveConfigs.push(TafAction_ConfigureZapiMockedProxy_LiveConfig.decode(reader, reader.uint32()));
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.recordingConfigs.push(TafAction_ConfigureZapiMockedProxy_RecordingConfig.decode(reader, reader.uint32()));
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }
                    message.replayConfigs.push(TafAction_ConfigureZapiMockedProxy_ReplayConfig.decode(reader, reader.uint32()));
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            liveConfigs: globalThis.Array.isArray(object?.liveConfigs)
                ? object.liveConfigs.map((e) => TafAction_ConfigureZapiMockedProxy_LiveConfig.fromJSON(e))
                : [],
            recordingConfigs: globalThis.Array.isArray(object?.recordingConfigs)
                ? object.recordingConfigs.map((e) => TafAction_ConfigureZapiMockedProxy_RecordingConfig.fromJSON(e))
                : [],
            replayConfigs: globalThis.Array.isArray(object?.replayConfigs)
                ? object.replayConfigs.map((e) => TafAction_ConfigureZapiMockedProxy_ReplayConfig.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.liveConfigs?.length) {
            obj.liveConfigs = message.liveConfigs.map((e) => TafAction_ConfigureZapiMockedProxy_LiveConfig.toJSON(e));
        }
        if (message.recordingConfigs?.length) {
            obj.recordingConfigs = message.recordingConfigs.map((e) => TafAction_ConfigureZapiMockedProxy_RecordingConfig.toJSON(e));
        }
        if (message.replayConfigs?.length) {
            obj.replayConfigs = message.replayConfigs.map((e) => TafAction_ConfigureZapiMockedProxy_ReplayConfig.toJSON(e));
        }
        return obj;
    },
    create(base) {
        return TafAction_ConfigureZapiMockedProxy.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTafAction_ConfigureZapiMockedProxy();
        message.liveConfigs =
            object.liveConfigs?.map((e) => TafAction_ConfigureZapiMockedProxy_LiveConfig.fromPartial(e)) || [];
        message.recordingConfigs =
            object.recordingConfigs?.map((e) => TafAction_ConfigureZapiMockedProxy_RecordingConfig.fromPartial(e)) || [];
        message.replayConfigs =
            object.replayConfigs?.map((e) => TafAction_ConfigureZapiMockedProxy_ReplayConfig.fromPartial(e)) || [];
        return message;
    },
};
function createBaseTafAction_ConfigureZapiMockedProxy_Padding() {
    return { pre: 0, post: 0 };
}
export const TafAction_ConfigureZapiMockedProxy_Padding = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.pre !== 0) {
            writer.uint32(8).uint32(message.pre);
        }
        if (message.post !== 0) {
            writer.uint32(16).uint32(message.post);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTafAction_ConfigureZapiMockedProxy_Padding();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 8) {
                        break;
                    }
                    message.pre = reader.uint32();
                    continue;
                case 2:
                    if (tag !== 16) {
                        break;
                    }
                    message.post = reader.uint32();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            pre: isSet(object.pre) ? globalThis.Number(object.pre) : 0,
            post: isSet(object.post) ? globalThis.Number(object.post) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.pre !== 0) {
            obj.pre = Math.round(message.pre);
        }
        if (message.post !== 0) {
            obj.post = Math.round(message.post);
        }
        return obj;
    },
    create(base) {
        return TafAction_ConfigureZapiMockedProxy_Padding.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTafAction_ConfigureZapiMockedProxy_Padding();
        message.pre = object.pre ?? 0;
        message.post = object.post ?? 0;
        return message;
    },
};
function createBaseTafAction_ConfigureZapiMockedProxy_Destination() {
    return { channel: "", unixStartTime: 0, unixEndTime: 0, dynamicPvr: false, padding: undefined };
}
export const TafAction_ConfigureZapiMockedProxy_Destination = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.channel !== "") {
            writer.uint32(10).string(message.channel);
        }
        if (message.unixStartTime !== 0) {
            writer.uint32(16).int64(message.unixStartTime);
        }
        if (message.unixEndTime !== 0) {
            writer.uint32(24).int64(message.unixEndTime);
        }
        if (message.dynamicPvr !== false) {
            writer.uint32(32).bool(message.dynamicPvr);
        }
        if (message.padding !== undefined) {
            TafAction_ConfigureZapiMockedProxy_Padding.encode(message.padding, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTafAction_ConfigureZapiMockedProxy_Destination();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.channel = reader.string();
                    continue;
                case 2:
                    if (tag !== 16) {
                        break;
                    }
                    message.unixStartTime = longToNumber(reader.int64());
                    continue;
                case 3:
                    if (tag !== 24) {
                        break;
                    }
                    message.unixEndTime = longToNumber(reader.int64());
                    continue;
                case 4:
                    if (tag !== 32) {
                        break;
                    }
                    message.dynamicPvr = reader.bool();
                    continue;
                case 5:
                    if (tag !== 42) {
                        break;
                    }
                    message.padding = TafAction_ConfigureZapiMockedProxy_Padding.decode(reader, reader.uint32());
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            channel: isSet(object.channel) ? globalThis.String(object.channel) : "",
            unixStartTime: isSet(object.unixStartTime) ? globalThis.Number(object.unixStartTime) : 0,
            unixEndTime: isSet(object.unixEndTime) ? globalThis.Number(object.unixEndTime) : 0,
            dynamicPvr: isSet(object.dynamicPvr) ? globalThis.Boolean(object.dynamicPvr) : false,
            padding: isSet(object.padding) ? TafAction_ConfigureZapiMockedProxy_Padding.fromJSON(object.padding) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.channel !== "") {
            obj.channel = message.channel;
        }
        if (message.unixStartTime !== 0) {
            obj.unixStartTime = Math.round(message.unixStartTime);
        }
        if (message.unixEndTime !== 0) {
            obj.unixEndTime = Math.round(message.unixEndTime);
        }
        if (message.dynamicPvr !== false) {
            obj.dynamicPvr = message.dynamicPvr;
        }
        if (message.padding !== undefined) {
            obj.padding = TafAction_ConfigureZapiMockedProxy_Padding.toJSON(message.padding);
        }
        return obj;
    },
    create(base) {
        return TafAction_ConfigureZapiMockedProxy_Destination.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTafAction_ConfigureZapiMockedProxy_Destination();
        message.channel = object.channel ?? "";
        message.unixStartTime = object.unixStartTime ?? 0;
        message.unixEndTime = object.unixEndTime ?? 0;
        message.dynamicPvr = object.dynamicPvr ?? false;
        message.padding = (object.padding !== undefined && object.padding !== null)
            ? TafAction_ConfigureZapiMockedProxy_Padding.fromPartial(object.padding)
            : undefined;
        return message;
    },
};
function createBaseTafAction_ConfigureZapiMockedProxy_LiveConfig() {
    return { source: undefined, destination: undefined };
}
export const TafAction_ConfigureZapiMockedProxy_LiveConfig = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.source !== undefined) {
            TafAction_ConfigureZapiMockedProxy_LiveConfig_Source.encode(message.source, writer.uint32(10).fork()).ldelim();
        }
        if (message.destination !== undefined) {
            TafAction_ConfigureZapiMockedProxy_LiveConfig_Destination.encode(message.destination, writer.uint32(18).fork())
                .ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTafAction_ConfigureZapiMockedProxy_LiveConfig();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.source = TafAction_ConfigureZapiMockedProxy_LiveConfig_Source.decode(reader, reader.uint32());
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.destination = TafAction_ConfigureZapiMockedProxy_LiveConfig_Destination.decode(reader, reader.uint32());
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            source: isSet(object.source)
                ? TafAction_ConfigureZapiMockedProxy_LiveConfig_Source.fromJSON(object.source)
                : undefined,
            destination: isSet(object.destination)
                ? TafAction_ConfigureZapiMockedProxy_LiveConfig_Destination.fromJSON(object.destination)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.source !== undefined) {
            obj.source = TafAction_ConfigureZapiMockedProxy_LiveConfig_Source.toJSON(message.source);
        }
        if (message.destination !== undefined) {
            obj.destination = TafAction_ConfigureZapiMockedProxy_LiveConfig_Destination.toJSON(message.destination);
        }
        return obj;
    },
    create(base) {
        return TafAction_ConfigureZapiMockedProxy_LiveConfig.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTafAction_ConfigureZapiMockedProxy_LiveConfig();
        message.source = (object.source !== undefined && object.source !== null)
            ? TafAction_ConfigureZapiMockedProxy_LiveConfig_Source.fromPartial(object.source)
            : undefined;
        message.destination = (object.destination !== undefined && object.destination !== null)
            ? TafAction_ConfigureZapiMockedProxy_LiveConfig_Destination.fromPartial(object.destination)
            : undefined;
        return message;
    },
};
function createBaseTafAction_ConfigureZapiMockedProxy_LiveConfig_Source() {
    return { cid: "" };
}
export const TafAction_ConfigureZapiMockedProxy_LiveConfig_Source = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.cid !== "") {
            writer.uint32(10).string(message.cid);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTafAction_ConfigureZapiMockedProxy_LiveConfig_Source();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.cid = reader.string();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return { cid: isSet(object.cid) ? globalThis.String(object.cid) : "" };
    },
    toJSON(message) {
        const obj = {};
        if (message.cid !== "") {
            obj.cid = message.cid;
        }
        return obj;
    },
    create(base) {
        return TafAction_ConfigureZapiMockedProxy_LiveConfig_Source.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTafAction_ConfigureZapiMockedProxy_LiveConfig_Source();
        message.cid = object.cid ?? "";
        return message;
    },
};
function createBaseTafAction_ConfigureZapiMockedProxy_LiveConfig_Destination() {
    return { cid: "", timeshift: 0, registerTimeshift: undefined, unregisteredTimeshift: undefined };
}
export const TafAction_ConfigureZapiMockedProxy_LiveConfig_Destination = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.cid !== "") {
            writer.uint32(10).string(message.cid);
        }
        if (message.timeshift !== 0) {
            writer.uint32(16).int32(message.timeshift);
        }
        if (message.registerTimeshift !== undefined) {
            writer.uint32(24).int32(message.registerTimeshift);
        }
        if (message.unregisteredTimeshift !== undefined) {
            writer.uint32(32).int32(message.unregisteredTimeshift);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTafAction_ConfigureZapiMockedProxy_LiveConfig_Destination();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.cid = reader.string();
                    continue;
                case 2:
                    if (tag !== 16) {
                        break;
                    }
                    message.timeshift = reader.int32();
                    continue;
                case 3:
                    if (tag !== 24) {
                        break;
                    }
                    message.registerTimeshift = reader.int32();
                    continue;
                case 4:
                    if (tag !== 32) {
                        break;
                    }
                    message.unregisteredTimeshift = reader.int32();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            cid: isSet(object.cid) ? globalThis.String(object.cid) : "",
            timeshift: isSet(object.timeshift) ? globalThis.Number(object.timeshift) : 0,
            registerTimeshift: isSet(object.registerTimeshift)
                ? tafAction_ConfigureZapiMockedProxy_TimeshiftModeFromJSON(object.registerTimeshift)
                : undefined,
            unregisteredTimeshift: isSet(object.unregisteredTimeshift)
                ? tafAction_ConfigureZapiMockedProxy_TimeshiftModeFromJSON(object.unregisteredTimeshift)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.cid !== "") {
            obj.cid = message.cid;
        }
        if (message.timeshift !== 0) {
            obj.timeshift = Math.round(message.timeshift);
        }
        if (message.registerTimeshift !== undefined) {
            obj.registerTimeshift = tafAction_ConfigureZapiMockedProxy_TimeshiftModeToJSON(message.registerTimeshift);
        }
        if (message.unregisteredTimeshift !== undefined) {
            obj.unregisteredTimeshift = tafAction_ConfigureZapiMockedProxy_TimeshiftModeToJSON(message.unregisteredTimeshift);
        }
        return obj;
    },
    create(base) {
        return TafAction_ConfigureZapiMockedProxy_LiveConfig_Destination.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTafAction_ConfigureZapiMockedProxy_LiveConfig_Destination();
        message.cid = object.cid ?? "";
        message.timeshift = object.timeshift ?? 0;
        message.registerTimeshift = object.registerTimeshift ?? undefined;
        message.unregisteredTimeshift = object.unregisteredTimeshift ?? undefined;
        return message;
    },
};
function createBaseTafAction_ConfigureZapiMockedProxy_RecordingConfig() {
    return { source: undefined, destination: undefined, padding: undefined };
}
export const TafAction_ConfigureZapiMockedProxy_RecordingConfig = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.source !== undefined) {
            TafAction_ConfigureZapiMockedProxy_RecordingConfig_Source.encode(message.source, writer.uint32(10).fork())
                .ldelim();
        }
        if (message.destination !== undefined) {
            TafAction_ConfigureZapiMockedProxy_Destination.encode(message.destination, writer.uint32(18).fork()).ldelim();
        }
        if (message.padding !== undefined) {
            TafAction_ConfigureZapiMockedProxy_Padding.encode(message.padding, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTafAction_ConfigureZapiMockedProxy_RecordingConfig();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.source = TafAction_ConfigureZapiMockedProxy_RecordingConfig_Source.decode(reader, reader.uint32());
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.destination = TafAction_ConfigureZapiMockedProxy_Destination.decode(reader, reader.uint32());
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }
                    message.padding = TafAction_ConfigureZapiMockedProxy_Padding.decode(reader, reader.uint32());
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            source: isSet(object.source)
                ? TafAction_ConfigureZapiMockedProxy_RecordingConfig_Source.fromJSON(object.source)
                : undefined,
            destination: isSet(object.destination)
                ? TafAction_ConfigureZapiMockedProxy_Destination.fromJSON(object.destination)
                : undefined,
            padding: isSet(object.padding) ? TafAction_ConfigureZapiMockedProxy_Padding.fromJSON(object.padding) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.source !== undefined) {
            obj.source = TafAction_ConfigureZapiMockedProxy_RecordingConfig_Source.toJSON(message.source);
        }
        if (message.destination !== undefined) {
            obj.destination = TafAction_ConfigureZapiMockedProxy_Destination.toJSON(message.destination);
        }
        if (message.padding !== undefined) {
            obj.padding = TafAction_ConfigureZapiMockedProxy_Padding.toJSON(message.padding);
        }
        return obj;
    },
    create(base) {
        return TafAction_ConfigureZapiMockedProxy_RecordingConfig.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTafAction_ConfigureZapiMockedProxy_RecordingConfig();
        message.source = (object.source !== undefined && object.source !== null)
            ? TafAction_ConfigureZapiMockedProxy_RecordingConfig_Source.fromPartial(object.source)
            : undefined;
        message.destination = (object.destination !== undefined && object.destination !== null)
            ? TafAction_ConfigureZapiMockedProxy_Destination.fromPartial(object.destination)
            : undefined;
        message.padding = (object.padding !== undefined && object.padding !== null)
            ? TafAction_ConfigureZapiMockedProxy_Padding.fromPartial(object.padding)
            : undefined;
        return message;
    },
};
function createBaseTafAction_ConfigureZapiMockedProxy_RecordingConfig_Source() {
    return { recordingId: 0 };
}
export const TafAction_ConfigureZapiMockedProxy_RecordingConfig_Source = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.recordingId !== 0) {
            writer.uint32(8).uint32(message.recordingId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTafAction_ConfigureZapiMockedProxy_RecordingConfig_Source();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 8) {
                        break;
                    }
                    message.recordingId = reader.uint32();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return { recordingId: isSet(object.recordingId) ? globalThis.Number(object.recordingId) : 0 };
    },
    toJSON(message) {
        const obj = {};
        if (message.recordingId !== 0) {
            obj.recordingId = Math.round(message.recordingId);
        }
        return obj;
    },
    create(base) {
        return TafAction_ConfigureZapiMockedProxy_RecordingConfig_Source.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTafAction_ConfigureZapiMockedProxy_RecordingConfig_Source();
        message.recordingId = object.recordingId ?? 0;
        return message;
    },
};
function createBaseTafAction_ConfigureZapiMockedProxy_ReplayConfig() {
    return { source: undefined, destination: undefined, padding: undefined };
}
export const TafAction_ConfigureZapiMockedProxy_ReplayConfig = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.source !== undefined) {
            TafAction_ConfigureZapiMockedProxy_ReplayConfig_Source.encode(message.source, writer.uint32(10).fork()).ldelim();
        }
        if (message.destination !== undefined) {
            TafAction_ConfigureZapiMockedProxy_Destination.encode(message.destination, writer.uint32(18).fork()).ldelim();
        }
        if (message.padding !== undefined) {
            TafAction_ConfigureZapiMockedProxy_Padding.encode(message.padding, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTafAction_ConfigureZapiMockedProxy_ReplayConfig();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.source = TafAction_ConfigureZapiMockedProxy_ReplayConfig_Source.decode(reader, reader.uint32());
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.destination = TafAction_ConfigureZapiMockedProxy_Destination.decode(reader, reader.uint32());
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }
                    message.padding = TafAction_ConfigureZapiMockedProxy_Padding.decode(reader, reader.uint32());
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            source: isSet(object.source)
                ? TafAction_ConfigureZapiMockedProxy_ReplayConfig_Source.fromJSON(object.source)
                : undefined,
            destination: isSet(object.destination)
                ? TafAction_ConfigureZapiMockedProxy_Destination.fromJSON(object.destination)
                : undefined,
            padding: isSet(object.padding) ? TafAction_ConfigureZapiMockedProxy_Padding.fromJSON(object.padding) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.source !== undefined) {
            obj.source = TafAction_ConfigureZapiMockedProxy_ReplayConfig_Source.toJSON(message.source);
        }
        if (message.destination !== undefined) {
            obj.destination = TafAction_ConfigureZapiMockedProxy_Destination.toJSON(message.destination);
        }
        if (message.padding !== undefined) {
            obj.padding = TafAction_ConfigureZapiMockedProxy_Padding.toJSON(message.padding);
        }
        return obj;
    },
    create(base) {
        return TafAction_ConfigureZapiMockedProxy_ReplayConfig.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTafAction_ConfigureZapiMockedProxy_ReplayConfig();
        message.source = (object.source !== undefined && object.source !== null)
            ? TafAction_ConfigureZapiMockedProxy_ReplayConfig_Source.fromPartial(object.source)
            : undefined;
        message.destination = (object.destination !== undefined && object.destination !== null)
            ? TafAction_ConfigureZapiMockedProxy_Destination.fromPartial(object.destination)
            : undefined;
        message.padding = (object.padding !== undefined && object.padding !== null)
            ? TafAction_ConfigureZapiMockedProxy_Padding.fromPartial(object.padding)
            : undefined;
        return message;
    },
};
function createBaseTafAction_ConfigureZapiMockedProxy_ReplayConfig_Source() {
    return { cid: "", programId: 0 };
}
export const TafAction_ConfigureZapiMockedProxy_ReplayConfig_Source = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.cid !== "") {
            writer.uint32(10).string(message.cid);
        }
        if (message.programId !== 0) {
            writer.uint32(16).uint32(message.programId);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTafAction_ConfigureZapiMockedProxy_ReplayConfig_Source();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.cid = reader.string();
                    continue;
                case 2:
                    if (tag !== 16) {
                        break;
                    }
                    message.programId = reader.uint32();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            cid: isSet(object.cid) ? globalThis.String(object.cid) : "",
            programId: isSet(object.programId) ? globalThis.Number(object.programId) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.cid !== "") {
            obj.cid = message.cid;
        }
        if (message.programId !== 0) {
            obj.programId = Math.round(message.programId);
        }
        return obj;
    },
    create(base) {
        return TafAction_ConfigureZapiMockedProxy_ReplayConfig_Source.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTafAction_ConfigureZapiMockedProxy_ReplayConfig_Source();
        message.cid = object.cid ?? "";
        message.programId = object.programId ?? 0;
        return message;
    },
};
function createBaseTafAction_StartSession() {
    return { name: "" };
}
export const TafAction_StartSession = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.name !== "") {
            writer.uint32(10).string(message.name);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTafAction_StartSession();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.name = reader.string();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
    },
    toJSON(message) {
        const obj = {};
        if (message.name !== "") {
            obj.name = message.name;
        }
        return obj;
    },
    create(base) {
        return TafAction_StartSession.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTafAction_StartSession();
        message.name = object.name ?? "";
        return message;
    },
};
function createBaseTafAction_ConfigureStreamingHubProxy() {
    return { license: undefined };
}
export const TafAction_ConfigureStreamingHubProxy = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.license !== undefined) {
            TafAction_ConfigureStreamingHubProxy_License.encode(message.license, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTafAction_ConfigureStreamingHubProxy();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.license = TafAction_ConfigureStreamingHubProxy_License.decode(reader, reader.uint32());
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            license: isSet(object.license)
                ? TafAction_ConfigureStreamingHubProxy_License.fromJSON(object.license)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.license !== undefined) {
            obj.license = TafAction_ConfigureStreamingHubProxy_License.toJSON(message.license);
        }
        return obj;
    },
    create(base) {
        return TafAction_ConfigureStreamingHubProxy.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTafAction_ConfigureStreamingHubProxy();
        message.license = (object.license !== undefined && object.license !== null)
            ? TafAction_ConfigureStreamingHubProxy_License.fromPartial(object.license)
            : undefined;
        return message;
    },
};
function createBaseTafAction_ConfigureStreamingHubProxy_License() {
    return { valid: undefined, invalid: undefined };
}
export const TafAction_ConfigureStreamingHubProxy_License = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.valid !== undefined) {
            TafAction_ConfigureStreamingHubProxy_License_Valid.encode(message.valid, writer.uint32(10).fork()).ldelim();
        }
        if (message.invalid !== undefined) {
            TafAction_ConfigureStreamingHubProxy_License_Invalid.encode(message.invalid, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTafAction_ConfigureStreamingHubProxy_License();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.valid = TafAction_ConfigureStreamingHubProxy_License_Valid.decode(reader, reader.uint32());
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.invalid = TafAction_ConfigureStreamingHubProxy_License_Invalid.decode(reader, reader.uint32());
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            valid: isSet(object.valid)
                ? TafAction_ConfigureStreamingHubProxy_License_Valid.fromJSON(object.valid)
                : undefined,
            invalid: isSet(object.invalid)
                ? TafAction_ConfigureStreamingHubProxy_License_Invalid.fromJSON(object.invalid)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.valid !== undefined) {
            obj.valid = TafAction_ConfigureStreamingHubProxy_License_Valid.toJSON(message.valid);
        }
        if (message.invalid !== undefined) {
            obj.invalid = TafAction_ConfigureStreamingHubProxy_License_Invalid.toJSON(message.invalid);
        }
        return obj;
    },
    create(base) {
        return TafAction_ConfigureStreamingHubProxy_License.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTafAction_ConfigureStreamingHubProxy_License();
        message.valid = (object.valid !== undefined && object.valid !== null)
            ? TafAction_ConfigureStreamingHubProxy_License_Valid.fromPartial(object.valid)
            : undefined;
        message.invalid = (object.invalid !== undefined && object.invalid !== null)
            ? TafAction_ConfigureStreamingHubProxy_License_Invalid.fromPartial(object.invalid)
            : undefined;
        return message;
    },
};
function createBaseTafAction_ConfigureStreamingHubProxy_License_Valid() {
    return { scope: [] };
}
export const TafAction_ConfigureStreamingHubProxy_License_Valid = {
    encode(message, writer = _m0.Writer.create()) {
        writer.uint32(10).fork();
        for (const v of message.scope) {
            writer.int32(v);
        }
        writer.ldelim();
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTafAction_ConfigureStreamingHubProxy_License_Valid();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag === 8) {
                        message.scope.push(reader.int32());
                        continue;
                    }
                    if (tag === 10) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.scope.push(reader.int32());
                        }
                        continue;
                    }
                    break;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            scope: globalThis.Array.isArray(object?.scope)
                ? object.scope.map((e) => tafAction_ConfigureStreamingHubProxy_License_Valid_ScopeFromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.scope?.length) {
            obj.scope = message.scope.map((e) => tafAction_ConfigureStreamingHubProxy_License_Valid_ScopeToJSON(e));
        }
        return obj;
    },
    create(base) {
        return TafAction_ConfigureStreamingHubProxy_License_Valid.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTafAction_ConfigureStreamingHubProxy_License_Valid();
        message.scope = object.scope?.map((e) => e) || [];
        return message;
    },
};
function createBaseTafAction_ConfigureStreamingHubProxy_License_Invalid() {
    return {};
}
export const TafAction_ConfigureStreamingHubProxy_License_Invalid = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTafAction_ConfigureStreamingHubProxy_License_Invalid();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return TafAction_ConfigureStreamingHubProxy_License_Invalid.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseTafAction_ConfigureStreamingHubProxy_License_Invalid();
        return message;
    },
};
function createBaseTafAction_CleanupSession() {
    return {};
}
export const TafAction_CleanupSession = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTafAction_CleanupSession();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return TafAction_CleanupSession.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseTafAction_CleanupSession();
        return message;
    },
};
function longToNumber(long) {
    if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
        throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
    }
    if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
        throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
    }
    return long.toNumber();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
