export const ErrorCode = {
    Network: 10000,
    NotImplemented: 10001,
    Initialization: 10002,
    AudioTrack: 10003,
    SubtitlesTrack: 10004,
    Unload: 10005,
    Source: 10006,
    ForbiddenAction: 10007,
    Ad: 10009,
    MissingPsid: 10010,
    SessionAbort: 10011,
    Unknown: 10999,
    /**
     * Used to mark internal overloads
     */
    Internal: 50000,
};
