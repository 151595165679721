import { PlayerError as BitmovinPlayerError, } from 'bitmovin-player/modules/bitmovinplayer-core';
import { PlaybackError } from '../../errors';
import { ErrorCode } from '../../errors/enums/error-code';
import { StreamRepresentation } from '../enums/stream-representations';
import { codecToAudioFormat } from '../../tracks/utils';
export const mapAudioTrack = (track) => {
    // getQualities is not available for native HLS player in Safari
    const qualities = track.getQualities?.() ?? null;
    const codec = qualities?.[0]?.codec;
    const audioFormat = codecToAudioFormat(codec);
    const result = {
        id: track.id,
        locale: track.lang,
        codec,
    };
    if (audioFormat) {
        result.audioFormat = audioFormat;
    }
    return result;
};
export const mapTextTrack = (track) => {
    return {
        id: track.id,
        locale: track.lang,
    };
};
export const isBitmovinError = (error) => {
    return error instanceof BitmovinPlayerError || (
    // @ts-expect-error undefined type
    error?.type === 'error' &&
        // @ts-expect-error undefined type
        error?.name);
};
export const mapBitmovinError = (error) => {
    let message = `${error.message} | BitmovinName: ${error.name} | BitmovinCode: ${error.code}`;
    if (error.data) {
        message += ` | Data: ${JSON.stringify(error.data)}`;
    }
    if (error.sourceIdentifier) {
        message += ` | ${error.sourceIdentifier}`;
    }
    const playbackError = new PlaybackError(ErrorCode.Unknown, message);
    playbackError.stack = error.stack;
    return playbackError;
};
export const calculateAbsoluteLiveEdge = (currentTime, offsetToLiveEdge) => {
    return currentTime - offsetToLiveEdge;
};
export const calculateAbsoluteTimeshift = (currentTime, offsetToLiveEdge, // negative number
maxTimeshift) => {
    return calculateAbsoluteLiveEdge(currentTime, offsetToLiveEdge) + maxTimeshift;
};
export const getAvailableRepresentations = (videoQualities = [], audioTracks = [], subtitleTracks = []) => {
    const availableVideo = videoQualities.map((videoQuality) => {
        return {
            type: StreamRepresentation.VIDEO,
            bitrate: videoQuality.bitrate,
            width: videoQuality.width,
            height: videoQuality.height,
            codec: videoQuality.codec,
            fps: videoQuality.frameRate,
        };
    });
    const availableAudio = audioTracks.reduce((acc, audioTrack) => {
        const qualities = audioTrack?.getQualities?.();
        if (!qualities) {
            acc.push({
                type: StreamRepresentation.AUDIO,
                language: audioTrack?.lang,
                bitrate: null,
            });
            return acc;
        }
        qualities.forEach((quality) => {
            acc.push({
                type: StreamRepresentation.AUDIO,
                language: audioTrack.lang,
                bitrate: quality.bitrate,
                codec: quality.codec,
            });
        });
        return acc;
    }, []);
    const availableText = subtitleTracks.map((textTrack) => {
        return {
            type: StreamRepresentation.TEXT,
            language: textTrack.lang,
        };
    });
    return [
        ...availableVideo,
        ...availableAudio,
        ...availableText,
    ];
};
export const debounce = (func, delay) => {
    let timeout;
    return () => {
        clearTimeout(timeout);
        timeout = setTimeout(func, delay);
    };
};
