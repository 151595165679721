export var EventKey;
(function (EventKey) {
    /**
     * Fired every time a new stream is loaded in the player
     */
    EventKey["MEDIA_CHANGED"] = "MEDIA_CHANGED";
    /**
     * Fired every time the player changes state, like for example when
     * canSeekForward or canSeekBackward property changes.
     */
    EventKey["PLAYER_STATE_CHANGED"] = "PLAYER_STATE_CHANGED";
    /**
     * Fired every time the player changes state, like for example
     * when the player goes from BUFFERING into PLAYING.
     */
    EventKey["PLAYBACK_STATE_CHANGED"] = "PLAYBACK_STATE_CHANGED";
    /**
     * Fired once the current stream has ended
     */
    EventKey["MEDIA_ENDED"] = "MEDIA_ENDED";
    /**
     * Fired once the player is ready to start the stream
     */
    EventKey["PLAYER_READY"] = "PLAYER_READY";
    /**
     * Fired when the underlying player reports an error
     */
    EventKey["PLAYER_ERROR"] = "PLAYER_ERROR";
    /**
     * Fired periodically to report the new position of the in-progress stream
     */
    EventKey["CURRENT_POSITION_CHANGED"] = "CURRENT_POSITION_CHANGED";
    /**
     * Fires every time the current position for the in progress Media has been saved in the backend.
     * Depending on the media being played, a specific media type like MediaRecording will be included.
     */
    EventKey["CURRENT_POSITION_SAVED"] = "CURRENT_POSITION_SAVED";
    /**
     * Fired when the underlying player reports changes in the tracks (audio, subtitles).
     * This event is triggered when the audio and subtitles media tracks of the current item have been loaded and
     * are available using the properties availableAudioTracks and availableSubtitlesTracks.
     */
    EventKey["AVAILABLE_SUBTITLES_TRACKS_CHANGED"] = "AVAILABLE_SUBTITLES_TRACKS_CHANGED";
    /**
     * Fired when the underlying player reports changes in the tracks (audio, subtitles).
     * This event is triggered when the audio and subtitles media tracks of the current item have been loaded and
     * are available using the properties availableAudioTracks and availableSubtitlesTracks.
     */
    EventKey["AVAILABLE_AUDIO_TRACKS_CHANGED"] = "AVAILABLE_AUDIO_TRACKS_CHANGED";
    /**
     * Fired when the underlying player reports changes in the selected audio track and the new track
     * is available using the property selectedAudioTrack.
     */
    EventKey["SELECTED_AUDIO_TRACK_CHANGED"] = "SELECTED_AUDIO_TRACK_CHANGED";
    /**
     * Fired when the underlying player reports changes in the selected subtitle track and the new track
     * is available using the property selectedSubtitlesTrack.
     */
    EventKey["SELECTED_SUBTITLES_TRACK_CHANGED"] = "SELECTED_SUBTITLES_TRACK_CHANGED";
    /**
     * Fired when the player's volume has been changed
     */
    EventKey["VOLUME_CHANGED"] = "VOLUME_CHANGED";
    /**
     * Fired when the player has seeked
     */
    EventKey["SEEKED"] = "SEEKED";
    /**
     * Fired when the player switches to a different video bitrate
     */
    EventKey["QUALITY_CHANGED"] = "QUALITY_CHANGED";
    /**
     * Fired every time there is an Ads related event
     */
    EventKey["AD_EVENT"] = "AD_EVENT";
})(EventKey || (EventKey = {}));
