// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.2
//   protoc               v5.29.3
// source: player_output.proto
/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Player_MediaTrack, player_MediaTypeFromJSON, player_MediaTypeToJSON } from "./player";
export const protobufPackage = "taf";
export var PlayerOutput_TimeshiftAvailability;
(function (PlayerOutput_TimeshiftAvailability) {
    PlayerOutput_TimeshiftAvailability[PlayerOutput_TimeshiftAvailability["AVAILABLE"] = 0] = "AVAILABLE";
    PlayerOutput_TimeshiftAvailability[PlayerOutput_TimeshiftAvailability["UNAVAILABLE"] = 1] = "UNAVAILABLE";
    PlayerOutput_TimeshiftAvailability[PlayerOutput_TimeshiftAvailability["ELIGIBLE"] = 2] = "ELIGIBLE";
    PlayerOutput_TimeshiftAvailability[PlayerOutput_TimeshiftAvailability["SUBSCRIBABLE"] = 3] = "SUBSCRIBABLE";
    PlayerOutput_TimeshiftAvailability[PlayerOutput_TimeshiftAvailability["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(PlayerOutput_TimeshiftAvailability || (PlayerOutput_TimeshiftAvailability = {}));
export function playerOutput_TimeshiftAvailabilityFromJSON(object) {
    switch (object) {
        case 0:
        case "AVAILABLE":
            return PlayerOutput_TimeshiftAvailability.AVAILABLE;
        case 1:
        case "UNAVAILABLE":
            return PlayerOutput_TimeshiftAvailability.UNAVAILABLE;
        case 2:
        case "ELIGIBLE":
            return PlayerOutput_TimeshiftAvailability.ELIGIBLE;
        case 3:
        case "SUBSCRIBABLE":
            return PlayerOutput_TimeshiftAvailability.SUBSCRIBABLE;
        case -1:
        case "UNRECOGNIZED":
        default:
            return PlayerOutput_TimeshiftAvailability.UNRECOGNIZED;
    }
}
export function playerOutput_TimeshiftAvailabilityToJSON(object) {
    switch (object) {
        case PlayerOutput_TimeshiftAvailability.AVAILABLE:
            return "AVAILABLE";
        case PlayerOutput_TimeshiftAvailability.UNAVAILABLE:
            return "UNAVAILABLE";
        case PlayerOutput_TimeshiftAvailability.ELIGIBLE:
            return "ELIGIBLE";
        case PlayerOutput_TimeshiftAvailability.SUBSCRIBABLE:
            return "SUBSCRIBABLE";
        case PlayerOutput_TimeshiftAvailability.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
export var PlayerOutput_PlaybackStateChanged_State;
(function (PlayerOutput_PlaybackStateChanged_State) {
    PlayerOutput_PlaybackStateChanged_State[PlayerOutput_PlaybackStateChanged_State["STOPPED"] = 0] = "STOPPED";
    PlayerOutput_PlaybackStateChanged_State[PlayerOutput_PlaybackStateChanged_State["BUFFERING"] = 1] = "BUFFERING";
    PlayerOutput_PlaybackStateChanged_State[PlayerOutput_PlaybackStateChanged_State["PAUSED"] = 2] = "PAUSED";
    PlayerOutput_PlaybackStateChanged_State[PlayerOutput_PlaybackStateChanged_State["PLAYING"] = 3] = "PLAYING";
    PlayerOutput_PlaybackStateChanged_State[PlayerOutput_PlaybackStateChanged_State["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(PlayerOutput_PlaybackStateChanged_State || (PlayerOutput_PlaybackStateChanged_State = {}));
export function playerOutput_PlaybackStateChanged_StateFromJSON(object) {
    switch (object) {
        case 0:
        case "STOPPED":
            return PlayerOutput_PlaybackStateChanged_State.STOPPED;
        case 1:
        case "BUFFERING":
            return PlayerOutput_PlaybackStateChanged_State.BUFFERING;
        case 2:
        case "PAUSED":
            return PlayerOutput_PlaybackStateChanged_State.PAUSED;
        case 3:
        case "PLAYING":
            return PlayerOutput_PlaybackStateChanged_State.PLAYING;
        case -1:
        case "UNRECOGNIZED":
        default:
            return PlayerOutput_PlaybackStateChanged_State.UNRECOGNIZED;
    }
}
export function playerOutput_PlaybackStateChanged_StateToJSON(object) {
    switch (object) {
        case PlayerOutput_PlaybackStateChanged_State.STOPPED:
            return "STOPPED";
        case PlayerOutput_PlaybackStateChanged_State.BUFFERING:
            return "BUFFERING";
        case PlayerOutput_PlaybackStateChanged_State.PAUSED:
            return "PAUSED";
        case PlayerOutput_PlaybackStateChanged_State.PLAYING:
            return "PLAYING";
        case PlayerOutput_PlaybackStateChanged_State.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBasePlayerOutput() {
    return {};
}
export const PlayerOutput = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayerOutput();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return PlayerOutput.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBasePlayerOutput();
        return message;
    },
};
function createBasePlayerOutput_PlaybackStateChanged() {
    return { state: 0 };
}
export const PlayerOutput_PlaybackStateChanged = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.state !== 0) {
            writer.uint32(8).int32(message.state);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayerOutput_PlaybackStateChanged();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 8) {
                        break;
                    }
                    message.state = reader.int32();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return { state: isSet(object.state) ? playerOutput_PlaybackStateChanged_StateFromJSON(object.state) : 0 };
    },
    toJSON(message) {
        const obj = {};
        if (message.state !== 0) {
            obj.state = playerOutput_PlaybackStateChanged_StateToJSON(message.state);
        }
        return obj;
    },
    create(base) {
        return PlayerOutput_PlaybackStateChanged.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePlayerOutput_PlaybackStateChanged();
        message.state = object.state ?? 0;
        return message;
    },
};
function createBasePlayerOutput_WatchRequested() {
    return {};
}
export const PlayerOutput_WatchRequested = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayerOutput_WatchRequested();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return PlayerOutput_WatchRequested.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBasePlayerOutput_WatchRequested();
        return message;
    },
};
function createBasePlayerOutput_WatchReceived() {
    return {
        csid: "",
        registerTimeshift: undefined,
        unregisteredTimeshift: undefined,
        youthProtectionPinRequiredAt: undefined,
    };
}
export const PlayerOutput_WatchReceived = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.csid !== "") {
            writer.uint32(10).string(message.csid);
        }
        if (message.registerTimeshift !== undefined) {
            writer.uint32(16).int32(message.registerTimeshift);
        }
        if (message.unregisteredTimeshift !== undefined) {
            writer.uint32(24).int32(message.unregisteredTimeshift);
        }
        if (message.youthProtectionPinRequiredAt !== undefined) {
            writer.uint32(32).int32(message.youthProtectionPinRequiredAt);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayerOutput_WatchReceived();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.csid = reader.string();
                    continue;
                case 2:
                    if (tag !== 16) {
                        break;
                    }
                    message.registerTimeshift = reader.int32();
                    continue;
                case 3:
                    if (tag !== 24) {
                        break;
                    }
                    message.unregisteredTimeshift = reader.int32();
                    continue;
                case 4:
                    if (tag !== 32) {
                        break;
                    }
                    message.youthProtectionPinRequiredAt = reader.int32();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            csid: isSet(object.csid) ? globalThis.String(object.csid) : "",
            registerTimeshift: isSet(object.registerTimeshift)
                ? playerOutput_TimeshiftAvailabilityFromJSON(object.registerTimeshift)
                : undefined,
            unregisteredTimeshift: isSet(object.unregisteredTimeshift)
                ? playerOutput_TimeshiftAvailabilityFromJSON(object.unregisteredTimeshift)
                : undefined,
            youthProtectionPinRequiredAt: isSet(object.youthProtectionPinRequiredAt)
                ? globalThis.Number(object.youthProtectionPinRequiredAt)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.csid !== "") {
            obj.csid = message.csid;
        }
        if (message.registerTimeshift !== undefined) {
            obj.registerTimeshift = playerOutput_TimeshiftAvailabilityToJSON(message.registerTimeshift);
        }
        if (message.unregisteredTimeshift !== undefined) {
            obj.unregisteredTimeshift = playerOutput_TimeshiftAvailabilityToJSON(message.unregisteredTimeshift);
        }
        if (message.youthProtectionPinRequiredAt !== undefined) {
            obj.youthProtectionPinRequiredAt = Math.round(message.youthProtectionPinRequiredAt);
        }
        return obj;
    },
    create(base) {
        return PlayerOutput_WatchReceived.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePlayerOutput_WatchReceived();
        message.csid = object.csid ?? "";
        message.registerTimeshift = object.registerTimeshift ?? undefined;
        message.unregisteredTimeshift = object.unregisteredTimeshift ?? undefined;
        message.youthProtectionPinRequiredAt = object.youthProtectionPinRequiredAt ?? undefined;
        return message;
    },
};
function createBasePlayerOutput_PlayerReady() {
    return {};
}
export const PlayerOutput_PlayerReady = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayerOutput_PlayerReady();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return PlayerOutput_PlayerReady.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBasePlayerOutput_PlayerReady();
        return message;
    },
};
function createBasePlayerOutput_MediaChanged() {
    return {
        url: "",
        licenseUrl: undefined,
        prePadding: undefined,
        postPadding: undefined,
        mediaType: 0,
        dynamic: false,
        timeshiftAvailability: undefined,
    };
}
export const PlayerOutput_MediaChanged = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.url !== "") {
            writer.uint32(10).string(message.url);
        }
        if (message.licenseUrl !== undefined) {
            writer.uint32(18).string(message.licenseUrl);
        }
        if (message.prePadding !== undefined) {
            writer.uint32(24).int32(message.prePadding);
        }
        if (message.postPadding !== undefined) {
            writer.uint32(32).int32(message.postPadding);
        }
        if (message.mediaType !== 0) {
            writer.uint32(40).int32(message.mediaType);
        }
        if (message.dynamic !== false) {
            writer.uint32(48).bool(message.dynamic);
        }
        if (message.timeshiftAvailability !== undefined) {
            writer.uint32(56).int32(message.timeshiftAvailability);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayerOutput_MediaChanged();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.url = reader.string();
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.licenseUrl = reader.string();
                    continue;
                case 3:
                    if (tag !== 24) {
                        break;
                    }
                    message.prePadding = reader.int32();
                    continue;
                case 4:
                    if (tag !== 32) {
                        break;
                    }
                    message.postPadding = reader.int32();
                    continue;
                case 5:
                    if (tag !== 40) {
                        break;
                    }
                    message.mediaType = reader.int32();
                    continue;
                case 6:
                    if (tag !== 48) {
                        break;
                    }
                    message.dynamic = reader.bool();
                    continue;
                case 7:
                    if (tag !== 56) {
                        break;
                    }
                    message.timeshiftAvailability = reader.int32();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            url: isSet(object.url) ? globalThis.String(object.url) : "",
            licenseUrl: isSet(object.licenseUrl) ? globalThis.String(object.licenseUrl) : undefined,
            prePadding: isSet(object.prePadding) ? globalThis.Number(object.prePadding) : undefined,
            postPadding: isSet(object.postPadding) ? globalThis.Number(object.postPadding) : undefined,
            mediaType: isSet(object.mediaType) ? player_MediaTypeFromJSON(object.mediaType) : 0,
            dynamic: isSet(object.dynamic) ? globalThis.Boolean(object.dynamic) : false,
            timeshiftAvailability: isSet(object.timeshiftAvailability)
                ? playerOutput_TimeshiftAvailabilityFromJSON(object.timeshiftAvailability)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.url !== "") {
            obj.url = message.url;
        }
        if (message.licenseUrl !== undefined) {
            obj.licenseUrl = message.licenseUrl;
        }
        if (message.prePadding !== undefined) {
            obj.prePadding = Math.round(message.prePadding);
        }
        if (message.postPadding !== undefined) {
            obj.postPadding = Math.round(message.postPadding);
        }
        if (message.mediaType !== 0) {
            obj.mediaType = player_MediaTypeToJSON(message.mediaType);
        }
        if (message.dynamic !== false) {
            obj.dynamic = message.dynamic;
        }
        if (message.timeshiftAvailability !== undefined) {
            obj.timeshiftAvailability = playerOutput_TimeshiftAvailabilityToJSON(message.timeshiftAvailability);
        }
        return obj;
    },
    create(base) {
        return PlayerOutput_MediaChanged.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePlayerOutput_MediaChanged();
        message.url = object.url ?? "";
        message.licenseUrl = object.licenseUrl ?? undefined;
        message.prePadding = object.prePadding ?? undefined;
        message.postPadding = object.postPadding ?? undefined;
        message.mediaType = object.mediaType ?? 0;
        message.dynamic = object.dynamic ?? false;
        message.timeshiftAvailability = object.timeshiftAvailability ?? undefined;
        return message;
    },
};
function createBasePlayerOutput_CurrentPositionChanged() {
    return { position: 0 };
}
export const PlayerOutput_CurrentPositionChanged = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.position !== 0) {
            writer.uint32(8).int32(message.position);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayerOutput_CurrentPositionChanged();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 8) {
                        break;
                    }
                    message.position = reader.int32();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return { position: isSet(object.position) ? globalThis.Number(object.position) : 0 };
    },
    toJSON(message) {
        const obj = {};
        if (message.position !== 0) {
            obj.position = Math.round(message.position);
        }
        return obj;
    },
    create(base) {
        return PlayerOutput_CurrentPositionChanged.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePlayerOutput_CurrentPositionChanged();
        message.position = object.position ?? 0;
        return message;
    },
};
function createBasePlayerOutput_Seeked() {
    return { position: 0, seekedTime: 0 };
}
export const PlayerOutput_Seeked = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.position !== 0) {
            writer.uint32(8).int32(message.position);
        }
        if (message.seekedTime !== 0) {
            writer.uint32(16).int32(message.seekedTime);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayerOutput_Seeked();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 8) {
                        break;
                    }
                    message.position = reader.int32();
                    continue;
                case 2:
                    if (tag !== 16) {
                        break;
                    }
                    message.seekedTime = reader.int32();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            position: isSet(object.position) ? globalThis.Number(object.position) : 0,
            seekedTime: isSet(object.seekedTime) ? globalThis.Number(object.seekedTime) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.position !== 0) {
            obj.position = Math.round(message.position);
        }
        if (message.seekedTime !== 0) {
            obj.seekedTime = Math.round(message.seekedTime);
        }
        return obj;
    },
    create(base) {
        return PlayerOutput_Seeked.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePlayerOutput_Seeked();
        message.position = object.position ?? 0;
        message.seekedTime = object.seekedTime ?? 0;
        return message;
    },
};
function createBasePlayerOutput_PlayerStateChanged() {
    return { canSeekForward: false, canSeekBackward: false, canPause: false, seekableRange: undefined };
}
export const PlayerOutput_PlayerStateChanged = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.canSeekForward !== false) {
            writer.uint32(8).bool(message.canSeekForward);
        }
        if (message.canSeekBackward !== false) {
            writer.uint32(16).bool(message.canSeekBackward);
        }
        if (message.canPause !== false) {
            writer.uint32(24).bool(message.canPause);
        }
        if (message.seekableRange !== undefined) {
            PlayerOutput_PlayerStateChanged_TimeRange.encode(message.seekableRange, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayerOutput_PlayerStateChanged();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 8) {
                        break;
                    }
                    message.canSeekForward = reader.bool();
                    continue;
                case 2:
                    if (tag !== 16) {
                        break;
                    }
                    message.canSeekBackward = reader.bool();
                    continue;
                case 3:
                    if (tag !== 24) {
                        break;
                    }
                    message.canPause = reader.bool();
                    continue;
                case 4:
                    if (tag !== 34) {
                        break;
                    }
                    message.seekableRange = PlayerOutput_PlayerStateChanged_TimeRange.decode(reader, reader.uint32());
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            canSeekForward: isSet(object.canSeekForward) ? globalThis.Boolean(object.canSeekForward) : false,
            canSeekBackward: isSet(object.canSeekBackward) ? globalThis.Boolean(object.canSeekBackward) : false,
            canPause: isSet(object.canPause) ? globalThis.Boolean(object.canPause) : false,
            seekableRange: isSet(object.seekableRange)
                ? PlayerOutput_PlayerStateChanged_TimeRange.fromJSON(object.seekableRange)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.canSeekForward !== false) {
            obj.canSeekForward = message.canSeekForward;
        }
        if (message.canSeekBackward !== false) {
            obj.canSeekBackward = message.canSeekBackward;
        }
        if (message.canPause !== false) {
            obj.canPause = message.canPause;
        }
        if (message.seekableRange !== undefined) {
            obj.seekableRange = PlayerOutput_PlayerStateChanged_TimeRange.toJSON(message.seekableRange);
        }
        return obj;
    },
    create(base) {
        return PlayerOutput_PlayerStateChanged.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePlayerOutput_PlayerStateChanged();
        message.canSeekForward = object.canSeekForward ?? false;
        message.canSeekBackward = object.canSeekBackward ?? false;
        message.canPause = object.canPause ?? false;
        message.seekableRange = (object.seekableRange !== undefined && object.seekableRange !== null)
            ? PlayerOutput_PlayerStateChanged_TimeRange.fromPartial(object.seekableRange)
            : undefined;
        return message;
    },
};
function createBasePlayerOutput_PlayerStateChanged_TimeRange() {
    return { start: 0, end: 0 };
}
export const PlayerOutput_PlayerStateChanged_TimeRange = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.start !== 0) {
            writer.uint32(8).int32(message.start);
        }
        if (message.end !== 0) {
            writer.uint32(16).int32(message.end);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayerOutput_PlayerStateChanged_TimeRange();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 8) {
                        break;
                    }
                    message.start = reader.int32();
                    continue;
                case 2:
                    if (tag !== 16) {
                        break;
                    }
                    message.end = reader.int32();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            start: isSet(object.start) ? globalThis.Number(object.start) : 0,
            end: isSet(object.end) ? globalThis.Number(object.end) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.start !== 0) {
            obj.start = Math.round(message.start);
        }
        if (message.end !== 0) {
            obj.end = Math.round(message.end);
        }
        return obj;
    },
    create(base) {
        return PlayerOutput_PlayerStateChanged_TimeRange.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePlayerOutput_PlayerStateChanged_TimeRange();
        message.start = object.start ?? 0;
        message.end = object.end ?? 0;
        return message;
    },
};
function createBasePlayerOutput_PlayerError() {
    return { code: undefined, message: undefined };
}
export const PlayerOutput_PlayerError = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.code !== undefined) {
            writer.uint32(8).int32(message.code);
        }
        if (message.message !== undefined) {
            writer.uint32(18).string(message.message);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayerOutput_PlayerError();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 8) {
                        break;
                    }
                    message.code = reader.int32();
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.message = reader.string();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            code: isSet(object.code) ? globalThis.Number(object.code) : undefined,
            message: isSet(object.message) ? globalThis.String(object.message) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.code !== undefined) {
            obj.code = Math.round(message.code);
        }
        if (message.message !== undefined) {
            obj.message = message.message;
        }
        return obj;
    },
    create(base) {
        return PlayerOutput_PlayerError.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePlayerOutput_PlayerError();
        message.code = object.code ?? undefined;
        message.message = object.message ?? undefined;
        return message;
    },
};
function createBasePlayerOutput_AvailableAudioTracksChanged() {
    return { tracks: [] };
}
export const PlayerOutput_AvailableAudioTracksChanged = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.tracks) {
            Player_MediaTrack.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayerOutput_AvailableAudioTracksChanged();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.tracks.push(Player_MediaTrack.decode(reader, reader.uint32()));
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            tracks: globalThis.Array.isArray(object?.tracks)
                ? object.tracks.map((e) => Player_MediaTrack.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.tracks?.length) {
            obj.tracks = message.tracks.map((e) => Player_MediaTrack.toJSON(e));
        }
        return obj;
    },
    create(base) {
        return PlayerOutput_AvailableAudioTracksChanged.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePlayerOutput_AvailableAudioTracksChanged();
        message.tracks = object.tracks?.map((e) => Player_MediaTrack.fromPartial(e)) || [];
        return message;
    },
};
function createBasePlayerOutput_AvailableSubtitlesTrackChanged() {
    return { tracks: [] };
}
export const PlayerOutput_AvailableSubtitlesTrackChanged = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.tracks) {
            Player_MediaTrack.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayerOutput_AvailableSubtitlesTrackChanged();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.tracks.push(Player_MediaTrack.decode(reader, reader.uint32()));
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            tracks: globalThis.Array.isArray(object?.tracks)
                ? object.tracks.map((e) => Player_MediaTrack.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.tracks?.length) {
            obj.tracks = message.tracks.map((e) => Player_MediaTrack.toJSON(e));
        }
        return obj;
    },
    create(base) {
        return PlayerOutput_AvailableSubtitlesTrackChanged.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePlayerOutput_AvailableSubtitlesTrackChanged();
        message.tracks = object.tracks?.map((e) => Player_MediaTrack.fromPartial(e)) || [];
        return message;
    },
};
function createBasePlayerOutput_SelectedAudioTrackChanged() {
    return { track: undefined };
}
export const PlayerOutput_SelectedAudioTrackChanged = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.track !== undefined) {
            Player_MediaTrack.encode(message.track, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayerOutput_SelectedAudioTrackChanged();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.track = Player_MediaTrack.decode(reader, reader.uint32());
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return { track: isSet(object.track) ? Player_MediaTrack.fromJSON(object.track) : undefined };
    },
    toJSON(message) {
        const obj = {};
        if (message.track !== undefined) {
            obj.track = Player_MediaTrack.toJSON(message.track);
        }
        return obj;
    },
    create(base) {
        return PlayerOutput_SelectedAudioTrackChanged.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePlayerOutput_SelectedAudioTrackChanged();
        message.track = (object.track !== undefined && object.track !== null)
            ? Player_MediaTrack.fromPartial(object.track)
            : undefined;
        return message;
    },
};
function createBasePlayerOutput_SelectedSubtitlesTrackChanged() {
    return { track: undefined };
}
export const PlayerOutput_SelectedSubtitlesTrackChanged = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.track !== undefined) {
            Player_MediaTrack.encode(message.track, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayerOutput_SelectedSubtitlesTrackChanged();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.track = Player_MediaTrack.decode(reader, reader.uint32());
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return { track: isSet(object.track) ? Player_MediaTrack.fromJSON(object.track) : undefined };
    },
    toJSON(message) {
        const obj = {};
        if (message.track !== undefined) {
            obj.track = Player_MediaTrack.toJSON(message.track);
        }
        return obj;
    },
    create(base) {
        return PlayerOutput_SelectedSubtitlesTrackChanged.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePlayerOutput_SelectedSubtitlesTrackChanged();
        message.track = (object.track !== undefined && object.track !== null)
            ? Player_MediaTrack.fromPartial(object.track)
            : undefined;
        return message;
    },
};
function createBasePlayerOutput_AvailableAudioTracks() {
    return { tracks: [] };
}
export const PlayerOutput_AvailableAudioTracks = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.tracks) {
            Player_MediaTrack.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayerOutput_AvailableAudioTracks();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.tracks.push(Player_MediaTrack.decode(reader, reader.uint32()));
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            tracks: globalThis.Array.isArray(object?.tracks)
                ? object.tracks.map((e) => Player_MediaTrack.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.tracks?.length) {
            obj.tracks = message.tracks.map((e) => Player_MediaTrack.toJSON(e));
        }
        return obj;
    },
    create(base) {
        return PlayerOutput_AvailableAudioTracks.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePlayerOutput_AvailableAudioTracks();
        message.tracks = object.tracks?.map((e) => Player_MediaTrack.fromPartial(e)) || [];
        return message;
    },
};
function createBasePlayerOutput_SelectedAudioTrack() {
    return { track: undefined };
}
export const PlayerOutput_SelectedAudioTrack = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.track !== undefined) {
            Player_MediaTrack.encode(message.track, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayerOutput_SelectedAudioTrack();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.track = Player_MediaTrack.decode(reader, reader.uint32());
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return { track: isSet(object.track) ? Player_MediaTrack.fromJSON(object.track) : undefined };
    },
    toJSON(message) {
        const obj = {};
        if (message.track !== undefined) {
            obj.track = Player_MediaTrack.toJSON(message.track);
        }
        return obj;
    },
    create(base) {
        return PlayerOutput_SelectedAudioTrack.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePlayerOutput_SelectedAudioTrack();
        message.track = (object.track !== undefined && object.track !== null)
            ? Player_MediaTrack.fromPartial(object.track)
            : undefined;
        return message;
    },
};
function createBasePlayerOutput_AvailableSubtitlesTracks() {
    return { tracks: [] };
}
export const PlayerOutput_AvailableSubtitlesTracks = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.tracks) {
            Player_MediaTrack.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayerOutput_AvailableSubtitlesTracks();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.tracks.push(Player_MediaTrack.decode(reader, reader.uint32()));
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            tracks: globalThis.Array.isArray(object?.tracks)
                ? object.tracks.map((e) => Player_MediaTrack.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.tracks?.length) {
            obj.tracks = message.tracks.map((e) => Player_MediaTrack.toJSON(e));
        }
        return obj;
    },
    create(base) {
        return PlayerOutput_AvailableSubtitlesTracks.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePlayerOutput_AvailableSubtitlesTracks();
        message.tracks = object.tracks?.map((e) => Player_MediaTrack.fromPartial(e)) || [];
        return message;
    },
};
function createBasePlayerOutput_SelectedSubtitlesTrack() {
    return { track: undefined };
}
export const PlayerOutput_SelectedSubtitlesTrack = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.track !== undefined) {
            Player_MediaTrack.encode(message.track, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayerOutput_SelectedSubtitlesTrack();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.track = Player_MediaTrack.decode(reader, reader.uint32());
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return { track: isSet(object.track) ? Player_MediaTrack.fromJSON(object.track) : undefined };
    },
    toJSON(message) {
        const obj = {};
        if (message.track !== undefined) {
            obj.track = Player_MediaTrack.toJSON(message.track);
        }
        return obj;
    },
    create(base) {
        return PlayerOutput_SelectedSubtitlesTrack.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePlayerOutput_SelectedSubtitlesTrack();
        message.track = (object.track !== undefined && object.track !== null)
            ? Player_MediaTrack.fromPartial(object.track)
            : undefined;
        return message;
    },
};
function createBasePlayerOutput_QualityChanged() {
    return { bitrate: 0 };
}
export const PlayerOutput_QualityChanged = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.bitrate !== 0) {
            writer.uint32(8).uint32(message.bitrate);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayerOutput_QualityChanged();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 8) {
                        break;
                    }
                    message.bitrate = reader.uint32();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return { bitrate: isSet(object.bitrate) ? globalThis.Number(object.bitrate) : 0 };
    },
    toJSON(message) {
        const obj = {};
        if (message.bitrate !== 0) {
            obj.bitrate = Math.round(message.bitrate);
        }
        return obj;
    },
    create(base) {
        return PlayerOutput_QualityChanged.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePlayerOutput_QualityChanged();
        message.bitrate = object.bitrate ?? 0;
        return message;
    },
};
function createBasePlayerOutput_Properties() {
    return {
        prePadding: 0,
        postPadding: 0,
        isLive: false,
        mediaType: 0,
        currentState: undefined,
        seekableRange: undefined,
        isStatsForNerdsEnabled: false,
        duration: undefined,
        volume: 0,
        currentPosition: undefined,
        canSeekForward: false,
        canSeekBackward: false,
        canPause: false,
    };
}
export const PlayerOutput_Properties = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.prePadding !== 0) {
            writer.uint32(9).double(message.prePadding);
        }
        if (message.postPadding !== 0) {
            writer.uint32(17).double(message.postPadding);
        }
        if (message.isLive !== false) {
            writer.uint32(24).bool(message.isLive);
        }
        if (message.mediaType !== 0) {
            writer.uint32(32).int32(message.mediaType);
        }
        if (message.currentState !== undefined) {
            writer.uint32(40).int32(message.currentState);
        }
        if (message.seekableRange !== undefined) {
            PlayerOutput_PlayerStateChanged_TimeRange.encode(message.seekableRange, writer.uint32(50).fork()).ldelim();
        }
        if (message.isStatsForNerdsEnabled !== false) {
            writer.uint32(56).bool(message.isStatsForNerdsEnabled);
        }
        if (message.duration !== undefined) {
            writer.uint32(65).double(message.duration);
        }
        if (message.volume !== 0) {
            writer.uint32(73).double(message.volume);
        }
        if (message.currentPosition !== undefined) {
            writer.uint32(81).double(message.currentPosition);
        }
        if (message.canSeekForward !== false) {
            writer.uint32(88).bool(message.canSeekForward);
        }
        if (message.canSeekBackward !== false) {
            writer.uint32(96).bool(message.canSeekBackward);
        }
        if (message.canPause !== false) {
            writer.uint32(104).bool(message.canPause);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayerOutput_Properties();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 9) {
                        break;
                    }
                    message.prePadding = reader.double();
                    continue;
                case 2:
                    if (tag !== 17) {
                        break;
                    }
                    message.postPadding = reader.double();
                    continue;
                case 3:
                    if (tag !== 24) {
                        break;
                    }
                    message.isLive = reader.bool();
                    continue;
                case 4:
                    if (tag !== 32) {
                        break;
                    }
                    message.mediaType = reader.int32();
                    continue;
                case 5:
                    if (tag !== 40) {
                        break;
                    }
                    message.currentState = reader.int32();
                    continue;
                case 6:
                    if (tag !== 50) {
                        break;
                    }
                    message.seekableRange = PlayerOutput_PlayerStateChanged_TimeRange.decode(reader, reader.uint32());
                    continue;
                case 7:
                    if (tag !== 56) {
                        break;
                    }
                    message.isStatsForNerdsEnabled = reader.bool();
                    continue;
                case 8:
                    if (tag !== 65) {
                        break;
                    }
                    message.duration = reader.double();
                    continue;
                case 9:
                    if (tag !== 73) {
                        break;
                    }
                    message.volume = reader.double();
                    continue;
                case 10:
                    if (tag !== 81) {
                        break;
                    }
                    message.currentPosition = reader.double();
                    continue;
                case 11:
                    if (tag !== 88) {
                        break;
                    }
                    message.canSeekForward = reader.bool();
                    continue;
                case 12:
                    if (tag !== 96) {
                        break;
                    }
                    message.canSeekBackward = reader.bool();
                    continue;
                case 13:
                    if (tag !== 104) {
                        break;
                    }
                    message.canPause = reader.bool();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            prePadding: isSet(object.prePadding) ? globalThis.Number(object.prePadding) : 0,
            postPadding: isSet(object.postPadding) ? globalThis.Number(object.postPadding) : 0,
            isLive: isSet(object.isLive) ? globalThis.Boolean(object.isLive) : false,
            mediaType: isSet(object.mediaType) ? player_MediaTypeFromJSON(object.mediaType) : 0,
            currentState: isSet(object.currentState)
                ? playerOutput_PlaybackStateChanged_StateFromJSON(object.currentState)
                : undefined,
            seekableRange: isSet(object.seekableRange)
                ? PlayerOutput_PlayerStateChanged_TimeRange.fromJSON(object.seekableRange)
                : undefined,
            isStatsForNerdsEnabled: isSet(object.isStatsForNerdsEnabled)
                ? globalThis.Boolean(object.isStatsForNerdsEnabled)
                : false,
            duration: isSet(object.duration) ? globalThis.Number(object.duration) : undefined,
            volume: isSet(object.volume) ? globalThis.Number(object.volume) : 0,
            currentPosition: isSet(object.currentPosition) ? globalThis.Number(object.currentPosition) : undefined,
            canSeekForward: isSet(object.canSeekForward) ? globalThis.Boolean(object.canSeekForward) : false,
            canSeekBackward: isSet(object.canSeekBackward) ? globalThis.Boolean(object.canSeekBackward) : false,
            canPause: isSet(object.canPause) ? globalThis.Boolean(object.canPause) : false,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.prePadding !== 0) {
            obj.prePadding = message.prePadding;
        }
        if (message.postPadding !== 0) {
            obj.postPadding = message.postPadding;
        }
        if (message.isLive !== false) {
            obj.isLive = message.isLive;
        }
        if (message.mediaType !== 0) {
            obj.mediaType = player_MediaTypeToJSON(message.mediaType);
        }
        if (message.currentState !== undefined) {
            obj.currentState = playerOutput_PlaybackStateChanged_StateToJSON(message.currentState);
        }
        if (message.seekableRange !== undefined) {
            obj.seekableRange = PlayerOutput_PlayerStateChanged_TimeRange.toJSON(message.seekableRange);
        }
        if (message.isStatsForNerdsEnabled !== false) {
            obj.isStatsForNerdsEnabled = message.isStatsForNerdsEnabled;
        }
        if (message.duration !== undefined) {
            obj.duration = message.duration;
        }
        if (message.volume !== 0) {
            obj.volume = message.volume;
        }
        if (message.currentPosition !== undefined) {
            obj.currentPosition = message.currentPosition;
        }
        if (message.canSeekForward !== false) {
            obj.canSeekForward = message.canSeekForward;
        }
        if (message.canSeekBackward !== false) {
            obj.canSeekBackward = message.canSeekBackward;
        }
        if (message.canPause !== false) {
            obj.canPause = message.canPause;
        }
        return obj;
    },
    create(base) {
        return PlayerOutput_Properties.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePlayerOutput_Properties();
        message.prePadding = object.prePadding ?? 0;
        message.postPadding = object.postPadding ?? 0;
        message.isLive = object.isLive ?? false;
        message.mediaType = object.mediaType ?? 0;
        message.currentState = object.currentState ?? undefined;
        message.seekableRange = (object.seekableRange !== undefined && object.seekableRange !== null)
            ? PlayerOutput_PlayerStateChanged_TimeRange.fromPartial(object.seekableRange)
            : undefined;
        message.isStatsForNerdsEnabled = object.isStatsForNerdsEnabled ?? false;
        message.duration = object.duration ?? undefined;
        message.volume = object.volume ?? 0;
        message.currentPosition = object.currentPosition ?? undefined;
        message.canSeekForward = object.canSeekForward ?? false;
        message.canSeekBackward = object.canSeekBackward ?? false;
        message.canPause = object.canPause ?? false;
        return message;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
