import { TimeshiftStatus } from '@zattoo/zapi/lib/stream/enums';
import { PlaybackState, } from '@zattoo/playback-sdk';
import * as timeshiftUtils from './utils';
export const PlayerWithUnregisteredTimeshift = (Base) => {
    return class extends Base {
        unregisteredTimeshiftAvailability = TimeshiftStatus.UNAVAILABLE;
        #newSeekableRange = null;
        get #timeshiftCanSeekForward() {
            return timeshiftUtils.canSeekForward(this.currentPosition, this.currentState, this.#newSeekableRange);
        }
        get #timeshiftCanSeekBackward() {
            return timeshiftUtils.canSeekBackward(this.currentPosition, this.currentState, this.#newSeekableRange);
        }
        get #timeshiftCanPause() {
            return timeshiftUtils.canPause(this.currentPosition, this.currentState, this.#newSeekableRange);
        }
        get timeshiftAvailability() {
            if (!this.isLive) {
                return null;
            }
            return this.unregisteredTimeshiftAvailability;
        }
        #updateTimeshiftAvailability(watchResponse) {
            this.unregisteredTimeshiftAvailability = watchResponse.unregistered_timeshift
                ?? TimeshiftStatus.UNAVAILABLE;
        }
        // @ts-expect-error https://github.com/microsoft/TypeScript/issues/48125
        setPlayerState(newState) {
            if (!timeshiftUtils.isModuleResponsible(this.isLive, this.unregisteredTimeshiftAvailability) || this.isAdPlaying()) {
                return super.setPlayerState(newState);
            }
            this.#newSeekableRange = newState.seekableRange ?? this.seekableRange;
            if (this.currentState === PlaybackState.PAUSED &&
                !this.#timeshiftCanSeekBackward) {
                this.play();
            }
            return super.setPlayerState({
                canPause: this.#timeshiftCanPause,
                canSeekBackward: this.#timeshiftCanSeekBackward,
                canSeekForward: this.#timeshiftCanSeekForward,
                seekableRange: this.seekableRange,
                ...newState,
            });
        }
        zapiHookMediaItem(media) {
            const watchResponse = this.zapiWatchResponse;
            if (!this.isLive || !watchResponse) {
                return super.zapiHookMediaItem(media);
            }
            this.#updateTimeshiftAvailability(watchResponse);
            return {
                ...super.zapiHookMediaItem(media),
                timeshiftAvailability: this.unregisteredTimeshiftAvailability,
            };
        }
        // @ts-expect-error https://github.com/microsoft/TypeScript/issues/48125
        seek(position) {
            if (!timeshiftUtils.isModuleResponsible(this.isLive, this.unregisteredTimeshiftAvailability)) {
                super.seek(position);
                return;
            }
            if (!this.seekableRange) {
                return;
            }
            super.seek(timeshiftUtils.getSafeSeekPosition(position, this.seekableRange));
        }
    };
};
