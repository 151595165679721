import { StreamType } from '../../stream/enums/stream-type';
export const createSourceConfiguration = (media, options, start) => {
    const config = {};
    if (start) {
        config.options = {
            startOffset: start.startOffset,
            startOffsetTimelineReference: start.startOffsetTimelineReference,
        };
    }
    const streamUrl = media.url;
    const licenseUrl = media.licenseUrl;
    const streamType = options.streamType;
    if (streamType === StreamType.DASH_WIDEVINE ||
        streamType === StreamType.DASH_PLAYREADY ||
        streamType === StreamType.DASH) {
        config.dash = streamUrl;
    }
    if (streamType === StreamType.DASH_WIDEVINE) {
        return {
            ...config,
            drm: {
                widevine: {
                    LA_URL: licenseUrl,
                },
            },
        };
    }
    if (streamType === StreamType.DASH_PLAYREADY) {
        return {
            ...config,
            drm: {
                playready: {
                    LA_URL: licenseUrl,
                },
            },
        };
    }
    if (streamType === StreamType.HLS7_FAIRPLAY ||
        streamType === StreamType.HLS7) {
        config.hls = streamUrl;
    }
    if (streamType === StreamType.HLS7_FAIRPLAY) {
        return {
            ...config,
            drm: {
                fairplay: {
                    LA_URL: licenseUrl,
                    certificateURL: options.fairPlayCertificateUrl,
                    headers: {
                        'content-type': 'application/data',
                    },
                    prepareMessage(keyMessage) {
                        return keyMessage.messageBase64Encoded;
                    },
                    prepareContentId(contentId) {
                        const prefix = 'skd://';
                        return contentId.substring(contentId.indexOf(prefix) + prefix.length);
                    },
                    useUint16InitData: true,
                },
            },
        };
    }
    return config;
};
