export const PlayerWithEvents = (Base) => {
    return class extends Base {
        on(eventKey, listener) {
            // @ts-expect-error key mismatch
            super.on(eventKey, listener);
        }
        off(eventKey, listener) {
            // @ts-expect-error key mismatch
            super.off(eventKey, listener);
        }
        triggerEvent(event) {
            super.triggerEvent(event);
        }
    };
};
