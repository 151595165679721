import React, { useEffect, useState, } from 'react';
import { Button, View, Text, StyleSheet, } from 'react-native';
import { EventKey, PlaybackState, } from '@zattoo/playback-sdk-react-native-zapi';
import { ScrollView } from '../layout/scroll-view';
import { PlayerManager } from './player-manager';
import { useOsd } from './osd-context';
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: 10,
        padding: 5,
    },
    label: {
        minWidth: 100,
        color: 'white',
        shadowColor: 'black',
        height: '100%',
        textAlign: 'center',
    },
    scroll: {
        flexGrow: 0,
    },
});
export const Control = () => {
    const playerManager = PlayerManager.getInstance();
    const player = playerManager.getPlayer();
    const { osd } = useOsd();
    const [playbackState, setPlaybackState] = useState(PlaybackState.STOPPED);
    const [canSeekForward, setCanSeekForward] = useState(false);
    const [canSeekBackward, setCanSeekBackward] = useState(false);
    const [canPause, setCanPause] = useState(false);
    useEffect(() => {
        const _playerManager = PlayerManager.getInstance();
        const _player = _playerManager.getPlayer();
        const updatePlaybackState = (event) => {
            setPlaybackState(event.state);
        };
        const updatePlayerState = (event) => {
            setCanSeekForward(event.canSeekForward);
            setCanSeekBackward(event.canSeekBackward);
            setCanPause(event.canPause);
        };
        _player?.on(EventKey.PLAYBACK_STATE_CHANGED, updatePlaybackState);
        _player?.on(EventKey.PLAYER_STATE_CHANGED, updatePlayerState);
        return () => {
            _player?.off(EventKey.PLAYBACK_STATE_CHANGED, updatePlaybackState);
            _player?.off(EventKey.PLAYER_STATE_CHANGED, updatePlayerState);
        };
    }, []);
    if (!player) {
        return null;
    }
    const restartReplay = () => {
        if (!osd || !osd.programId || !osd.channelId) {
            return;
        }
        player?.playProgram(osd.channelId, osd.programId, {
            preferredAudioLanguage: 'de',
            preferredSubtitlesLanguage: 'de',
            startupPosition: 0,
            program: {
                start: osd.programStart,
                duration: osd.programEnd - osd.programStart,
            },
            pin: osd.pin,
        });
    };
    const restartRecording = () => {
        if (!osd || !osd.recordingId) {
            return;
        }
        player?.playRecording(osd.recordingId, {
            preferredAudioLanguage: 'de',
            preferredSubtitlesLanguage: 'de',
            startupPosition: 0,
            pin: osd.pin,
        });
    };
    player.on(EventKey.PLAYBACK_STATE_CHANGED, (event) => {
        setPlaybackState(event.state);
    });
    const pauseHandler = () => {
        player.pause();
    };
    const playHandler = () => {
        player.play();
    };
    const increaseVolumeHandler = () => {
        player.setVolume(player.volume + 0.1);
    };
    const decreaseVolumeHandler = () => {
        player.setVolume(player.volume - 0.1);
    };
    return (React.createElement(ScrollView, { horizontal: true, style: styles.scroll },
        React.createElement(View, { style: styles.container },
            React.createElement(Text, { style: styles.label }, playbackState),
            osd?.programId &&
                React.createElement(Button, { onPress: restartReplay, title: "Replay - from program.s" }),
            osd?.recordingId &&
                React.createElement(Button, { onPress: restartRecording, title: "Recording - from program.s" }),
            React.createElement(Button, { onPress: () => {
                    player.seek(30);
                }, title: "Seek to 30s", disabled: !canSeekBackward }),
            React.createElement(Button, { onPress: () => {
                    playHandler();
                }, title: "Play", disabled: playbackState !== PlaybackState.PAUSED }),
            React.createElement(Button, { onPress: () => {
                    pauseHandler();
                }, title: "Pause", disabled: !canPause }),
            React.createElement(Button, { onPress: () => {
                    player.seekBackward(1);
                }, title: "Seek Back", disabled: !canSeekBackward }),
            React.createElement(Button, { onPress: () => {
                    player.seekForward(1);
                }, title: "Seek Forward", disabled: !canSeekForward }),
            React.createElement(Button, { onPress: () => {
                    const duration = player.duration;
                    if (!duration) {
                        return;
                    }
                    player.seek(duration - 5);
                }, title: "Seek to end", disabled: !canSeekForward }),
            React.createElement(Button, { onPress: () => {
                    increaseVolumeHandler();
                }, title: "Volume up" }),
            React.createElement(Button, { onPress: () => {
                    decreaseVolumeHandler();
                }, title: "Volume down" }))));
};
