import { errorPlayerContainerNotCreated, errorPlayerNotCreated, errorPlayerZapiNotCreated, } from './actions/errors';
export var ContainerType;
(function (ContainerType) {
    ContainerType[ContainerType["None"] = 0] = "None";
    ContainerType[ContainerType["PLAYER"] = 1] = "PLAYER";
    ContainerType[ContainerType["PLAYER_ZAPI"] = 2] = "PLAYER_ZAPI";
})(ContainerType || (ContainerType = {}));
export class InternalPlayerContainer {
    playerType;
    player;
    playerZapi;
    constructor(playerType, player) {
        this.playerType = playerType;
        switch (playerType) {
            case ContainerType.PLAYER:
                this.player = player;
                this.playerZapi = null;
                break;
            case ContainerType.PLAYER_ZAPI:
                this.player = null;
                this.playerZapi = player;
                break;
            case ContainerType.None:
                this.player = null;
                this.playerZapi = null;
                break;
            default:
                throw errorPlayerContainerNotCreated;
        }
    }
    getPlayer() {
        if (this.player === null) {
            throw errorPlayerNotCreated;
        }
        return this.player;
    }
    getContainerType() {
        return this.playerType;
    }
    getPlayerZapi() {
        if (this.playerZapi === null) {
            throw errorPlayerZapiNotCreated;
        }
        return this.playerZapi;
    }
    getAny() {
        switch (this.playerType) {
            case ContainerType.PLAYER:
                return this.getPlayer();
            case ContainerType.PLAYER_ZAPI:
                return this.getPlayerZapi();
            default:
                throw errorPlayerNotCreated;
        }
    }
}
