import { ZapiEventKey } from '../event/event-key';
import { ErrorCode } from '../error/error-code';
import { ZapiPlaybackError } from '../error/error';
export const PlayerWithPositionListener = (Base) => {
    return class PlayerWithUtil extends Base {
        pinRequiredAt = null;
        onPositionChange = (event) => {
            if (!this.pinRequiredAt) {
                return;
            }
            if (this.pinRequiredAt <= event.position) {
                this.handleError(new ZapiPlaybackError(ErrorCode.PinRequired, 'Pin required'));
            }
        };
        cleanUpPositionListener() {
            this.off(ZapiEventKey.CURRENT_POSITION_CHANGED, this.onPositionChange);
            this.pinRequiredAt = null;
        }
        updatePinRequiredAt() {
            this.pinRequiredAt =
                this.zapiWatchResponse?.youth_protection_pin_required_at ??
                    null;
        }
        // @ts-expect-error https://github.com/microsoft/TypeScript/issues/48125
        destroy() {
            this.cleanUpPositionListener();
            return super.destroy();
        }
        // @ts-expect-error https://github.com/microsoft/TypeScript/issues/48125
        stop() {
            this.cleanUpPositionListener();
            return super.stop();
        }
        zapiHookMediaItem(media) {
            this.cleanUpPositionListener();
            this.updatePinRequiredAt();
            if (!this.pinRequiredAt) {
                return super.zapiHookMediaItem(media);
            }
            this.on(ZapiEventKey.CURRENT_POSITION_CHANGED, this.onPositionChange);
            return super.zapiHookMediaItem(media);
        }
    };
};
