import { TimeshiftStatus } from '@zattoo/zapi/lib/stream/enums';
import { PlaybackState, } from '@zattoo/playback-sdk';
import { SEEK_BACK_MARGIN, SEEK_FORWARD_MARGIN, } from '../utils';
export const isModuleResponsible = (isLive, timeshiftAvailability) => {
    return (isLive &&
        timeshiftAvailability === TimeshiftStatus.AVAILABLE);
};
export const canSeekForward = (currentPosition, currentState, seekableRange) => {
    if (!currentPosition ||
        !seekableRange?.end ||
        currentState === PlaybackState.STOPPED) {
        return false;
    }
    return seekableRange.end > currentPosition + SEEK_FORWARD_MARGIN;
};
export const canSeekBackward = (currentPosition, currentState, seekableRange) => {
    if (!currentPosition ||
        seekableRange?.start === undefined ||
        currentState === PlaybackState.STOPPED) {
        return false;
    }
    return currentPosition > seekableRange.start + SEEK_BACK_MARGIN;
};
export const canPause = (currentPosition, currentState, seekableRange) => {
    return ((currentState === PlaybackState.PLAYING ||
        currentState === PlaybackState.BUFFERING) &&
        canSeekBackward(currentPosition, currentState, seekableRange));
};
export { getSafeSeekPosition } from '../utils';
