import { VideoAdType } from '@zattoo/zapi/lib/stream/enums';
import { AdType, } from '@zattoo/playback-sdk';
export const adsInfoFromWatchResponse = (watchResponseAdInfo, muteStream) => {
    if (!watchResponseAdInfo || !watchResponseAdInfo.length) {
        return undefined;
    }
    return {
        muteStream,
        ads: watchResponseAdInfo.map((ad) => {
            // todo: incomplete mapping
            const type = ad.type === VideoAdType.MIDROLL
                ? AdType.MIDROLL
                : AdType.PREROLL;
            return {
                type,
                url: ad.vast_url,
            };
        }),
    };
};
export const trackingInfoFromWatchResponse = (psid, watchResponse) => {
    const trackingInfo = watchResponse?.tracking;
    if (!trackingInfo?.temp_telemetry_url) {
        return {
            psid,
        };
    }
    return {
        psid,
        telemetryEndpoint: trackingInfo.temp_telemetry_url,
    };
};
