import { MediaType, } from '@zattoo/playback-sdk';
export const PlayerWithProperties = (Base) => {
    return class extends Base {
        get prePadding() {
            return this.zapiMedia?.prePadding ?? 0;
        }
        get postPadding() {
            return this.zapiMedia?.postPadding ?? 0;
        }
        get isLive() {
            return this.mediaType === MediaType.LIVE;
        }
        get mediaType() {
            return this.zapiStreamRequestInfo?.mediaType ?? null;
        }
    };
};
