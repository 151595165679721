import { WebPlayer, detectCapabilities, } from '@zattoo/playback-sdk';
import { BitmovinAdapterWithIma } from '@zattoo/playback-sdk/adapter/bitmovin';
export const init = () => {
    // nothing to initialize for apple
    // but keep the function for compatibility with other platforms
    return Promise.resolve();
};
export const createPlayer = (options) => {
    const capabilities = detectCapabilities();
    return new WebPlayer(options, new BitmovinAdapterWithIma(options, capabilities), capabilities);
};
export { PlayerView, } from './player-view';
export { BitmovinAdapterWithIma as Adapter, WebPlayer as Player, };
