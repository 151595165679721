import { WebPlayer, detectCapabilities, } from '@zattoo/playback-sdk';
import { PlayerWithLive } from '../stream/live';
import { PlayerWithTimeshift } from '../stream/timeshift';
import { PlayerWithZapiBase } from './base';
import { PlayerWithVod } from '../stream/vod';
import { PlayerWithRecording } from '../stream/recordings';
import { PlayerWithReplay } from '../stream/replay';
import { PlayerWithStreamRequest } from '../stream/request';
export const WithZapi = (Base) => {
    return PlayerWithReplay(PlayerWithRecording(PlayerWithVod(PlayerWithTimeshift(PlayerWithLive(PlayerWithStreamRequest(PlayerWithZapiBase(Base)))))));
};
const WebPlayerZapi = WithZapi(WebPlayer);
export const createPlayer = (options, adapter) => {
    const capabilities = detectCapabilities();
    return new WebPlayerZapi(options, adapter, capabilities);
};
