import { Platform } from 'react-native';
import { Action, Output, } from '@zattoo/taf-protobuf/protobuf/action';
import { EventKey as SocketEventKey, Socket, } from './socket-adapter';
import { convertMedia, playerAvailableAudioTracks, playerAvailableSubtitlesTracks, playerCreate, playerCreateZapi, playerDestroy, playerGetProperties, playerPause, playerPlay, playerPlayLive, playerPlayProgram, playerPlayRecording, playerPlayUrl, playerPlayVod, playerSeek, playerSeekBackward, playerSeekForward, playerSelectedAudioTrack, playerSelectedSubtitlesTrack, playerSetAudioTrack, playerSetSubtitlesTrack, playerStop, zapiCreate, zapiSessionHello, zapiSignIn, } from './actions';
import { ContainerType, InternalPlayerContainer, } from './player-container';
export var EventKey;
(function (EventKey) {
    EventKey["NewSession"] = "NEW_SESSION";
    EventKey["PlayerCreated"] = "PLAYER_CREATED";
    EventKey["PlayerDestroyed"] = "PLAYER_DESTROYED";
})(EventKey || (EventKey = {}));
export class TafAdapter {
    player = null;
    socket;
    session = null;
    listeners = new Map();
    constructor(configuration) {
        this.socket = new Socket(configuration);
        this.socket.addEventListener(SocketEventKey.Ready, this.onSocketReady);
        // @ts-expect-error incomplete types
        this.socket.addEventListener(SocketEventKey.Message, this.onSocketMessage);
        this.socket.addEventListener(SocketEventKey.Close, this.onSocketClose);
    }
    onSocketReady = () => {
        const deviceInfo = Output.create({
            deviceInfo: {
                name: 'Some device',
                id: 'some uuid',
                platform: Platform.OS,
            },
        });
        this.dispatchOutput(deviceInfo);
    };
    onSocketMessage = async (event) => {
        await this.handleSocketMessage(event.detail);
    };
    onSocketClose = () => {
        this.setSession(null);
    };
    async handle(action) {
        const actionKey = Object.entries(action)
            .filter(([_actionKey, _actionData]) => _actionData !== undefined && _actionKey !== 'id')
            .pop()?.[0];
        if (!actionKey) {
            return Promise.resolve();
        }
        const actionValue = action[actionKey];
        if (actionValue === null) {
            return Promise.resolve();
        }
        switch (actionKey) {
            case 'session':
                this.setSession(actionValue);
                break;
            case 'zapiCreate':
                zapiCreate(actionValue);
                break;
            case 'zapiSessionHello':
                await zapiSessionHello(actionValue);
                break;
            case 'zapiSignIn':
                await zapiSignIn(actionValue);
                break;
            case 'playerCreate':
                this.setPlayer(new InternalPlayerContainer(ContainerType.PLAYER, playerCreate(actionValue, (output) => this.dispatchOutput(output))));
                break;
            case 'playerCreateZapi':
                this.setPlayer(new InternalPlayerContainer(ContainerType.PLAYER_ZAPI, playerCreateZapi(actionValue, (output) => this.dispatchOutput(output))));
                break;
            case 'playerPlayLive':
                return {
                    mediaChanged: convertMedia(await playerPlayLive(this.player, actionValue)),
                };
            case 'playerPlayProgram':
                return {
                    mediaChanged: convertMedia(await playerPlayProgram(this.player, actionValue)),
                };
            case 'playerPlayRecording':
                return {
                    mediaChanged: convertMedia(await playerPlayRecording(this.player, actionValue)),
                };
            case 'playerPlayVod':
                return {
                    mediaChanged: convertMedia(await playerPlayVod(this.player, actionValue)),
                };
            case 'playerPlayUrl':
                await playerPlayUrl(this.player, actionValue);
                break;
            case 'playerSeek':
                playerSeek(this.player, actionValue);
                break;
            case 'playerGetAvailableAudioTracks':
                return playerAvailableAudioTracks(this.player);
            case 'playerGetSelectedAudioTrack':
                return playerSelectedAudioTrack(this.player);
            case 'playerGetAvailableSubtitlesTracks':
                return playerAvailableSubtitlesTracks(this.player);
            case 'playerGetSelectedSubtitlesTrack':
                return playerSelectedSubtitlesTrack(this.player);
            case 'playerSetAudioTrack':
                playerSetAudioTrack(this.player, actionValue);
                break;
            case 'playerSetSubtitlesTrack':
                playerSetSubtitlesTrack(this.player, actionValue);
                break;
            case 'playerDestroy':
                await playerDestroy(this.player);
                this.cleanPlayer();
                break;
            case 'playerStop':
                await playerStop(this.player);
                break;
            case 'playerSeekForward':
                playerSeekForward(this.player, actionValue);
                break;
            case 'playerSeekBackward':
                playerSeekBackward(this.player, actionValue);
                break;
            case 'playerGetProperties':
                return playerGetProperties(this.player);
            case 'tafCleanupSession':
                if (this.player) {
                    await playerDestroy(this.player);
                    this.cleanPlayer();
                }
                break;
            case 'tafStartSession':
                break;
            case 'playerPause':
                await playerPause(this.player);
                break;
            case 'playerPlay':
                playerPlay(this.player);
                break;
            default:
                console.log('Unsupported action', action, action.id);
                throw new Error(`Unsupported action ${JSON.stringify(action)}`);
        }
        return Promise.resolve();
    }
    async handleSocketMessage(data) {
        const action = Action.decode(data);
        let output;
        try {
            const result = await this.handle(action);
            if (!result) {
                output = Output.create({
                    actionResult: {
                        id: action.id,
                        success: true,
                    },
                });
            }
            else {
                output = Output.create({
                    actionResult: {
                        id: action.id,
                        success: true,
                        ...result,
                    },
                });
            }
        }
        catch (error) {
            console.error(error);
            output = Output.create({
                actionResult: {
                    id: action.id,
                    success: false,
                    error: `${error}`,
                },
            });
        }
        this.dispatchOutput(output);
    }
    dispatchOutput(output) {
        const encodedData = Output.encode(output).finish();
        this.socket.send(encodedData);
    }
    getSession() {
        return this.session;
    }
    setSession(session) {
        this.session = session;
        this.dispatchEvent(EventKey.NewSession, session);
    }
    setPlayer(player) {
        this.player = player;
        this.dispatchEvent(EventKey.PlayerCreated, player);
    }
    cleanPlayer() {
        this.player = null;
        this.dispatchEvent(EventKey.PlayerDestroyed);
    }
    getPlayer() {
        return this.player;
    }
    dispatchEvent(key, data) {
        const eventListeners = this.listeners.get(key);
        if (eventListeners) {
            eventListeners.forEach((listener) => listener({
                type: key,
                detail: data,
            }));
        }
    }
    addEventListener(type, listener) {
        if (!this.listeners.has(type)) {
            this.listeners.set(type, new Map());
        }
        this.listeners.get(type)?.set(listener, listener);
    }
    removeEventListener(type, listener) {
        const eventListeners = this.listeners.get(type);
        if (eventListeners) {
            eventListeners.delete(listener);
        }
    }
}
