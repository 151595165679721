import { registerTimeshift } from '@zattoo/zapi';
import { TimeshiftStatus } from '@zattoo/zapi/lib/stream/enums';
import { MediaType, } from '@zattoo/playback-sdk';
import * as timeshiftUtils from './utils';
export const PlayerWithRegisteredTimeshift = (Base) => {
    return class extends Base {
        #registeredTimeshiftAvailability = TimeshiftStatus.UNAVAILABLE;
        #channelId = null;
        #timeshiftInfo = null;
        #currentTimeRegistered = null;
        #currentSeekableRange = null;
        #previousSeekableRange = null;
        get #timeshiftCanSeekForward() {
            return timeshiftUtils.canSeekForward(this.mediaType, this.currentPosition, this.currentState, this.#currentSeekableRange, this.#timeshiftInfo?.forward_seeking);
        }
        get #timeshiftCanSeekBackward() {
            return timeshiftUtils.canSeekBackward(this.mediaType, this.currentPosition, this.currentState, this.#currentSeekableRange);
        }
        get #timeshiftCanPause() {
            return timeshiftUtils.canPause(this.#timeshiftInfo, this.currentState, this.mediaType, this.currentPosition, this.#currentSeekableRange);
        }
        get timeshiftAvailability() {
            if (this.#registeredTimeshiftAvailability === TimeshiftStatus.UNAVAILABLE) {
                return super.timeshiftAvailability;
            }
            return this.#registeredTimeshiftAvailability;
        }
        #canRegisterTimeshift() {
            return timeshiftUtils.canRegisterTimeshift(this.isLive, this.#timeshiftInfo);
        }
        #hasRegisteredTimeshift() {
            return timeshiftUtils.hasRegisteredTimeshift(this.#timeshiftInfo);
        }
        #isTimeshift() {
            return timeshiftUtils.isTimeshift(this.mediaType);
        }
        #isTimeshiftModuleResponsible() {
            if (this.#registeredTimeshiftAvailability !== TimeshiftStatus.AVAILABLE) {
                return false;
            }
            return (this.#canRegisterTimeshift() ||
                this.#hasRegisteredTimeshift() ||
                this.#isTimeshift());
        }
        #cleanTimeshiftInfo() {
            this.#registeredTimeshiftAvailability = TimeshiftStatus.UNAVAILABLE;
            this.#channelId = null;
            this.#currentTimeRegistered = null;
            this.#timeshiftInfo = null;
        }
        #updateTimeshiftInformation(watchResponse, watchRequest) {
            if (!this.isLive) {
                return;
            }
            this.#registeredTimeshiftAvailability = watchResponse.register_timeshift
                ?? TimeshiftStatus.UNAVAILABLE;
            this.#channelId = watchRequest.cid;
        }
        zapiHookMediaItem(media) {
            const watchRepsonse = this.zapiWatchResponse;
            if (!watchRepsonse) {
                throw new Error('Not enough information');
            }
            if (this.zapiStreamRequestInfo?.mediaType === MediaType.LIVE &&
                this.zapiStreamRequestInfo.watchRequestParams.cid) {
                this.#cleanTimeshiftInfo();
                this.#updateTimeshiftInformation(watchRepsonse, this.zapiStreamRequestInfo.watchRequestParams);
            }
            const isUnrelatedStreamType = !(this.isLive ||
                this.#isTimeshift());
            if (isUnrelatedStreamType ||
                this.#registeredTimeshiftAvailability === TimeshiftStatus.UNAVAILABLE) {
                this.#cleanTimeshiftInfo();
                return super.zapiHookMediaItem(media);
            }
            return {
                ...super.zapiHookMediaItem(media),
                timeshiftAvailability: this.#registeredTimeshiftAvailability,
            };
        }
        #handleEdgeCase() {
            if (timeshiftUtils.shouldAutostart(this.mediaType, this.#currentSeekableRange, this.#previousSeekableRange, this.currentState, this.currentPosition)) {
                this.play();
            }
            this.#previousSeekableRange = this.#currentSeekableRange;
        }
        // @ts-expect-error todo
        setPlayerState(newState) {
            if (!this.#isTimeshiftModuleResponsible() || this.isAdPlaying()) {
                return super.setPlayerState(newState);
            }
            if (newState.seekableRange) {
                // this.seekableRange will not be updated at this point
                // because it is set with the last super.setPlayerState call,
                // but we need to operate with the freshest available value here.
                // Otherwise there is a gap with the more frequent updating
                // currentPosition.
                this.#currentSeekableRange = newState.seekableRange;
            }
            this.#handleEdgeCase();
            return super.setPlayerState({
                ...newState,
                canPause: this.#timeshiftCanPause,
                canSeekBackward: this.#timeshiftCanSeekBackward,
                canSeekForward: this.#timeshiftCanSeekForward,
                seekableRange: this.#isTimeshift() ? this.#currentSeekableRange : null,
            });
        }
        async #registerTimeshift() {
            if (!this.#channelId) {
                return Promise.reject(new Error('Invalid channel Id'));
            }
            this.#currentTimeRegistered = this.currentPosition;
            const response = await registerTimeshift({ cid: this.#channelId });
            this.#timeshiftInfo = response;
            return Promise.resolve();
        }
        #playTimeshift() {
            const playOptions = this.playOptions;
            if (!this.#channelId || !playOptions || !this.#timeshiftInfo?.registered_at) {
                return Promise.reject(new Error('No timeshift stream registered'));
            }
            return this.requestStream({
                playOptions: {
                    ...playOptions,
                    startupPosition: playOptions.startupPosition ??
                        this.#currentTimeRegistered ??
                        0,
                },
                mediaType: MediaType.REGISTERED_TIMESHIFT,
                watchRequestParams: {
                    cid: this.#channelId,
                },
            });
        }
        // @ts-expect-error https://github.com/microsoft/TypeScript/issues/48125
        pause() {
            if (!this.#isTimeshiftModuleResponsible()) {
                return super.pause();
            }
            if (this.#canRegisterTimeshift()) {
                this.#registerTimeshift();
            }
            return super.pause();
        }
        // @ts-expect-error https://github.com/microsoft/TypeScript/issues/48125
        play() {
            if (this.#hasRegisteredTimeshift() && !this.#isTimeshift()) {
                this.#playTimeshift();
            }
            else {
                super.play();
            }
        }
        // @ts-expect-error https://github.com/microsoft/TypeScript/issues/48125
        seek(position) {
            if (!this.#isTimeshift()) {
                super.seek(position);
                return;
            }
            if (!this.#currentSeekableRange) {
                return;
            }
            super.seek(timeshiftUtils.getSafeSeekPosition(position, this.#currentSeekableRange));
        }
    };
};
