import { getLiveStream, getReplayStream, getRecordingStream, getVOD, getTimeshiftStream, } from '@zattoo/zapi';
import { StreamProtocol, StreamProtection, StreamType, MediaType, } from '@zattoo/playback-sdk';
export const getZapiStream = (requestInfo) => {
    const streamWatchParams = requestInfo;
    switch (streamWatchParams.mediaType) {
        case MediaType.LIVE:
            return getLiveStream(streamWatchParams.watchRequestParams);
        case MediaType.RECORDING:
            return getRecordingStream(streamWatchParams.watchRequestParams);
        case MediaType.REPLAY:
            return getReplayStream(streamWatchParams.watchRequestParams);
        case MediaType.VOD:
            return getVOD(streamWatchParams.watchRequestParams);
        case MediaType.REGISTERED_TIMESHIFT:
            return getTimeshiftStream(streamWatchParams.watchRequestParams);
        default:
            throw new Error('Unsupported media type');
    }
};
export const getStreamingProtocol = (options, capability) => {
    if (!options.drmRequired) {
        switch (capability.streamProtocol) {
            case StreamProtocol.DASH:
                return StreamType.DASH;
            case StreamProtocol.HLS:
                return StreamType.HLS7;
            default:
                throw new Error('Unsupported streaming protocol');
        }
    }
    switch (capability.streamProtocol) {
        case StreamProtocol.DASH:
            switch (capability.streamProtection) {
                case StreamProtection.WIDEVINE:
                    return StreamType.DASH_WIDEVINE;
                case StreamProtection.PLAYREADY:
                    return StreamType.DASH_PLAYREADY;
                default:
                    return StreamType.DASH;
            }
        case StreamProtocol.HLS:
            return StreamType.HLS7_FAIRPLAY;
        default:
            throw new Error('Unsupported streaming protocol');
    }
};
