import { AudioFormat } from '../player/enums';
export const codecToAudioFormat = (codec) => {
    if (!codec) {
        return null;
    }
    if (codec === 'ec-3') {
        return AudioFormat.DOLBY;
    }
    return AudioFormat.STEREO;
};
