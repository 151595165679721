export var TelemetryAssetType;
(function (TelemetryAssetType) {
    TelemetryAssetType["LIVE"] = "LIVE";
    TelemetryAssetType["TIMESHIFT"] = "TIMESHIFT";
    TelemetryAssetType["REPLAY"] = "REPLAY";
    TelemetryAssetType["RECORDING"] = "RECORDING";
    TelemetryAssetType["VOD"] = "VOD";
    TelemetryAssetType["VOD_TRAILER"] = "VOD_TRAILER";
    TelemetryAssetType["UNKNOWN"] = "UNKNOWN";
})(TelemetryAssetType || (TelemetryAssetType = {}));
