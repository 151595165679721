import { ErrorCode } from './errors/enums/error-code';
import { PlaybackError, isPlaybackError, } from './errors';
import { Player } from './player/player';
import { TelemetryAssetType } from './telemetry/enums/asset-type';
import { mediaTypeToTelemetryAssetType } from './telemetry/utils';
import { ImaActions } from './adapter/ima/actions';
import { WithIma } from './adapter/ima/ima';
import { WithImaCallbacksDispatcher } from './adapter/ima/callbacks-dispatcher';
import { AdapterCallbacks } from './adapter/adapter-callbacks';
import { AdapterCallbacksDispatcher } from './adapter/adapter-callbacks-dispatcher';
import { AdType, EventKey, PlaybackState, AudioFormat, } from './player/enums';
import { generatePsid } from './player/utils';
import { WebPlayer } from './player/web-player';
import { ChannelLogger, ConsoleLogger, } from './logger';
import { StreamProtocol, StreamProtection, SubtitlesCodec, } from './capability';
import { Client } from './clients/clients';
import { isApple, isChrome, } from './clients/device-detection';
export { StreamType } from './stream/enums/stream-type';
export { MediaType } from './stream/enums/media-type';
export const detectCapabilities = () => {
    if (isApple) {
        return {
            default: {
                streamProtocol: StreamProtocol.HLS,
                streamProtection: StreamProtection.FAIRPLAY,
            },
        };
    }
    else if (isChrome) {
        return {
            default: {
                streamProtocol: StreamProtocol.DASH,
                streamProtection: StreamProtection.WIDEVINE,
            },
            optional: [
                {
                    streamProtocol: StreamProtocol.HLS,
                    streamProtection: StreamProtection.NONE,
                },
            ],
        };
    }
    else {
        return {
            default: {
                streamProtocol: StreamProtocol.DASH,
                streamProtection: StreamProtection.WIDEVINE,
            },
        };
    }
};
const createPlayer = (options, 
// We couldn't import bitmovin adapter in this file
// because the build will include Bitmovin codes that requires DOM and breaks Android
// todo: maybe bundler can do magic to avoid this, but not for now
adapter) => {
    const capabilities = detectCapabilities();
    return new WebPlayer(options, adapter, capabilities);
};
export { AdType, AdapterCallbacks, AdapterCallbacksDispatcher, AudioFormat, ChannelLogger, Client, ConsoleLogger, ErrorCode, EventKey, ImaActions, PlaybackError, PlaybackState, Player, StreamProtection, StreamProtocol, SubtitlesCodec, TelemetryAssetType, WebPlayer, WithIma, WithImaCallbacksDispatcher, createPlayer, generatePsid, isPlaybackError, mediaTypeToTelemetryAssetType, };
