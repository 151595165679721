import { PlaybackState, MediaType, } from '@zattoo/playback-sdk';
export const PlayerWithReplay = (Base) => {
    return class extends Base {
        get isReplay() {
            return this.mediaType === MediaType.REPLAY;
        }
        playProgram(cid, programId, playOptions) {
            const dynamic_pvr = playOptions.dynamic ?? true;
            return this.requestStream({
                playOptions,
                mediaType: MediaType.REPLAY,
                watchRequestParams: {
                    cid,
                    program_id: programId,
                    dynamic_pvr,
                },
            });
        }
        // @ts-expect-error https://github.com/microsoft/TypeScript/issues/48125
        setPlayerState(newState) {
            if (!this.isReplay || this.isAdPlaying()) {
                return super.setPlayerState(newState);
            }
            return super.setPlayerState({
                canPause: this.currentState === PlaybackState.PLAYING || this.currentState === PlaybackState.BUFFERING,
                canSeekBackward: true,
                canSeekForward: this.zapiWatchResponse?.stream?.forward_seeking,
                seekableRange: this.seekableRange,
                ...newState,
            });
        }
    };
};
