import { MediaType, } from '@zattoo/playback-sdk';
export const MAX_TIMESHIFT_OFFSET = 10800;
export const PlayerWithLive = (Base) => {
    return class extends Base {
        playLive(channelId, playOptions) {
            return this.requestStream({
                playOptions,
                mediaType: MediaType.LIVE,
                watchRequestParams: {
                    cid: channelId,
                    timeshift: MAX_TIMESHIFT_OFFSET,
                },
            });
        }
        // @ts-expect-error https://github.com/microsoft/TypeScript/issues/48125
        setPlayerState(newState) {
            if (!this.isLive || this.isAdPlaying()) {
                return super.setPlayerState(newState);
            }
            return super.setPlayerState({
                canPause: false,
                canSeekBackward: false,
                canSeekForward: false,
                seekableRange: null,
                ...newState,
            });
        }
    };
};
