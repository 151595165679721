import { MediaType } from '@zattoo/playback-sdk';
export const assetIdFromRequestInfo = (requestInfo) => {
    switch (requestInfo.mediaType) {
        case MediaType.LIVE:
            return requestInfo.watchRequestParams.cid;
        case MediaType.RECORDING:
            return String(requestInfo.watchRequestParams.recording_id);
        case MediaType.REPLAY:
            return String(requestInfo.watchRequestParams.program_id);
        case MediaType.VOD:
            return requestInfo.watchRequestParams.teasable_id;
        case MediaType.REGISTERED_TIMESHIFT:
            return requestInfo.watchRequestParams.cid;
        default:
            return '';
    }
};
