import React, { createRef, useCallback, useState, } from 'react';
import { UTVPlayerView, } from '@zattoo/playback-sdk-react-native-zapi';
import { useSessionContext } from '../authentication/context';
import { styles } from './styles';
import { UTVOSD } from './utv-osd';
export const PlayerApi = createRef();
export const NewPlayerView = (props) => {
    const publicId = useSessionContext().session?.account?.public_id;
    const [playerState, setPlayerState] = useState(undefined);
    const onEvent = useCallback((event) => {
        console.log('Event:', event);
        setPlayerState(event.state);
    }, []);
    if (!publicId) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(UTVPlayerView, { ref: PlayerApi, host: "https://staging.zattoo.com", publicId: publicId, appVersion: "9.9.9", appId: 204, stepBackwardDuration: 10, stepForwardDuration: 10, style: styles.player, onEvent: onEvent, debug: true }),
        React.createElement(UTVOSD, { playerState: playerState, hide: props.hideOsd })));
};
