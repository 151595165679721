import { isZAPIError, ErrorCode as ZAPIErrorCodes, } from '@zattoo/zapi/lib/helpers/error';
import { isPlaybackError } from '@zattoo/playback-sdk';
import { ErrorCode } from './error-code';
import { ZapiPlaybackError } from './error';
export const isZapiPlaybackError = (error) => {
    return error instanceof ZapiPlaybackError;
};
const isZapiPinError = (error) => {
    return (error.code === ZAPIErrorCodes.PIN_MISSING ||
        error.code === ZAPIErrorCodes.PIN_INVALID ||
        error.code === ZAPIErrorCodes.PIN_SETUP_REQUIRED ||
        error.code === ZAPIErrorCodes.PIN_LOCKED);
};
export const isPinError = (error) => {
    const zapiPinError = isZAPIError(error) && isZapiPinError(error);
    const playbackPinError = isZapiPlaybackError(error) && (error.extendedCode === ErrorCode.PinRequired);
    const mappedZapiPinError = isZapiPlaybackError(error) &&
        isZAPIError(error.zapiError) &&
        isZapiPinError(error.zapiError);
    return zapiPinError || playbackPinError || mappedZapiPinError;
};
export const mapError = (error) => {
    if (isZapiPlaybackError(error)) {
        return error;
    }
    if (isPlaybackError(error)) {
        return new ZapiPlaybackError(error.code, error.message);
    }
    if (isZAPIError(error)) {
        return new ZapiPlaybackError(error);
    }
    return new ZapiPlaybackError(ErrorCode.Unknown, String(error));
};
