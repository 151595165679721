import { AudioFormat, SubtitlesCodec, } from '@zattoo/playback-sdk';
export const watchParamsFromStreamType = (streamType) => ({
    stream_type: streamType,
    https_watch_urls: true,
});
export const watchParamsFromPlayOptions = (playOptions) => {
    const params = {};
    if (playOptions?.pin) {
        params.youth_protection_pin = playOptions.pin;
    }
    if (playOptions?.tcString) {
        params.tc_string = playOptions.tcString;
    }
    return params;
};
export const watchParamsFromCapability = (capability) => {
    const params = {};
    // todo: set based on capability in the future
    params.enable_eac3 = true;
    // we don't have the capability module on all platforms
    // so we just check for explicit stereo to fix kepler
    // https://zattoo2.atlassian.net/browse/PLY-1735
    if (capability.audioFormat === AudioFormat.STEREO) {
        params.enable_eac3 = false;
    }
    if (capability?.subtitlesCodec) {
        params.vtt = SubtitlesCodec.VTT === capability.subtitlesCodec;
        params.ttml = SubtitlesCodec.TTML === capability.subtitlesCodec;
    }
    return params;
};
export const mapHdcpVersionToType = (version) => {
    if (!version) {
        return null;
    }
    if (version.toLowerCase() === 'unprotected') {
        return 'Unprotected';
    }
    if (Number(version) >= 1 && Number(version) < 2) {
        return 0;
    }
    if (Number(version) >= 2) {
        return 1;
    }
    return null;
};
export const watchParamsFromAdapterCapability = (capability) => {
    const params = {};
    if (capability.hdcp) {
        const hdcpType = mapHdcpVersionToType(capability.hdcp.connectedVersion);
        if (hdcpType !== null) {
            params.max_hdcp_type = hdcpType;
        }
    }
    return params;
};
/**
 * Picks highest rate from watch urls list
 * Remark: maxrate 0 means unlimited
 */
export const pickHighestRate = (watchUrls) => {
    if (watchUrls.length === 0) {
        return null;
    }
    const sort = (infoA, infoB) => {
        const maxRateA = infoA.maxrate || Number.POSITIVE_INFINITY;
        const maxRateB = infoB.maxrate || Number.POSITIVE_INFINITY;
        // order descending
        return maxRateB - maxRateA;
    };
    return watchUrls.slice().sort(sort)[0] ?? null;
};
export const mediaItemFromWatchResponse = (mediaType, watchResponse, dynamic) => {
    const highestRate = pickHighestRate(watchResponse.stream.watch_urls);
    const url = watchResponse.stream.url ??
        highestRate?.url;
    const licenseUrl = watchResponse.stream.license_url ??
        highestRate?.license_url;
    return {
        url,
        licenseUrl,
        prePadding: watchResponse.stream.padding?.pre ?? 0,
        postPadding: watchResponse.stream.padding?.post ?? 0,
        mediaType,
        dynamic: dynamic ?? false,
    };
};
