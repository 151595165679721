export const WithProperty = (Base) => {
    return class PlayerWithProperty extends Base {
        get mediaType() {
            return this._mediaType ?? null;
        }
        get canSeekForward() {
            return this._canSeekForward;
        }
        get canSeekBackward() {
            return this._canSeekBackward;
        }
        get canPause() {
            return this._canPause;
        }
        get duration() {
            return this._duration;
        }
        get seekableRange() {
            return this._seekableRange;
        }
        get volume() {
            return this._volume;
        }
        get currentState() {
            return this._playbackState;
        }
        get currentPosition() {
            return this._currentPosition;
        }
        get capabilities() {
            return this._capabilities;
        }
        get isStatsForNerdsEnabled() {
            this._logger?.info('not implemented: prePadding');
            return false;
        }
    };
};
