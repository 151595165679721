import { PlaybackState, MediaType, } from '@zattoo/playback-sdk';
export const PlayerWithVod = (Base) => {
    return class extends Base {
        get isVod() {
            return this.mediaType === MediaType.VOD;
        }
        // eslint-disable-next-line max-params
        playVod(token, teasableId, teasableType, playOptions) {
            return this.requestStream({
                playOptions,
                mediaType: MediaType.VOD,
                watchRequestParams: {
                    teasable_id: teasableId,
                    teasable_type: teasableType,
                    term_token: token,
                    https_watch_urls: true,
                },
            });
        }
        // @ts-expect-error todo
        setPlayerState(newState) {
            if (!this.isVod || this.isAdPlaying()) {
                return super.setPlayerState(newState);
            }
            return super.setPlayerState({
                canPause: (this.currentState === PlaybackState.PLAYING ||
                    this.currentState === PlaybackState.BUFFERING),
                canSeekBackward: true,
                canSeekForward: true,
                seekableRange: {
                    start: 0,
                    end: this.seekableRange?.end ?? this.duration ?? 0,
                },
                ...newState,
            });
        }
    };
};
