import { PlaybackError } from '../errors';
import { ErrorCode } from '../errors/enums/error-code';
import { generatePsid } from '../player/utils';
import { EventKey as TelemetryEventKey } from '../telemetry/enums/event-key';
import { StreamProtection, StreamProtocol, } from '../capability';
import { mediaTypeToTelemetryAssetType } from '../telemetry/utils';
const detectStreamProtocol = (url) => {
    if (url.match(/\.m3u8/i)) {
        return StreamProtocol.HLS;
    }
    else if (url.match(/\.mpd/i)) {
        return StreamProtocol.DASH;
    }
    else {
        return null;
    }
};
const detectStreamProtection = (laUrl) => {
    if (!laUrl) {
        return StreamProtection.NONE;
    }
    if (laUrl.match(/playready/i)) {
        return StreamProtection.PLAYREADY;
    }
    if (laUrl.match(/widevine/i)) {
        return StreamProtection.WIDEVINE;
    }
    if (laUrl.match(/fairplay/i)) {
        return StreamProtection.FAIRPLAY;
    }
    return null;
};
const findBestCapabilities = (url, laUrl, capabilities) => {
    const streamProtocol = detectStreamProtocol(url);
    if (!streamProtocol) {
        throw new Error("Can't recognize stream protocol");
    }
    const streamProtection = detectStreamProtection(laUrl);
    if (!streamProtection) {
        throw new Error("Can't recognize stream protection");
    }
    const expectedCapability = {
        streamProtocol,
        streamProtection,
    };
    return [capabilities.default, ...capabilities.optional ? capabilities.optional : []].find((capability) => {
        if (capability.streamProtocol !== expectedCapability.streamProtocol) {
            return false;
        }
        if (expectedCapability.streamProtection === StreamProtection.NONE) {
            return true;
        }
        return capability.streamProtection === expectedCapability.streamProtection;
    }) ?? null;
};
export const PlayerWithPlay = (Base) => {
    return class extends Base {
        async playUrl(media, playOptions) {
            this._mediaType = playOptions.mediaType;
            this._playOptions = playOptions;
            const sessionAbortController = this.startNewSession();
            const psid = playOptions.psid ?? generatePsid();
            if (playOptions.enableNativeTelemetry) {
                this.disableTelemetry(psid);
            }
            if (!playOptions.isInternalCall) {
                this.triggerTelemetryNewSessionEvent(psid, mediaTypeToTelemetryAssetType(playOptions.mediaType), 'external');
                await this.stop();
                this.psid = psid;
            }
            if (sessionAbortController.signal.aborted) {
                throw new PlaybackError(ErrorCode.SessionAbort, 'Session start aborted by new Session');
            }
            const capability = findBestCapabilities(media.url, media.licenseUrl ?? null, this.capabilities);
            if (!capability) {
                throw new Error("Can't find capability for given url and laUrl");
            }
            const adapterRequest = {
                playOptions,
                media,
            };
            this.triggerTelemetryEvent({
                type: TelemetryEventKey.STREAM_LOADING,
                psid,
            });
            const adapterMedia = await this.adapter.load(adapterRequest);
            if (sessionAbortController.signal.aborted) {
                throw new PlaybackError(ErrorCode.SessionAbort, 'Session start aborted by new Session');
            }
            await this.dispatchMedia(adapterMedia, playOptions.isInternalCall);
        }
    };
};
