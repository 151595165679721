import { AudioFormat, } from '@zattoo/playback-sdk';
export const setMediaTrackById = (nextTrackId, tracks, setTrack) => {
    const targetTrack = tracks?.find((track) => track.id === nextTrackId);
    if (!targetTrack) {
        return;
    }
    setTrack?.(targetTrack);
};
export const updateProgram = (playOptions) => {
    const { startPositionMs, ...originalPlayOptions } = playOptions;
    const updatedPlayOptions = { ...originalPlayOptions };
    if (playOptions.programStartEpochMs && playOptions.programEndEpochMs) {
        updatedPlayOptions.program = {
            start: playOptions.programStartEpochMs,
            duration: playOptions.programEndEpochMs - playOptions.programStartEpochMs,
        };
    }
    if (startPositionMs !== undefined) {
        updatedPlayOptions.startupPosition = startPositionMs / 1000;
    }
    return updatedPlayOptions;
};
const getDolbyName = (languageNames, currentLocale = 'en') => {
    if (!languageNames) {
        return undefined;
    }
    return languageNames['ec-3']?.[currentLocale];
};
export const getISOLanguageName = (track, languageNames, currentLocale = 'en') => {
    const languageCode = track.locale;
    let dolbyLabel = '';
    if (!languageCode || !languageNames) {
        return undefined;
    }
    if (track.audioFormat === AudioFormat.DOLBY) {
        dolbyLabel = getDolbyName(languageNames, currentLocale) ?? ' - Dolby';
    }
    const result = languageNames[languageCode]?.[currentLocale]
        ?? `${languageNames.default?.[currentLocale]} (${languageCode})`;
    return `${result}${dolbyLabel}`;
};
