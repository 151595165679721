export const generatePsid = () => {
    /**
     * We reference the csid implementation
     * The session_id is composed of two parts separated with a dash (-) e.g. 15608BDC52E2621C-23FA9B84BD67316C
     * the first part before the dash is the unix timestamp in microsecond (due to precision)
     * and the next part is random.
     *
     * Ref: https://github.com/zattoo/sessionserver/blob/9065a3bb4154158e3d32937fafe41422acc5b383/session/session.go#L96
     */
    const date = (Date.now() * 1000000).toString(16);
    const randomPart = (`00000000${Math.floor(Math.random() * (2 ** 32)).toString(16)}`).slice(-8)
        + (`00000000${Math.floor(Math.random() * (2 ** 32)).toString(16)}`).slice(-8);
    return (`${date}-${randomPart}`).toUpperCase();
};
