import { ErrorCode, PlaybackError, generatePsid, MediaType, mediaTypeToTelemetryAssetType, } from '@zattoo/playback-sdk';
import { watchParamsFromAdapterCapability, watchParamsFromCapability, watchParamsFromPlayOptions, watchParamsFromStreamType, mediaItemFromWatchResponse, } from '../utils';
import { adsInfoFromWatchResponse, trackingInfoFromWatchResponse, } from '../../ads/utils';
import { EventKey as ZapiTelemetryEventKey } from '../../telemetry/enums';
import { assetIdFromRequestInfo } from '../../telemetry/utils';
import { getZapiStream, getStreamingProtocol, } from './utils';
import { ZapiEventKey } from '../../player/modules/event/event-key';
export const PlayerWithStreamRequest = (Base) => {
    return class extends Base {
        async requestStream(streamRequestInfo) {
            try {
                const sessionAbortController = this.startNewSession();
                const psid = generatePsid();
                const playOptions = streamRequestInfo.playOptions;
                if (playOptions.enableNativeTelemetry) {
                    this.disableTelemetry(psid);
                }
                this.triggerTelemetryNewSessionEvent(psid, mediaTypeToTelemetryAssetType(streamRequestInfo.mediaType), assetIdFromRequestInfo(streamRequestInfo));
                const capability = this.capabilities.default;
                const adapterCapabilities = await this.adapter.getCapabilities();
                await this.stop();
                this.psid = psid;
                if (sessionAbortController.signal.aborted) {
                    throw new PlaybackError(ErrorCode.SessionAbort, 'Session start aborted by new Session');
                }
                const streamType = getStreamingProtocol(streamRequestInfo.playOptions, capability);
                const commonWatchParams = {
                    ...watchParamsFromStreamType(streamType),
                    ...watchParamsFromPlayOptions(playOptions),
                    ...watchParamsFromCapability(capability),
                    ...(adapterCapabilities
                        ? watchParamsFromAdapterCapability(adapterCapabilities)
                        : {}),
                };
                const streamWatchParams = {
                    ...streamRequestInfo,
                    watchRequestParams: {
                        ...streamRequestInfo.watchRequestParams,
                        ...commonWatchParams,
                    },
                };
                this.updateStreamInformation(streamWatchParams, playOptions);
                // todo: dispatch telemetry based on sdk events
                // https://zattoo2.atlassian.net/browse/PLY-1702
                this.dispatchCustomTelemetryEvent({
                    psid,
                    type: ZapiTelemetryEventKey.WATCH_SENT,
                });
                this.triggerEvent({
                    type: ZapiEventKey.WATCH_REQUESTED,
                    psid,
                });
                this.zapiWatchResponse = await getZapiStream(streamWatchParams);
                if (sessionAbortController.signal.aborted) {
                    throw new PlaybackError(ErrorCode.SessionAbort, 'Session start aborted by new Session');
                }
                if (!this.zapiWatchResponse) {
                    throw new Error('No watch response');
                }
                const zapiMedia = mediaItemFromWatchResponse(streamRequestInfo.mediaType, this.zapiWatchResponse, streamRequestInfo.mediaType === MediaType.REPLAY
                    ? streamRequestInfo.watchRequestParams.dynamic_pvr
                    : false);
                // todo: dispatch telemetry based on sdk events
                // https://zattoo2.atlassian.net/browse/PLY-1702
                this.dispatchCustomTelemetryEvent({
                    psid,
                    type: ZapiTelemetryEventKey.WATCH_RECEIVED,
                    url: zapiMedia.url,
                    license_url: zapiMedia.licenseUrl,
                    csid: this.zapiWatchResponse.csid,
                });
                this.triggerEvent({
                    type: ZapiEventKey.WATCH_RECEIVED,
                    psid,
                    data: this.zapiWatchResponse,
                });
                await this.playUrl({
                    url: zapiMedia.url,
                    licenseUrl: zapiMedia.licenseUrl,
                    mediaType: zapiMedia.mediaType,
                }, {
                    ...playOptions,
                    isInternalCall: true,
                    adsInfo: adsInfoFromWatchResponse(this.zapiWatchResponse.stream.ads, this.isLive),
                    ...trackingInfoFromWatchResponse(psid, this.zapiWatchResponse),
                    mediaType: streamRequestInfo.mediaType,
                    streamType,
                    startupPosition: playOptions.startupPosition === undefined
                        ? undefined
                        : playOptions.startupPosition + (zapiMedia.prePadding ?? 0),
                });
                this.zapiDispatchMedia(zapiMedia);
                return zapiMedia;
            }
            catch (error) {
                this.handleError(error);
                return Promise.reject(error);
            }
        }
    };
};
