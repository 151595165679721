import { PlaybackError, ErrorCode as BaseErrorCode, } from '@zattoo/playback-sdk';
export class ZapiPlaybackError extends PlaybackError {
    name = 'ZapiPlaybackError';
    extendedCode = null;
    zapiError;
    constructor(...args) {
        if (args.length === 2) {
            super(
            // @ts-expect-error @todo make sure the error code type match only sdk/js ones
            args[0], args[1]);
            this.extendedCode = BaseErrorCode.Internal;
            return;
        }
        const [zapiError] = args;
        super(BaseErrorCode.Internal, zapiError.message);
        this.zapiError = zapiError;
    }
    get message() {
        if (this.zapiError) {
            return this.zapiError.message;
        }
        return `${this.extendedCode}: ${super.message}`;
    }
}
